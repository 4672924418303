export const YUNO_PAYMENT_STYLES = `
          @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
          .Yuno-pan-secure-field,
          .Yuno-cvv-secure-field,
          .Yuno-expiration-secure-field,
          .Yuno-input__content,
          .Yuno-fieldset__box {
              border-color: #181818 !important;      
              border-radius: 12px !important;
              background: #181818 !important;
              color: white !important;
              font-size: 16px !important;
              font-family: 'Manrope', sans-serif !important;
              font-weight: 400 !important;
          }
          .Yuno-input__base {
              border-color: var(--gray-dark) !important;
              background: var(--gray-dark) !important;
              color: white !important;
              font-size: 16px !important;
              font-family: 'Manrope', sans-serif !important;
              font-weight: 400 !important;
          }
          .Yuno-cvv-secure-field {
              margin-left: 8px !important;
              font-family: 'Manrope', sans-serif !important;
              font-weight: 400 !important;
          }
          .Yuno-expiration-secure-field {
              margin-right: 8px !important;
              font-family: 'Manrope', sans-serif !important;
              font-weight: 400 !important;
          }
          .Yuno-input__label {
              bottom: 100% !important;
              color: #808080 !important;
              font-size: 16px !important;
              font-family: 'Manrope', sans-serif !important;
              font-weight: 400 !important;
          }
          .Yuno-input__label--focus {
              color: white !important;
              font-size: 10px !important;
              font-family: 'Manrope', sans-serif !important;
              font-weight: 400 !important;
          }
          .Yuno-input__label--loaded {
              color: #808080 !important;
              font-size: 10px !important;
              font-family: 'Manrope', sans-serif !important;
              font-weight: 400 !important;
          }
          }`;
