import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ButtonProps } from './types.ts';

import styles from './styles.module.scss';

export const Button = ({
  variant = 'primary',
  link,
  type,
  isExternalLink,
  onClick,
  children,
  className,
  disabled,
  fullWidth,
  id,
  state,
}: ButtonProps) => {
  if (link) {
    return (
      <Link
        id={id}
        to={link}
        onClick={onClick}
        state={state}
        className={classNames(
          styles.button,
          styles[variant],
          fullWidth && styles.fullWidth,
          disabled && styles.disabled,
          className,
        )}
      >
        {children}
      </Link>
    );
  }

  if (isExternalLink) {
    return (
      <a
        id={id}
        href={link}
        onClick={onClick}
        className={classNames(
          styles.button,
          styles[variant],
          fullWidth && styles.fullWidth,
          disabled && styles.disabled,
          className,
        )}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      id={id}
      onClick={onClick}
      type={type}
      className={classNames(
        styles.button,
        styles[variant],
        fullWidth && styles.fullWidth,
        className,
      )}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
