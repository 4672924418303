import { Link } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Routes } from '@/const/enum.ts';
import { convertCentsToDollars } from '@/utils/convertCentsToDollars.ts';

import {
  DEFAULT_FREE_TOKENS_ERROR,
  DEFAULT_FREE_TOKENS_IMAGE_TEXT,
  DEFAULT_PAYMENT_ERROR_MESSAGE,
} from '../../SolidPayment/constants.tsx';
import { PaymentFailedProps } from './types.ts';

import styles from './styles.module.scss';

import WarningIcon from '@/assets/icons/chat/warning.svg?react';
import ArrowIcon from '@/assets/icons/pricing/arrow.svg?react';
import GetTokensIcon from '@/assets/icons/pricing/get-tokens.svg?react';
import TryAgainIcon from '@/assets/icons/pricing/try-again.svg?react';

export const PaymentFailed = ({
  title,
  description,
  freeTokensError = DEFAULT_FREE_TOKENS_ERROR,
  freeTokensImageText = DEFAULT_FREE_TOKENS_IMAGE_TEXT,
  totalPrice,
  handleTryAgain,
  resultProductInfo,
  freeTokensAmount,
}: PaymentFailedProps) => {
  const price = resultProductInfo
    ? convertCentsToDollars(resultProductInfo?.amountPaid)
    : totalPrice;

  return (
    <div className={styles.errorContainer}>
      {freeTokensAmount ? (
        <div className={styles.freeTokensContainer}>
          <div className={styles.iconWithText}>
            <TryAgainIcon />
            <p className={styles.tryAgainText}>{freeTokensImageText}</p>
          </div>
          <ArrowIcon className={styles.arrowIcon} />
          <div className={styles.iconWithText}>
            <GetTokensIcon />
            <p className={styles.getTokensText}>
              Get {freeTokensAmount} tokens
            </p>
          </div>
        </div>
      ) : (
        <div className={styles.errorHeader}>
          <WarningIcon />
          <p className={styles.errorText}>{title}</p>
        </div>
      )}

      <div className={styles.errorContent}>
        <div className={styles.errorDescription}>
          {!!freeTokensAmount && (
            <p className={styles.freeTokensErrorTitle}>{title}</p>
          )}
          <p>{description || DEFAULT_PAYMENT_ERROR_MESSAGE}</p>
        </div>
        {!!freeTokensAmount && (
          <p className={styles.freeTokensText}>{freeTokensError}</p>
        )}

        <div className={styles.errorPaymentInfo}>
          <p className={styles.errorPrice}>${price}</p>
          <p className={styles.errorInfoText}>Unpaid</p>
        </div>
        <Button onClick={handleTryAgain} fullWidth>
          {freeTokensImageText}
        </Button>

        <p className={styles.errorHelpText}>
          Have a question? <Link to={Routes.CONTACT_US_PAGE}>Contact Us</Link>
        </p>
      </div>
    </div>
  );
};
