import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SubscriptionProduct } from '@/services/paymentsServices/types.ts';
import { RootState } from '@/store';

export type RenewSubscriptionStep =
  | 'initial'
  | 'failed'
  | 'success'
  | 'upsell'
  | 'checkout';

export type RenewSubscriptionState = {
  step: RenewSubscriptionStep;
  selectedProduct: SubscriptionProduct | null;
};

const initialState: RenewSubscriptionState = {
  step: 'initial',
  selectedProduct: null,
};

const renewSubscriptionSlice = createSlice({
  name: 'renewSubscription',
  initialState,
  reducers: {
    setRenewSubscriptionStep(
      state,
      action: PayloadAction<RenewSubscriptionStep>,
    ) {
      state.step = action.payload;
    },
    setRenewSelectedProduct(
      state,
      action: PayloadAction<SubscriptionProduct | null>,
    ) {
      state.selectedProduct = action.payload;
    },
  },
});

export const selectRenewSubscriptionStep = (state: RootState) =>
  state.renewSubscription.step;
export const selectRenewSelectedProduct = (state: RootState) =>
  state.renewSubscription.selectedProduct;

export const { setRenewSubscriptionStep, setRenewSelectedProduct } =
  renewSubscriptionSlice.actions;
export default renewSubscriptionSlice.reducer;
