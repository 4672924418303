import classNames from 'classnames';
import { useState } from 'react';

import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';
import { RadioGroup } from '@/components/RadioGroup';
import { CANCELLATION_TEXTAREA_MIN_LENGTH } from '@/pages/pricing/CancellationSubscription/constants.ts';
import { CancellationComponentProps } from '@/pages/pricing/CancellationSubscription/types.ts';

import styles from './styles.module.scss';

export const CancellationFeedback = ({
  setStep,
  answers,
  handleUpdateAnswers,
  onClose,
}: CancellationComponentProps) => {
  const [isInputError, setIsInputError] = useState(false);

  const onAction = () => {
    if (!isValidInput) {
      setIsInputError(true);
      return;
    }

    setStep('changes');
  };

  const isValidInput =
    answers.feedback.trim().length >= CANCELLATION_TEXTAREA_MIN_LENGTH;

  return (
    <Modal
      onClose={onClose}
      closeButtonStyle="absolute"
      disableAppearanceAnimation
      maxWidth={550}
    >
      <div className={styles.container}>
        <p className={styles.heading}>Give us your feedback</p>
        <RadioGroup
          options={[{ value: answers.reason, label: answers.reason }]}
          selectedOption={answers.reason}
          onChange={() => {}}
        />

        <div>
          <textarea
            value={answers.feedback}
            onChange={(e) => {
              handleUpdateAnswers('feedback', e.target.value);
              if (isValidInput) setIsInputError(false);
            }}
            placeholder="Type your feedback here..."
            className={classNames(
              styles.textarea,
              isInputError && styles.withError,
            )}
          />
          <div className={styles.textareaHelpers}>
            {isInputError && (
              <p className={styles.errorText}>
                Please enter at least {CANCELLATION_TEXTAREA_MIN_LENGTH}
              </p>
            )}

            <p
              className={classNames(
                styles.symbols,
                isInputError && styles.withError,
              )}
            >
              {answers.feedback.trim().length}/
              {CANCELLATION_TEXTAREA_MIN_LENGTH}
            </p>
          </div>
        </div>

        <Button onClick={onAction} fullWidth>
          Send feedback
        </Button>
      </div>
    </Modal>
  );
};
