import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Loader } from '@/components/Loader';
import { LogoSvitlo } from '@/components/Mirror/Logo';
import { Modal } from '@/components/Modals/Modal';
import {
  getErrorTitleByCode,
  getFreeTokensErrorByCode,
  getFreeTokensImageTextByCode,
} from '@/components/Modals/ModalCheckout/SolidPayment/helpers.tsx';
import { PaymentError } from '@/components/Modals/ModalCheckout/components/Failed/types.ts';
import {
  getIsTokensProduct,
  getProductDetails,
  normalizeSubscription,
} from '@/components/Modals/ModalCheckout/helpers.ts';
import { useRegistration } from '@/components/Modals/ModalCheckout/hooks/useRegistration.tsx';
import { APP_NAME } from '@/const';
import {
  CHECKOUT_PAGE_OPENED_EVENT,
  PAYMENT_FAILED_EVENT,
  PURCHASE_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useContentType } from '@/hooks/useContentType.tsx';
import { useHubConnection } from '@/hooks/useHubConnection.tsx';
import { useIframeLoadTracker } from '@/hooks/useIframeLoadTracker.tsx';
import { usePaymentIntent } from '@/hooks/usePaymentIntent.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { SdkMessage, MessageType } from '@/libs/solidgate-react-sdk';
import PayableEntity from '@/libs/solidgate-react-sdk/components/SDKLoader/enums/PayableEntity.ts';
import { getProductPeriod } from '@/pages/pricing/PricingBlock/helpers.tsx';
import {
  paymentsApi,
  useGetUserCardInfoQuery,
  useSaveCardDetailsMutation,
} from '@/services/paymentsServices';
import { userApi } from '@/services/userServices';
import {
  selectIsAuth,
  selectUserCards,
  setTokens,
  updateUserSubscription,
} from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';
import { navigateAfterPurchase } from '@/utils/navigateAfterPurchase.ts';

import { PaymentCardSelection } from '../components/CardSelection';
import { PaymentFailed } from '../components/Failed';
import PaymentOrderSummary from '../components/OrderSummary';
import { PaymentSuccess } from '../components/Success';
import {
  CurrentPaymentState,
  ModalCheckoutProps,
  PaymentNotificationResponse,
  ProductType,
} from '../types.ts';
import { PaymentForm } from './SolidPaymentForm';
import {
  DEFAULT_PAYMENT_ERROR_MESSAGE,
  PAYMENT_ERRORS,
  SPECIAL_SUTTON_BANK_ERROR,
} from './constants.tsx';

import styles from './styles.module.scss';

import ArrowLeftIcon from '@/assets/icons/arrow-left.svg?react';
import LogoMirrorIcon from '@/assets/icons/logo-mirror.svg?react';
import LogoIcon from '@/assets/icons/logo.svg?react';

const LOGO = {
  main: <LogoIcon />,
  mainMirror: <LogoMirrorIcon />,
  svitlo: <LogoSvitlo />,
};

export const SolidPayment = ({
  onClose,
  productTitle,
  selectedProduct,
  handlePaymentSuccess,
  paywallType,
  additionalDiscountPercent = 0,
  disableAppearanceAnimation,
}: ModalCheckoutProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const userIdFromRedirect = searchParams.get('userId');
  const paymentSystem = searchParams.get('paymentSystem');
  const isTokensProduct = getIsTokensProduct(selectedProduct);

  const { initialTotalPrice, paywall_type, product_id, eventParams } =
    getProductDetails(selectedProduct, additionalDiscountPercent, paywallType);

  const { user, isNSFWContent } = useContentType();
  const isAuth = useAppSelector(selectIsAuth);
  const userCardInfo = useAppSelector(selectUserCards);

  const [currentStep, setCurrentStep] =
    useState<CurrentPaymentState>('loading');
  const [isFromRedirect] = useState(!!orderId);
  const [error, setError] = useState<PaymentError>({ code: '', message: '' });
  const [totalPrice, setTotalPrice] = useState(initialTotalPrice);
  const [flow, setFlow] = useState<'newCard' | 'resign'>('newCard');
  const [freeTokensAmount, setFreeTokensAmount] = useState(0);
  const [shouldSaveCard, setShouldSaveCard] = useState(true);
  const [isFormRedirected, setIsFormRedirected] = useState(false);
  const [resultProductInfo, setResultProductInfo] =
    useState<ProductType | null>(null);

  const isTestGroup = user?.featureGroup === 'experiment_example_test_group';
  useIframeLoadTracker({
    iframeId: 'payment-form-iframe',
    enabled: ['loading', 'paymentForm', 'resignForm'].includes(currentStep),
  });

  const { connection, initializeConnection, closeConnection } =
    useHubConnection({
      url: 'paymentNotificationHub',
    });

  const {
    connection: registerConnection,
    initializeConnection: initializeRegister,
    closeConnection: closeRegister,
  } = useHubConnection({
    url: 'registrationNotificationHub',
  });

  const {
    initializePayment,
    paymentData,
    resignPaymentData,
    resetPaymentData,
    resetResignData,
  } = usePaymentIntent({
    initializeConnection,
    initializeRegister,
    isTokensProduct,
    productId: selectedProduct?.id,
    additionalDiscountPercent,
  });

  const [saveCardDetails] = useSaveCardDetailsMutation();
  useGetUserCardInfoQuery(null, {
    skip: !isAuth,
  });

  useRegistration({ registerConnection, shouldSaveCard, saveCardDetails });

  useEffect(() => {
    pushToDataLayer(CHECKOUT_PAGE_OPENED_EVENT, user, {
      ...eventParams,
      paywall_type,
      product_id,
      price_usd: `${totalPrice}`,
      is_pre_paywall_shown: (!isTestGroup).toString(),
    });

    if (isFromRedirect) {
      const queryParams = userIdFromRedirect
        ? `?userId=${userIdFromRedirect}&orderId=${orderId}&paymentSystem=${paymentSystem}`
        : `?orderId=${orderId}&paymentSystem=${paymentSystem}`;
      setCurrentStep('paymentProcessing');
      initializeConnection(queryParams);
      initializeRegister(queryParams);
      setSearchParams({}, { replace: true });
    } else {
      userCardInfo?.length
        ? setCurrentStep('cardSelection')
        : initializePayment();
    }

    return closeRegister;
  }, []);

  useEffect(() => {
    if (!connection) return;

    connection.on('SpecialErrorCodeNotification', () => {
      setError(SPECIAL_SUTTON_BANK_ERROR);
    });

    connection.on('PaymentNotification', (res: PaymentNotificationResponse) => {
      const isSubscription = res.product.isSubscriptionProduct;
      const priceUSD = res.amountPaid / 100;
      res.amountPaid && setTotalPrice(priceUSD);
      const analyticPaywallType = isSubscription
        ? 'default_subscription'
        : 'default_tokens';
      const analyticProductId = isSubscription
        ? `subscription_${getProductPeriod(res.product.billingPeriod?.unit, res.product.billingPeriod?.value)}_${res.product.productPrice}`
        : `tokens_${res.product.tokenAmount}_${res.product.productPrice}`;

      if (res.success) {
        setResultProductInfo({
          ...res.product,
          amountPaid: res.amountPaid,
        });

        const analyticParams = {
          ...eventParams,
          paywall_type: analyticPaywallType,
          product_id: analyticProductId,
          price_usd: `${priceUSD}`,
          random_number: Date.now().toString(),
          is_pre_paywall_shown: (!isTestGroup).toString(),
        };

        pushToDataLayer(PURCHASE_EVENT, user, analyticParams);
        Sentry.captureMessage('Payment successful', {
          extra: {
            paymentProvider: 'solid',
            ...analyticParams,
          },
        });

        const normalizedSubscription = normalizeSubscription(res.subscription);
        if (normalizedSubscription) {
          dispatch(updateUserSubscription(normalizedSubscription));
        }
        dispatch(setTokens(res.remainingBalance));
        dispatch(paymentsApi.util.invalidateTags(['UserCardInfo']));
        dispatch(paymentsApi.util.invalidateTags(['ActiveSubscription']));
        dispatch(paymentsApi.util.invalidateTags(['Products']));
        dispatch(paymentsApi.util.invalidateTags(['UpsellProducts']));
        dispatch(userApi.util.invalidateTags(['User']));
        setCurrentStep('success');
      } else {
        resetApiData();

        const analyticParams = {
          ...eventParams,
          paywall_type: analyticPaywallType,
          product_id: analyticProductId,
          price_usd: `${priceUSD}`,
          is_pre_paywall_shown: (!isTestGroup).toString(),
        };

        pushToDataLayer(PAYMENT_FAILED_EVENT, user, analyticParams);

        const errorMessage = res.errorCode
          ? PAYMENT_ERRORS[res.errorCode as keyof typeof PAYMENT_ERRORS]
          : res.error;
        setError({
          code: res.errorCode || '',
          message: errorMessage || DEFAULT_PAYMENT_ERROR_MESSAGE,
        });

        Sentry.captureMessage('Payment failed', {
          extra: {
            code_source: 'backend_response',
            ...analyticParams,
          },
        });
        setCurrentStep('fail');
      }
    });

    connection.on('RedirectNotification', (res: { redirectUrl: string }) => {
      if (res?.redirectUrl) window.location.href = res.redirectUrl;
    });

    connection.on('FailedPaymentNotification', (res: { bonus: number }) => {
      setFreeTokensAmount(res?.bonus || 20);
    });

    return closeConnection;
  }, [connection]);

  const resetApiData = () => {
    resetPaymentData();
    resetResignData();
  };

  const handleTryAgain = () => {
    resetApiData();
    setFreeTokensAmount(0);
    if (userCardInfo?.length) {
      setCurrentStep('cardSelection');
    } else {
      setCurrentStep('loading');
      initializePayment();
    }
  };

  const handleClose = () => {
    if (currentStep === 'paymentProcessing' || freeTokensAmount) return;
    if (currentStep === 'fail') {
      handleTryAgain();
      return;
    }
    if (currentStep === 'success') {
      handlePaymentSuccess?.();
      if (paywallType === 'default_tokens') {
        navigateAfterPurchase(navigate);
      }
    }
    resetApiData();
    setFreeTokensAmount(0);
    onClose(!resultProductInfo);
  };

  const sendFailedAnalytics = (
    source: string,
    error: SdkMessage[MessageType.Error | MessageType.Fail],
  ) => {
    const analyticParams = {
      ...eventParams,
      paywall_type,
      product_id,
      price_usd: `${totalPrice}`,
      is_pre_paywall_shown: (!isTestGroup).toString(),
    };
    pushToDataLayer(PAYMENT_FAILED_EVENT, user, analyticParams);
    Sentry.captureMessage('Payment failed', {
      extra: {
        code_source: source,
        paymentProvider: 'solid',
        error,
        ...analyticParams,
      },
    });
  };

  const handleOnError = (e: SdkMessage[MessageType.Error]) => {
    console.log('error', e);
    sendFailedAnalytics('handle_on_error', e);
    resetApiData();
    setCurrentStep('fail');
  };

  const handleOnFail = (e: SdkMessage[MessageType.Fail]) => {
    sendFailedAnalytics('handle_on_fail', e);
    setError({
      code: e.code || '',
      message:
        PAYMENT_ERRORS[e.code as keyof typeof PAYMENT_ERRORS] ||
        DEFAULT_PAYMENT_ERROR_MESSAGE,
    });
    resetApiData();
    setCurrentStep('fail');
  };

  const handleBackClick = () => {
    setCurrentStep('cardSelection');
    resetApiData();
  };

  const handleSubmit = (e: SdkMessage[MessageType.Submit]) => {
    if (shouldSaveCard && e.entity === PayableEntity.Form && user) {
      saveCardDetails(paymentData?.orderId || '');
    }
    setCurrentStep('paymentProcessing');
  };

  const handleOnMounted = () => {
    console.log('mounted');
    setCurrentStep('paymentForm');
  };
  const handleReadyResignInstance = () => setCurrentStep('resignForm');

  const showLoader = currentStep === 'paymentProcessing';
  const showOrderSummary = currentStep === 'cardSelection' && !isFromRedirect;
  const showBackButton =
    !!userCardInfo?.length &&
    ['resignForm', 'paymentForm'].includes(currentStep);
  const showPaymentForm =
    !isFormRedirected &&
    ['loading', 'paymentForm', 'resignForm', 'paymentProcessing'].includes(
      currentStep,
    );
  const shouldSetWidth = ['loading', 'paymentForm', 'resignForm'].includes(
    currentStep,
  );

  const renderOrderSummary = (className?: string) =>
    selectedProduct && (
      <PaymentOrderSummary
        productTitle={productTitle}
        totalPrice={totalPrice}
        selectedProduct={selectedProduct}
        additionalDiscountPercent={additionalDiscountPercent}
        className={className}
      />
    );

  return (
    <Modal
      onClose={handleClose}
      disableBackdropClick
      maxWidth={shouldSetWidth && isNSFWContent ? 900 : 600}
      containerClassName={styles.modalContainer}
      noPadding={!!freeTokensAmount}
      closeButtonClassName={styles.closeModalButton}
      disableAppearanceAnimation={disableAppearanceAnimation}
      closeButtonStyle={
        currentStep === 'paymentProcessing' || freeTokensAmount
          ? 'hidden'
          : 'absolute'
      }
    >
      {showBackButton && (
        <button onClick={handleBackClick} className={styles.backButton}>
          <ArrowLeftIcon />
          Back
        </button>
      )}

      {!freeTokensAmount && currentStep !== 'fail' && (
        <div id="pay_frame" className={styles.logoWrapper}>
          {LOGO[APP_NAME]}
        </div>
      )}

      {showLoader && <Loader className={styles.loader} />}

      {showPaymentForm && (
        <PaymentForm
          currentStep={currentStep}
          paymentData={paymentData}
          resignPaymentData={resignPaymentData}
          handleOnError={handleOnError}
          handleOnFail={handleOnFail}
          handleSubmit={handleSubmit}
          handleReadyResignInstance={handleReadyResignInstance}
          setIsFormRedirected={setIsFormRedirected}
          handleOnMounted={handleOnMounted}
          shouldSaveCard={shouldSaveCard}
          setShouldSaveCard={setShouldSaveCard}
          flow={flow}
          OrderSummary={renderOrderSummary(styles.orderSummary)}
        />
      )}

      {currentStep === 'cardSelection' && (
        <PaymentCardSelection
          initializePayment={initializePayment}
          setCurrentStep={setCurrentStep}
          setFlow={setFlow}
        />
      )}

      {currentStep === 'success' && (
        <PaymentSuccess
          handleClose={handleClose}
          resultProductInfo={resultProductInfo!}
        />
      )}

      {currentStep === 'fail' && (
        <PaymentFailed
          title={getErrorTitleByCode(error.code, error.isSuttonBankError)}
          description={error.message}
          totalPrice={totalPrice}
          freeTokensError={getFreeTokensErrorByCode(error.code)}
          freeTokensImageText={getFreeTokensImageTextByCode(error.code)}
          resultProductInfo={resultProductInfo}
          handleTryAgain={handleTryAgain}
          freeTokensAmount={freeTokensAmount}
        />
      )}

      {showOrderSummary && renderOrderSummary()}
    </Modal>
  );
};
