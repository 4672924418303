import { useRenewSubscription } from '@/hooks/useRenewSubscription.tsx';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { RenewButton } from '@/pages/settings/components/RenewButton';
import { selectIsYunoPaymentMethod } from '@/store/public';

import { RenewSubscriptionProps } from './types.ts';

export const RenewSubscription = ({
  buttonLabel,
  buttonSpanText,
  className,
}: RenewSubscriptionProps) => {
  const isYunoPayment = useAppSelector(selectIsYunoPaymentMethod);

  const { onClickRenew, isLoadingRenew } = useRenewSubscription();

  if (isYunoPayment) {
    return null;
  }

  return (
    <RenewButton
      onClick={onClickRenew}
      isLoading={isLoadingRenew}
      label={buttonLabel}
      buttonSpanText={buttonSpanText}
      className={className}
    />
  );
};
