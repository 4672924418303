import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import {
  ANONYMOUS_USER_ID_KEY,
  BASE_LOCATION_URL,
  CONFIRMED_TERMS_KEY,
  IS_LIGHT,
  IS_MIRROR_MAIN_APP,
} from '@/const';
import { Routes } from '@/const/enum.ts';
import { FingerprintData } from '@/hooks/useFingerprint.tsx';
import { getFingerprintHash } from '@/utils/getFingerprintHash.ts';
import { ls } from '@/utils/localStorage.ts';

import styles from './styles.module.scss';

import GoogleIcon from '@/assets/icons/google.svg?react';

export const GoogleAuth = () => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const terms = ls.get(CONFIRMED_TERMS_KEY, '');
    const userId = ls.get(ANONYMOUS_USER_ID_KEY, '');
    let baseUrl = `${BASE_LOCATION_URL}${Routes.GOOGLE_AUTH_PAGE}?isMirror=${IS_MIRROR_MAIN_APP}${terms ? `&terms=${terms}` : ''}`;

    if (userId) {
      baseUrl += `${terms ? '&' : '?'}userid=${userId}`;
    }

    const getFingerprintData = async () => {
      const fingerprintData = (await getFingerprint(true)) as FingerprintData;
      const fingerprintHash = getFingerprintHash(fingerprintData);

      baseUrl += `&fingerprint=${fingerprintHash}`;
      setUrl(baseUrl);
    };

    getFingerprintData();
  }, []);

  return (
    <a
      id="google_btn"
      href={url}
      className={classNames(styles.googleAuth, IS_LIGHT && styles.light)}
    >
      <GoogleIcon />
      <span className={styles.googleText}>Continue with Google</span>
    </a>
  );
};
