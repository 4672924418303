import classNames from 'classnames';

import { MirrorLogoProps } from './types.ts';

import styles from './styles.module.scss';

export const LogoSvitlo = ({ isLight }: MirrorLogoProps) => {
  return (
    <p className={classNames(styles.logo, isLight && styles.light)}>
      svitlo<span>.ai</span>
    </p>
  );
};
