import { useEffect, useState } from 'react';

import { SIGNUP_POPUP_SHOWN_EVENT } from '@/const/dataLayerEvents.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';

import { ModalAuthMagicLink } from './ModalAuthMagicLink';
import { ModalAuthWithPassword } from './ModalAuthWithPassword';
import { ModalAuthContainerProps } from './types.ts';

export const ModalAuthContainer = ({
  source = 'explore_page',
  defaultVariant,
  ...props
}: ModalAuthContainerProps) => {
  const user = useAppSelector(selectUser);

  const [variant, setVariant] = useState<'login' | 'register' | 'expired'>(
    defaultVariant || 'login',
  );
  const [isMagicLinkForm, setIsMagicLinkForm] = useState(true);

  useEffect(() => {
    pushToDataLayer(SIGNUP_POPUP_SHOWN_EVENT, user, { source });
  }, []);

  return isMagicLinkForm ? (
    <ModalAuthMagicLink
      {...props}
      variant={variant}
      setVariant={setVariant}
      setIsMagicLinkForm={setIsMagicLinkForm}
    />
  ) : (
    <ModalAuthWithPassword
      {...props}
      variant={variant}
      setVariant={setVariant}
      setIsMagicLinkForm={setIsMagicLinkForm}
    />
  );
};
