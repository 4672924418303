import { useEffect } from 'react';

import { Button } from '@/components/Button';
import { Loader } from '@/components/Loader';
import { Modal } from '@/components/Modals/Modal';
import {
  SUBSCRIPTION_CANCELLATION_WARNING_POPUP_CANCEL_CLICKED_EVENT,
  SUBSCRIPTION_CANCELLATION_WARNING_POPUP_CLOSED_EVENT,
  SUBSCRIPTION_CANCELLATION_WARNING_POPUP_SHOWN_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { CancellationComponentProps } from '@/pages/pricing/CancellationSubscription/types.ts';
import { useGetUserActivityInfoQuery } from '@/services/userServices';
import { selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';

import styles from './styles.module.scss';

import errorImage from '@/assets/images/warning.png';

export const CancellationWarning = ({
  setStep,
  onClose,
}: CancellationComponentProps) => {
  const user = useAppSelector(selectUser);

  const { data, isLoading } = useGetUserActivityInfoQuery(user?.id);

  useEffect(() => {
    pushToDataLayer(SUBSCRIPTION_CANCELLATION_WARNING_POPUP_SHOWN_EVENT, user);
  }, []);

  const onAction = () => {
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_WARNING_POPUP_CANCEL_CLICKED_EVENT,
      user,
    );
    setStep('reason');
  };
  const handleClose = () => {
    pushToDataLayer(SUBSCRIPTION_CANCELLATION_WARNING_POPUP_CLOSED_EVENT, user);
    onClose();
  };

  return (
    <Modal onClose={handleClose} closeButtonStyle="absolute" maxWidth={460}>
      <div className={styles.container}>
        <img src={errorImage} alt="Error" />
        {isLoading ? (
          <Loader className={styles.loader} />
        ) : (
          <>
            <h1 className={styles.title}>
              Warning! You’ll lose all your content
            </h1>
            <p className={styles.description}>
              By unsubscribing, you'll lose access to your {data?.tokenCount}
              &nbsp;tokens, {data?.chatCount}&nbsp;chats and {data?.modelCount}
              &nbsp;AI characters at the end of the billing period. Are you
              sure?
            </p>
            <Button onClick={onClose} fullWidth>
              I keep my subscription and continue
            </Button>
            <Button variant="disabled" onClick={onAction} fullWidth>
              I cancel and lose all my content
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};
