import classNames from 'classnames';

import { IS_MAIN_APP, IS_MIRROR_SVITLO_APP } from '@/const';
import { useContentType } from '@/hooks/useContentType.tsx';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { formatProductDetails } from '@/pages/pricing/PricingBlock/helpers.tsx';
import { selectIsYunoPaymentMethod } from '@/store/public';

import { PricingBlockProductProps } from './types.tsx';

import styles from './styles.module.scss';

const PricingBlockProduct = ({
  index,
  product,
  isLifeTimeSubscription,
  selectedProduct,
  setSelectedProduct,
  activeSubscriptionInMonths,
  className,
  selectedClassName,
}: PricingBlockProductProps) => {
  const { isNSFWContent } = useContentType();

  const isYunoPayment = useAppSelector(selectIsYunoPaymentMethod);

  const isNSFWOrMainApp = isNSFWContent || IS_MAIN_APP;

  const {
    priceDaily,
    previousPriceDaily,
    priceMonthlyInteger,
    priceMonthlyDecimal,
    previousPriceMonthly,
    period,
    isLifetime,
    isCurrentPlan,
  } = formatProductDetails(product, activeSubscriptionInMonths);

  return (
    <div
      id={`subscription_${index}`}
      className={classNames(
        styles.priceBlock,
        selectedProduct?.id === product.id && styles.selected,
        selectedProduct?.id === product.id && selectedClassName,
        isCurrentPlan && styles.activeSubscription,
        product.isPopular && styles.popular,
        isNSFWContent && styles.nEnabled,
        isLifeTimeSubscription && styles.lifetimeSubscription,
        className,
        'subscription_price',
      )}
      role="button"
      onClick={() => {
        !IS_MIRROR_SVITLO_APP && setSelectedProduct(product);
      }}
    >
      <div className={styles.priceBlockContent}>
        <div>
          <div className={styles.planInfo}>
            <p className={styles.planTitle}>
              {isLifetime ? 'Lifetime Access 🍓' : period}
            </p>
            {!!product.discount && !product.extraBonusMonth && (
              <p className={styles.discountBadge}>{product.discount}% off</p>
            )}
            {!!product.extraBonusMonth && !isYunoPayment && (
              <p className={styles.discountBadge}>
                +{product.extraBonusMonth} EXTRA Months
              </p>
            )}
          </div>

          {isLifetime && (
            <p className={styles.lifetimeSubscriptionText}>
              Eternal pleasure just for you!
            </p>
          )}

          {product.currentPriceDaily < product.previousPriceDaily && (
            <p className={styles.previousPrice}>
              {isNSFWOrMainApp ? (
                `${previousPriceDaily} per day`
              ) : (
                <>
                  Was&nbsp;${previousPriceMonthly}/<br />
                  month
                </>
              )}
            </p>
          )}
        </div>

        {!isLifetime && (
          <div className={styles.priceInfo}>
            <p className={styles.priceInteger}>
              {isNSFWOrMainApp ? priceDaily : `$${priceMonthlyInteger}`}
            </p>
            <div>
              <p className={styles.priceDecimal}>
                {isNSFWOrMainApp ? 'USD' : `.${priceMonthlyDecimal}`}
              </p>
              <p className={styles.period}>
                {isNSFWOrMainApp ? 'per day' : '/month'}
              </p>
            </div>
          </div>
        )}
      </div>

      {isCurrentPlan && (
        <p className={styles.activeSubscriptionBadge}>Current Plan</p>
      )}

      {product.isPopular && <p className={styles.popularBadge}>Most Popular</p>}
    </div>
  );
};

export default PricingBlockProduct;
