import AnonymousIcon from '@/assets/icons/pricing/anonymous.svg?react';
import ContactIcon from '@/assets/icons/pricing/contact.svg?react';
import SecureIcon from '@/assets/icons/pricing/secure.svg?react';

export const PAYMENT_SECURE_FEATURES = [
  { name: 'Customer Support', Icon: ContactIcon },
  { name: '100% Secure Payment', Icon: SecureIcon },
  { name: 'Anonymous Payment', Icon: AnonymousIcon },
];

export const PAYMENT_FAQ = [
  {
    summary: 'What is an AI girl and how does it work?',
    details:
      'AI girl is a virtual character created using artificial intelligence. You can customize her appearance and personality, after which she will communicate with you via text messages, adapting to your preferences.',
  },
  {
    summary: 'Can I customize the appearance and character of the AI girl?',
    details:
      'Yes, you can customize her appearance (facial features, clothes, etc.), as well as choose her personality and behavior to perfectly match your preferences.',
  },
  {
    summary: 'How realistic is the AI girl?',
    details:
      'The AI girl is created using advanced natural language processing technologies, which makes communication smooth and realistic. However, she remains a virtual character.',
  },
  {
    summary: 'Can I chat with an AI girl 24/7?',
    details:
      'Yes, the AI girl is available around the clock, without time or place restrictions. She is always ready to carry on a conversation whenever it is convenient for you.',
  },
  {
    summary: 'Can I create multiple AI girls?',
    details:
      'Yes, you can create multiple characters and customize each one differently. This allows you to experiment with different images and characters.',
  },
  {
    summary: 'Is it safe to chat with an AI girl?',
    details:
      'Yes, your privacy is important to us. All conversations with the AI girl are protected and are not transferred to third parties. We do not store personal data without your consent.',
  },
];
