import { createApi } from '@reduxjs/toolkit/query/react';

import { userApi } from '@/services/userServices';
import { baseQueryWithReauth } from '@/services/utils/baseQueryWithReauth.ts';

import {
  GoogleLoginData,
  GoogleLoginResponse,
  LoginAuthType,
  LoginData,
  LoginPreRegisterData,
  LoginPreRegisterResponse,
  PasswordLessLoginData,
  PreRegisterData,
  PreRegisterResponse,
  RegisterData,
} from './types';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    passwordLessLogin: builder.mutation<LoginAuthType, PasswordLessLoginData>({
      query: (body) => ({
        url: `Authenticate/passwordless-login`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(name, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.util.invalidateTags(['User']));
        } catch (error) {
          console.error('Change user name failed', error);
        }
      },
    }),

    login: builder.mutation<LoginAuthType, LoginData>({
      query: (body) => ({
        url: `Authenticate/login`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(name, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.util.invalidateTags(['User']));
        } catch (error) {
          console.error('Change user name failed', error);
        }
      },
    }),

    register: builder.mutation<LoginAuthType, RegisterData>({
      query: (body) => ({
        url: `Authenticate/register`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(name, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.util.invalidateTags(['User']));
        } catch (error) {
          console.error('Change user name failed', error);
        }
      },
    }),

    googleLogin: builder.mutation<GoogleLoginResponse, GoogleLoginData>({
      query: (body) => ({
        url: `Authenticate/google-signin`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(name, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.util.invalidateTags(['User']));
        } catch (error) {
          console.error('Change user name failed', error);
        }
      },
    }),

    preRegister: builder.mutation<PreRegisterResponse, PreRegisterData>({
      query: (body) => ({
        url: `Authenticate/pre-register`,
        method: 'POST',
        body,
      }),
    }),

    loginPreRegister: builder.mutation<
      LoginPreRegisterResponse,
      LoginPreRegisterData
    >({
      query: (body) => ({
        url: `Authenticate/login-pre-register`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  usePasswordLessLoginMutation,
  useLoginMutation,
  useRegisterMutation,
  useGoogleLoginMutation,
  usePreRegisterMutation,
  useLoginPreRegisterMutation,
} = authApi;
