import classNames from 'classnames';

import { useContentType } from '@/hooks/useContentType.tsx';
import { getPricingDiscountText } from '@/pages/pricing/PricingBlock/helpers.tsx';
import { convertSubscriptionPeriodToMonthsNumber } from '@/pages/pricing/helpers.ts';

import { PricingBlockDiscountProps } from './types.tsx';

import styles from './styles.module.scss';

import pricingGirlImage from '@/assets/images/pricing/pricing-girl.png';

const ONE_YEAR_IN_MONTHS = 12;

export const PricingBlockDiscount = ({
  isLifeTimeSubscription,
  isLoading,
  activeSubscription,
  isModal,
}: PricingBlockDiscountProps) => {
  const { user, isNSFWContent } = useContentType();

  const period = convertSubscriptionPeriodToMonthsNumber(
    activeSubscription?.periodType,
    activeSubscription?.periodValue,
  );
  const isOneYearSubscription = period === ONE_YEAR_IN_MONTHS;

  const { title, description } = getPricingDiscountText(
    !!user?.activeSubscription,
    isLifeTimeSubscription,
    isOneYearSubscription,
  );

  return (
    <div className={styles.discountSection}>
      <div className={styles.discountTextContent}>
        {!isLoading && (
          <>
            <h2
              id="discount_title"
              className={classNames(
                styles.discountTitle,
                isModal && styles.isModal,
              )}
            >
              {title}
            </h2>
            <p id="discount_description" className={styles.discountDescription}>
              {description}
            </p>
          </>
        )}
      </div>

      {isNSFWContent && !isLoading && (
        <img
          src={pricingGirlImage}
          alt="AI Companion"
          width={330}
          height={667}
          className={styles.pricingGirlImage}
        />
      )}
    </div>
  );
};
