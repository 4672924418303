import classNames from 'classnames';

import { Button } from '@/components/Button';
import { Spinner } from '@/components/Spinner';
import { MAX_DISCOUNT_PERCENT } from '@/const';

import { RenewButtonProps } from './types.ts';

import styles from './styles.module.scss';

import RenewIcon from '@/assets/icons/pricing/renew.svg?react';

export const RenewButton = ({
  onClick,
  isLoading,
  label,
  buttonSpanText,
  className,
}: RenewButtonProps) => {
  return (
    <div className={classNames(styles.actionButtonContainer, className)}>
      {!isLoading && (
        <span className={styles.discount}>{MAX_DISCOUNT_PERCENT}% OFF</span>
      )}

      <Button onClick={onClick}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <RenewIcon />
            {label} <span>{buttonSpanText}</span>
          </>
        )}
      </Button>
    </div>
  );
};
