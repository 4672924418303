import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';

import { ModalUpsellConfirmationProps } from './types.ts';

import styles from './styles.module.scss';

import premiumGirl from '@/assets/images/tokens/upsell-tokens-2.webp';

export const ModalUpsellConfirmation = ({
  handleAction,
  handleClose,
}: ModalUpsellConfirmationProps) => {
  return (
    <Modal
      closeButtonStyle="hidden"
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth={450}
      noPadding
    >
      <img src={premiumGirl} alt="Premium Girl" className={styles.girlImage} />
      <p className={styles.badge}>Don't leave me...😔</p>
      <div className={styles.content}>
        <p className={styles.title}>This is a One-Time Offer</p>
        <p className={styles.description}>
          If you close this window, you won’t be able to get this deal again.
        </p>

        <div className={styles.actionButtons}>
          <Button onClick={handleAction} fullWidth>
            No, go back
          </Button>

          <Button variant="disabled" onClick={handleClose} fullWidth>
            Yes, skip It
          </Button>
        </div>
      </div>
    </Modal>
  );
};
