import {
  ANONYMOUS_USER_ID_KEY,
  BASE_URL_API,
  CONFIRMED_TERMS_KEY,
  REFRESH_PRE_REGISTER_URL,
  REFRESH_TOKEN_URL,
} from '@/const';
import { ls } from '@/utils/localStorage.ts';

export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';

export const setTokensInLocalStorage = (
  accessToken: string,
  refreshToken: string,
) => {
  ls.set(ACCESS_TOKEN_KEY, accessToken);
  ls.set(REFRESH_TOKEN_KEY, refreshToken);
};

export const getTokensFromLocalStorage = () => {
  const accessToken = ls.get(ACCESS_TOKEN_KEY, '');
  const refreshToken = ls.get(REFRESH_TOKEN_KEY, '');
  return { accessToken, refreshToken };
};

export const removeTokensFromLocalStorage = () => {
  ls.remove(ACCESS_TOKEN_KEY);
  ls.remove(REFRESH_TOKEN_KEY);
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const refreshAccessToken = async (): Promise<string> => {
  const { accessToken, refreshToken } = getTokensFromLocalStorage();
  const anonUserId = ls.get(ANONYMOUS_USER_ID_KEY, '');
  const confirmedTerms = ls.get(CONFIRMED_TERMS_KEY, '');
  if (!accessToken || !refreshToken) return '';

  try {
    const response = await fetch(
      `${BASE_URL_API}${anonUserId ? REFRESH_PRE_REGISTER_URL : REFRESH_TOKEN_URL}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Ignore-Authorization': 'true',
        },
        body: JSON.stringify({
          accessToken: accessToken,
          refreshToken: refreshToken,
          userId: anonUserId,
          isNConfirmed: confirmedTerms === 'n',
          isSConfirmed: !!confirmedTerms,
        }),
      },
    );

    if (response.ok) {
      const data = await response.json();
      const { accessToken, refreshToken } = data;
      setTokensInLocalStorage(accessToken, refreshToken);
      return accessToken;
    } else {
      removeTokensFromLocalStorage();
      return '';
    }
  } catch (error) {
    console.error('Error refreshing access token:', error);
    return '';
  }
};
