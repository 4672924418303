import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectSpentTokens } from '@/store/public';

import styles from './styles.module.scss';

import TokenIcon from '@/assets/icons/token.svg?react';

export const SpentTokens = () => {
  const spentTokens = useAppSelector(selectSpentTokens);

  if (!spentTokens) return null;

  return (
    <span className={styles.spentTokens}>
      - {spentTokens} <TokenIcon />
    </span>
  );
};
