import { Loader } from '@/components/Loader';
import { SolidPayment } from '@/components/Modals/ModalCheckout/SolidPayment';
import { YunoPayment } from '@/components/Modals/ModalCheckout/YunoPayment';
import { ModalCheckoutProps } from '@/components/Modals/ModalCheckout/types.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectIsYunoPaymentMethod, selectUser } from '@/store/public';

export const ModalCheckout = (props: ModalCheckoutProps) => {
  const user = useAppSelector(selectUser);
  const isYunoPaymentMethod = useAppSelector(selectIsYunoPaymentMethod);

  if (!user) {
    return <Loader />;
  }

  return isYunoPaymentMethod ? (
    <YunoPayment {...props} />
  ) : (
    <SolidPayment {...props} />
  );
};
