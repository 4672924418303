import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from '@/services/utils/baseQueryWithReauth.ts';

import { FingerPrintPayload, UserActivityType, UserType } from './types.ts';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUserInfo: builder.query<UserType, void | null>({
      query: () => `User`,
      providesTags: ['User'],
    }),

    getUserActivityInfo: builder.query<UserActivityType, string | undefined>({
      query: (userId = '') => `User/get-user-activity-info?userId=${userId}`,
    }),

    deleteAccount: builder.mutation<UserType, void>({
      query: () => ({
        url: `User`,
        method: 'DELETE',
      }),
    }),

    changeUserName: builder.mutation<UserType, string>({
      query: (userName) => ({
        url: `User/update/name`,
        method: 'POST',
        body: { userName },
      }),
      async onQueryStarted(name, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.util.invalidateTags(['User']));
        } catch (error) {
          console.error('Change user name failed', error);
        }
      },
    }),

    setFingerprint: builder.mutation<void, FingerPrintPayload>({
      query: (body) => ({
        url: `Fingerprint`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(name, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.util.invalidateTags(['User']));
        } catch (error) {
          console.error('Change user name failed', error);
        }
      },
    }),

    putFingerprint: builder.mutation<
      { nEnabled: boolean },
      {
        Fingerprint: string;
      }
    >({
      query: (body) => ({
        url: `Fingerprint`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted(name, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.util.invalidateTags(['User']));
        } catch (error) {
          console.error('Change user name failed', error);
        }
      },
    }),

    acceptTerms: builder.mutation<UserType, void>({
      query: (body) => ({
        url: `User/nsfw-confirmation`,
        method: 'POST',
        body,
      }),
    }),

    acceptSFWTerms: builder.mutation<UserType, void>({
      query: (body) => ({
        url: `User/sfw-confirmation`,
        method: 'POST',
        body,
      }),
    }),

    sendPropellerEvent: builder.mutation<void, string>({
      query: (fingerprint) => ({
        url: `Event/send-pre-paywall-propeller-event`,
        method: 'POST',
        body: { fingerprint },
      }),
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useDeleteAccountMutation,
  useChangeUserNameMutation,
  useSetFingerprintMutation,
  usePutFingerprintMutation,
  useAcceptTermsMutation,
  useAcceptSFWTermsMutation,
  useSendPropellerEventMutation,
  useGetUserActivityInfoQuery,
} = userApi;
