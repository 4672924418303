import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { authApi } from '@/services/authServices';
import { contactApi } from '@/services/contactServices';
import { createCharacterApi } from '@/services/createCharacterServices';
import { paymentsApi } from '@/services/paymentsServices';
import { personalApi } from '@/services/personalServices';
import { userApi } from '@/services/userServices';

import chatReducer from './chat';
import paywallReducer from './paywall';
import publicReducer from './public';
import renewSubscriptionReducer from './renewSubscription';

const store = configureStore({
  reducer: {
    public: publicReducer,
    chat: chatReducer,
    paywall: paywallReducer,
    renewSubscription: renewSubscriptionReducer,
    [authApi.reducerPath]: authApi.reducer,
    [personalApi.reducerPath]: personalApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [createCharacterApi.reducerPath]: createCharacterApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      authApi.middleware,
      personalApi.middleware,
      userApi.middleware,
      paymentsApi.middleware,
      contactApi.middleware,
      createCharacterApi.middleware,
    ),
});

setupListeners(store.dispatch);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
