import * as amplitude from '@amplitude/analytics-browser';

import { UserType } from '@/services/userServices/types.ts';

export const pushToDataLayer = (
  event: string,
  user: UserType | null,
  properties: Record<string, string> = {},
): void => {
  const dataLayerEvent = {
    event,
    ...properties,
    ...(user ? { user_id: user.id } : {}),
  };

  const amplitudeEvent = {
    event_type: event,
  };
  amplitude.track(amplitudeEvent, properties);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayerEvent);
};
