import { useEffect } from 'react';

import { ModalCancelConfirmationWithImage } from '@/components/Modals/ModalCancelConfirmationWithImage';
import {
  SUBSCRIPTION_CANCELLATION_REMINDER_POPUP_CLOSED_EVENT,
  SUBSCRIPTION_CANCELLATION_REMINDER_POPUP_LOOSE_ACCESS_EVENT,
  SUBSCRIPTION_CANCELLATION_REMINDER_POPUP_SHOWN_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useContentType } from '@/hooks/useContentType.tsx';
import { CancellationComponentProps } from '@/pages/pricing/CancellationSubscription/types.ts';
import { pushToDataLayer } from '@/utils/gtm.ts';

import reminderBannerImageSafe from '@/assets/images/pricing/reminder-banner-sf.webp';
import reminderBannerImage from '@/assets/images/pricing/reminder-banner.webp';

export const CancellationReminder = ({
  setStep,
  onClose,
}: CancellationComponentProps) => {
  const { user, isNSFWContent } = useContentType();

  useEffect(() => {
    pushToDataLayer(SUBSCRIPTION_CANCELLATION_REMINDER_POPUP_SHOWN_EVENT, user);
  }, []);

  const onAction = () => {
    setStep('changes');
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_REMINDER_POPUP_LOOSE_ACCESS_EVENT,
      user,
    );
  };
  const handleClose = () => {
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_REMINDER_POPUP_CLOSED_EVENT,
      user,
    );
    onClose();
  };

  const bannerImage = isNSFWContent
    ? reminderBannerImage
    : reminderBannerImageSafe;

  return (
    <ModalCancelConfirmationWithImage
      handleAction={onAction}
      handleClose={handleClose}
      badgeText="Don't leave me Baby...😔"
      title="We will remind you about charging at the of the billing period"
      imageUrl={bannerImage}
      description="Do you want yo save your subscription?"
      actionButtonText="No, lose access"
      cancelButtonText="Yes, go back"
      disableAppearanceAnimation
    />
  );
};
