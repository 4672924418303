import { createApi } from '@reduxjs/toolkit/query/react';

import {
  CharacterType,
  ExternalQuizAnswersType,
} from '@/pages/createCharacter/types.ts';
import { CreateQuizModelResponse } from '@/services/createCharacterServices/types.ts';
import { baseQueryWithReauth } from '@/services/utils/baseQueryWithReauth.ts';

export const createCharacterApi = createApi({
  reducerPath: 'createCharacterApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createCharacter: builder.mutation<CreateQuizModelResponse, CharacterType>({
      query: (body) => ({
        url: `PersonModel/create`,
        method: 'POST',
        body,
      }),
    }),
    createQuizModel: builder.mutation<
      CreateQuizModelResponse,
      ExternalQuizAnswersType
    >({
      query: (body) => ({
        url: `PersonModel/create-landing-model-quickdate`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useCreateCharacterMutation, useCreateQuizModelMutation } =
  createCharacterApi;
