import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import { useEffect, useState } from 'react';

import { BASE_URL, IS_NGROK_API } from '@/const';
import {
  getTokensFromLocalStorage,
  refreshAccessToken,
} from '@/utils/authUtils.ts';
import { isTokenExpired } from '@/utils/isTokenExpired.ts';

import { UseHubConnectionProps } from './types.ts';

const headers: Record<string, string> = IS_NGROK_API
  ? { 'ngrok-skip-browser-warning': 'true' }
  : {};

const getAccessToken = async (): Promise<string> => {
  const { accessToken } = getTokensFromLocalStorage();

  if (accessToken && !isTokenExpired()) {
    return accessToken;
  }

  const refreshedToken = await refreshAccessToken();
  return refreshedToken ?? '';
};

export const useHubConnection = ({
  url,
  onConnectionStateChange,
}: UseHubConnectionProps) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    onConnectionStateChange?.(connection);
  }, [connection]);

  const initializeConnection = async (queryParams?: string) => {
    closeConnection();
    const currentUrl = queryParams
      ? `${BASE_URL}/${url}${queryParams}`
      : `${BASE_URL}/${url}`;

    const hubConnection = new HubConnectionBuilder()
      .withUrl(currentUrl, {
        accessTokenFactory: async () => await getAccessToken(),
        withCredentials: true,
        headers,
      })
      .withAutomaticReconnect()
      .build();

    try {
      if (hubConnection.state === HubConnectionState.Disconnected) {
        await hubConnection.start();
      }
      onConnectionStateChange
        ? onConnectionStateChange(hubConnection)
        : setConnection(hubConnection);
    } catch (error) {
      console.error('Error establishing SignalR connection:', error);
    }
  };

  const closeConnection = (
    hubConnection: HubConnection | null = connection,
  ) => {
    if (hubConnection && hubConnection.state === HubConnectionState.Connected) {
      hubConnection.stop();
      onConnectionStateChange
        ? onConnectionStateChange(null)
        : setConnection(null);
    }
  };

  return { connection, initializeConnection, closeConnection };
};
