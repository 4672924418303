export const CANCELLATION_CHANGES_OPTIONS = [
  {
    label: 'Improve chat',
    value: 'Improve chat',
  },
  {
    label: 'Improve photo generation',
    value: 'Improve photo generation',
  },
  {
    label: 'Improve character creation',
    value: 'Improve character creation',
  },
  {
    label: 'Add more pre-generated models',
    value: 'Add more pre-generated models',
  },
  {
    label: 'Add more additional features',
    value: 'Add more additional features',
  },
];
