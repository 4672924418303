import { createApi } from '@reduxjs/toolkit/query/react';

import { InitConfig, ResignRequest } from '@/libs/solidgate-react-sdk';
import { CancellationAnswers } from '@/pages/pricing/CancellationSubscription/types.ts';
import {
  PaymentIntentParams,
  TokensProduct,
  UserCardType,
} from '@/services/paymentsServices/types.ts';
import { userApi } from '@/services/userServices';
import {
  ActiveSubscriptionProduct,
  UserActiveSubscriptionType,
} from '@/services/userServices/types.ts';
import { baseQueryWithReauth } from '@/services/utils/baseQueryWithReauth.ts';

import { SubscriptionProduct } from './types.ts';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'UserCardInfo',
    'ActiveSubscription',
    'Products',
    'UpsellProducts',
  ],
  endpoints: (builder) => ({
    getProducts: builder.query<SubscriptionProduct[], void | null>({
      query: () => `Payments/subscription-products`,
      providesTags: ['Products'],
    }),

    getProductsAdmin: builder.query<SubscriptionProduct[], void | null>({
      query: () => `Payments/admin-subscription-products-hidden`,
    }),

    getActiveSubscription: builder.query<ActiveSubscriptionProduct, void>({
      query: () => `Payments/user-active-subscription`,
      providesTags: ['ActiveSubscription'],
    }),

    cancelSubscription: builder.mutation<
      void,
      {
        subscriptionId: string;
        isYunoPayment: boolean;
        answers?: CancellationAnswers;
      }
    >({
      query: ({ subscriptionId, answers, isYunoPayment }) => ({
        url: `${isYunoPayment ? 'Yuno' : ''}Payments/subscription/${subscriptionId}/cancel`,
        method: 'POST',
        body: answers,
      }),
      async onQueryStarted(name, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(paymentsApi.util.invalidateTags(['ActiveSubscription']));
          dispatch(userApi.util.invalidateTags(['User']));
        } catch (error) {
          console.error('Cancel Subscription failed', error);
        }
      },
    }),

    getTokens: builder.query<
      {
        remainingTokens: number;
        activeSubscription: UserActiveSubscriptionType;
      },
      void | null
    >({
      query: () => `Tokens/balance`,
    }),

    getTokenProducts: builder.query<TokensProduct[], void | null>({
      query: () => `Payments/one-time-pay-products`,
    }),

    getTokenProductsAdmin: builder.query<TokensProduct[], void | null>({
      query: () => `Payments/admin-one-time-pay-products-hidden`,
    }),

    createPaymentIntent: builder.mutation<
      InitConfig['merchantData'] & { orderId: string; createdUserId: string },
      PaymentIntentParams
    >({
      query: ({ redirectUrl, ...rest }) => ({
        url: `Payments/create-payment-intent`,
        method: 'POST',
        body: {
          successUrl: redirectUrl,
          failUrl: redirectUrl,
          ...rest,
        },
      }),
    }),

    updateSubscription: builder.mutation<
      InitConfig['merchantData'] & { orderId: string; createdUserId: string },
      PaymentIntentParams
    >({
      query: ({ redirectUrl, ...rest }) => ({
        url: `Payments/subscription-products/update`,
        method: 'POST',
        body: {
          successUrl: redirectUrl,
          failUrl: redirectUrl,
          ...rest,
        },
      }),
      async onQueryStarted(name, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(paymentsApi.util.invalidateTags(['ActiveSubscription']));
          dispatch(paymentsApi.util.invalidateTags(['Products']));
          dispatch(paymentsApi.util.invalidateTags(['UpsellProducts']));
        } catch (error) {
          console.error('Cancel Subscription failed', error);
        }
      },
    }),

    createPaymentIntentYuno: builder.mutation<
      { checkoutSession: string; createdUserId: string },
      PaymentIntentParams
    >({
      query: ({ redirectUrl, ...rest }) => ({
        url: `YunoPayments/create-payment-intent`,
        method: 'POST',
        body: {
          successUrl: redirectUrl,
          failUrl: redirectUrl,
          ...rest,
        },
      }),
    }),

    createPaymentYuno: builder.mutation<
      {
        checkoutSession: string;
        createdUserId: string;
        actionRequired?: boolean;
      },
      PaymentIntentParams & {
        sessionId: string;
        email?: string;
        vaultedToken?: string;
        oneTimeToken?: string;
      }
    >({
      query: ({ redirectUrl, ...rest }) => ({
        url: `YunoPayments/create-payment`,
        method: 'POST',
        body: {
          successUrl: redirectUrl,
          failUrl: redirectUrl,
          ...rest,
        },
      }),
      async onQueryStarted(name, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(paymentsApi.util.invalidateTags(['Products']));
          dispatch(paymentsApi.util.invalidateTags(['UpsellProducts']));
        } catch (error) {
          console.error('Cancel Subscription failed', error);
        }
      },
    }),

    getLifetimeProduct: builder.query<SubscriptionProduct[], void>({
      query: () => `Payments/lifetime-subscription-products`,
    }),

    getUserCardInfoYuno: builder.query<UserCardType[], void | null>({
      query: () => `YunoPayments/user-card-info`,
      providesTags: ['UserCardInfo'],
    }),

    createResignIntent: builder.mutation<
      ResignRequest & { createdUserId?: string },
      {
        productId: string;
        userCardInfoId: string;
        redirectUrl: string;
        sourcePath: string;
        customerPlatform: string;
        userAgent: string;
      }
    >({
      query: ({
        productId,
        userCardInfoId,
        redirectUrl,
        sourcePath,
        customerPlatform,
        userAgent,
      }) => ({
        url: `Payments/create-resign-intent`,
        method: 'POST',
        body: {
          productId,
          userCardInfoId,
          successUrl: redirectUrl,
          failUrl: redirectUrl,
          sourcePath,
          customerPlatform,
          userAgent,
        },
      }),
    }),

    getUserCardInfo: builder.query<UserCardType[], void | null>({
      query: () => `Payments/user-card-info`,
      providesTags: ['UserCardInfo'],
    }),

    deleteUserCardInfo: builder.mutation<void, string>({
      query: (cardId) => ({
        url: `Payments/user-card-info?cardId=${cardId}`,
        method: 'DELETE',
      }),
    }),

    saveCardDetails: builder.mutation<void, string>({
      query: (orderId) => ({
        url: `Payments/save-card-details`,
        method: 'POST',
        body: { orderId },
      }),
    }),

    getUpsellProducts: builder.query<TokensProduct[], void | null>({
      query: () => `Payments/upsell-products`,
      providesTags: ['UpsellProducts'],
    }),

    renewSubscription: builder.mutation<void, string | undefined>({
      query: (subscriptionId = '') => ({
        url: `Payments/subscription/${subscriptionId}/restore`,
        method: 'POST',
      }),
      async onQueryStarted(name, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(paymentsApi.util.invalidateTags(['Products']));
          dispatch(paymentsApi.util.invalidateTags(['UpsellProducts']));
          dispatch(paymentsApi.util.invalidateTags(['ActiveSubscription']));
          dispatch(userApi.util.invalidateTags(['User']));
        } catch (error) {
          console.error('Cancel Subscription failed', error);
        }
      },
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetActiveSubscriptionQuery,
  useCancelSubscriptionMutation,
  useGetTokensQuery,
  useGetTokenProductsQuery,
  useCreatePaymentIntentMutation,
  useUpdateSubscriptionMutation,
  useGetUserCardInfoQuery,
  useCreateResignIntentMutation,
  useDeleteUserCardInfoMutation,
  useSaveCardDetailsMutation,
  useGetProductsAdminQuery,
  useGetTokenProductsAdminQuery,
  useGetUpsellProductsQuery,
  useGetLifetimeProductQuery,
  useCreatePaymentIntentYunoMutation,
  useCreatePaymentYunoMutation,
  useGetUserCardInfoYunoQuery,
  useLazyGetUserCardInfoQuery,
  useLazyGetUserCardInfoYunoQuery,
  useRenewSubscriptionMutation,
} = paymentsApi;
