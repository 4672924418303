import { RouteObject } from 'react-router-dom';

import { Layout } from '@/layout';

import { CommonRoutes } from './CommonRoutes.tsx';

export const PublicRoutes: RouteObject = {
  path: '/',
  element: <Layout />,
  children: CommonRoutes,
};
