import { useEffect } from 'react';

import { ModalCancelConfirmationWithImage } from '@/components/Modals/ModalCancelConfirmationWithImage';
import {
  SUBSCRIPTION_CANCELLATION_LIFETIME_DOWNSELL_ARE_YOU_SURE_CLOSED_EVENT,
  SUBSCRIPTION_CANCELLATION_LIFETIME_DOWNSELL_ARE_YOU_SURE_LOSE_ACCESS_EVENT,
  SUBSCRIPTION_CANCELLATION_LIFETIME_DOWNSELL_ARE_YOU_SURE_SHOWN_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { CancellationComponentProps } from '@/pages/pricing/CancellationSubscription/types.ts';
import { selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';

import girlImage from '@/assets/images/tokens/upsell-tokens-2.webp';

export const OfferCancelConfirmation = ({
  setStep,
}: CancellationComponentProps) => {
  const user = useAppSelector(selectUser);

  useEffect(() => {
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_LIFETIME_DOWNSELL_ARE_YOU_SURE_SHOWN_EVENT,
      user,
    );
  }, []);

  const onAction = () => {
    setStep('unsubscribe');
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_LIFETIME_DOWNSELL_ARE_YOU_SURE_LOSE_ACCESS_EVENT,
      user,
    );
  };
  const onClose = () => {
    setStep('offer');
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_LIFETIME_DOWNSELL_ARE_YOU_SURE_CLOSED_EVENT,
      user,
    );
  };

  return (
    <ModalCancelConfirmationWithImage
      handleAction={onAction}
      handleClose={onClose}
      closeButtonStyle="withBackground"
      badgeText="Don't leave me Baby...😔"
      title="Are you sure? It’s one-time offer."
      imageUrl={girlImage}
      description="If you close this window, you won’t be able to get this deal again."
      actionButtonText="Yes, lose access"
      cancelButtonText="No, go back"
      disableAppearanceAnimation
    />
  );
};
