import { useRoutes } from 'react-router-dom';

import NotFoundPage from '@/pages/404/404.tsx';
import { PublicRoutes } from '@/routes/pubicRoutes.tsx';

import { getTokensFromLocalStorage } from '../utils/authUtils';
import { ProtectedRoutes } from './protectedRoutes';

export const AppRouter = () => {
  const { accessToken } = getTokensFromLocalStorage();

  const routes = accessToken ? ProtectedRoutes : PublicRoutes;

  return useRoutes([routes, { path: '*', element: <NotFoundPage /> }]);
};
