import classNames from 'classnames';

import { Button } from '@/components/Button';
import { Loader } from '@/components/Loader';
import { PaymentFormWrapper } from '@/components/Modals/ModalCheckout/components/PaymentFormWrapper';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectIsAuth } from '@/store/public';

import { PaymentFormProps } from './types.ts';

import styles from './styles.module.scss';

import ErrorIcon from '@/assets/icons/yuno/error.svg?react';

export const PaymentForm = ({
  OrderSummary,
  handleSubmitPayment,
  userEmail,
  handleEmailChange,
  emailError,
  showLoader,
  isHidden,
}: PaymentFormProps) => {
  const isAuth = useAppSelector(selectIsAuth);

  return (
    <PaymentFormWrapper isHidden={isHidden} OrderSummary={OrderSummary}>
      <div className={styles.formWrapper}>
        {showLoader && (
          <Loader
            className={classNames(styles.loader, isAuth && styles.withoutEmail)}
          />
        )}
        {!isAuth && (
          <div
            className={classNames(
              styles.emailField,
              emailError && styles.withError,
            )}
          >
            <input
              value={userEmail}
              onChange={handleEmailChange}
              type="text"
              id="yuno-email-field"
              placeholder=""
            />
            <label htmlFor="yuno-email-field">E-mail</label>
            <p
              className={classNames(styles.error, emailError && styles.visible)}
            >
              <ErrorIcon />
              {emailError}
            </p>
          </div>
        )}
        <div id="yuno-payment-form-root" className={styles.rootForm} />
        <div id="yuno-payment-form" />
        <div id="yuno-payment-form-elements" />
        <Button
          disabled={showLoader}
          onClick={handleSubmitPayment}
          className={styles.submitButton}
          fullWidth
        >
          Continue
        </Button>
      </div>
    </PaymentFormWrapper>
  );
};
