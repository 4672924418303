import classNames from 'classnames';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';
import { ModalCheckout } from '@/components/Modals/ModalCheckout';
import { PRICING_INITIAL_SOURCE_KEY, PRICING_SOURCE_KEY } from '@/const';
import {
  TOKENS_UPSELL_POPUP_CLICKED_EVENT,
  TOKENS_UPSELL_POPUP_CLOSE_CONFIRMED_EVENT,
  TOKENS_UPSELL_POPUP_CLOSE_REJECTED_EVENT,
  TOKENS_UPSELL_POPUP_CLOSED_EVENT,
  TOKENS_UPSELL_POPUP_SHOWN_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useBoolean } from '@/hooks/useBoolean.tsx';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { ModalUpsellConfirmation } from '@/pages/pricing/ModalUpsellConfirmation';
import { useGetUpsellProductsQuery } from '@/services/paymentsServices';
import { selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';
import { ls } from '@/utils/localStorage.ts';
import { navigateAfterPurchase } from '@/utils/navigateAfterPurchase.ts';

import { getUpsellProductDetails } from './helpers.tsx';
import { ModalUpsellTokensProps } from './types.ts';

import styles from './styles.module.scss';

import premiumGirl from '@/assets/images/tokens/upsell-tokens.jpeg';

export const ModalUpsellTokens = ({
  closeUpsellFlow,
}: ModalUpsellTokensProps) => {
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);
  const [
    isModalConfirmationOpened,
    openModalConfirmation,
    closeModalConfirmation,
  ] = useBoolean();
  const [isModalCheckoutOpened, openModalCheckout, closeModalCheckout] =
    useBoolean();

  const { data } = useGetUpsellProductsQuery();
  const selectedProduct = data?.[0] || null;

  useEffect(() => {
    pushToDataLayer(TOKENS_UPSELL_POPUP_SHOWN_EVENT, user);
  }, []);

  const onAction = () => {
    pushToDataLayer(TOKENS_UPSELL_POPUP_CLICKED_EVENT, user);
    openModalCheckout();
    const initialSource = ls.get(PRICING_SOURCE_KEY, '');
    if (initialSource) ls.set(PRICING_INITIAL_SOURCE_KEY, initialSource);
    ls.set(PRICING_SOURCE_KEY, 'upsell_tokens');
  };

  const onClose = () => {
    pushToDataLayer(TOKENS_UPSELL_POPUP_CLOSED_EVENT, user);
    openModalConfirmation();
  };

  const {
    productTitle,
    productPreviousPrice,
    productPrice,
    tokenCount,
    tokenBonus,
    tokenAmount,
  } = getUpsellProductDetails(selectedProduct);

  const eventParams = {
    paywall_type: 'default_tokens',
    source: 'tokens_upsell',
  };

  return (
    <>
      <Modal
        closeButtonStyle="withBackground"
        onClose={onClose}
        disableBackdropClick
        disableEscapeKeyDown
        disableAppearanceAnimation
        maxWidth={450}
        noPadding
      >
        <img
          src={premiumGirl}
          alt="Premium Girl"
          className={styles.girlImage}
        />
        <p className={styles.badge}>Exclusive Offer Just for You!</p>
        <div className={styles.content}>
          <p className={styles.title}>Starter Pack</p>
          <p className={styles.description}>
            Buy {tokenCount} tokens and receive +{tokenBonus} bonus tokens for
            free
          </p>

          <div className={styles.pricing}>
            <div className={classNames(styles.flex, styles.bold)}>
              <div className={styles.tokensAmount}>
                <p>{tokenCount} Tokens</p>
                <p className={styles.bonusBadge}>+{tokenBonus} Bonus</p>
              </div>
              <p>${productPrice}</p>
            </div>
            <div className={classNames(styles.flex, styles.gray)}>
              <p>You will receive {tokenAmount} tokens</p>
              <p className={styles.prevPrice}>${productPreviousPrice}</p>
            </div>
          </div>

          <Button
            onClick={onAction}
            disabled={!data}
            className={styles.actionButton}
            fullWidth
          >
            Get {tokenAmount} Tokens Now
          </Button>

          <p className={styles.disclaimer}>
            This offer is available only now, on this screen.
          </p>
        </div>
      </Modal>

      {isModalCheckoutOpened && (
        <ModalCheckout
          onClose={closeModalCheckout}
          productTitle={productTitle}
          selectedProduct={selectedProduct}
          handlePaymentSuccess={closeUpsellFlow}
          paywallType={eventParams.paywall_type}
        />
      )}

      {isModalConfirmationOpened && (
        <ModalUpsellConfirmation
          handleAction={() => {
            pushToDataLayer(TOKENS_UPSELL_POPUP_CLOSE_REJECTED_EVENT, user);
            closeModalConfirmation();
          }}
          handleClose={() => {
            pushToDataLayer(TOKENS_UPSELL_POPUP_CLOSE_CONFIRMED_EVENT, user);
            closeUpsellFlow();
            navigateAfterPurchase(navigate);
          }}
        />
      )}
    </>
  );
};
