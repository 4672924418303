import * as Sentry from '@sentry/react';
import React, { ReactNode } from 'react';

import { Button } from '@/components/Button';

import styles from './styles.module.scss';

import errorImage from '@/assets/images/error.webp';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  isViteError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { hasError: false, isViteError: false };

  static getDerivedStateFromError(error: Error) {
    const isViteError = error.message.includes(
      'Failed to fetch dynamically imported module',
    );
    return { hasError: !isViteError, isViteError };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (!this.state.isViteError) {
      console.error(error, errorInfo);
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <section className={styles.error}>
          {!this.state.isViteError && (
            <>
              <img src={errorImage} alt="Error" />
              <p className={styles.title}>Something went wrong</p>
              <p className={styles.description}>
                We’re sorry, but something went wrong on our site. We’re already
                working to fix it. Please try refreshing the page or go back to
                the homepage.
              </p>
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                Back to Home
              </Button>
            </>
          )}
        </section>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
