import {
  getStatusColor,
  getPasswordStrength,
  getActiveBars,
} from './helpers.ts';
import { ValidationPasswordType } from './types.ts';

import styles from './styles.module.scss';

export const ValidationPassword = ({ password }: ValidationPasswordType) => {
  const passwordStrength = getPasswordStrength(password);

  const activeBars = getActiveBars(passwordStrength);

  const progressBars = [1, 2, 3, 4].map((_, index) => (
    <div
      key={index}
      className={styles.progress}
      style={{
        backgroundColor:
          index < activeBars ? getStatusColor(passwordStrength) : '#181818',
      }}
    />
  ));

  return <div className={styles.validation}>{progressBars}</div>;
};
