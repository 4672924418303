import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

import { PaymentNotificationResponse } from '@/components/Modals/ModalCheckout/types.ts';
import {
  CONFIRMED_TERMS_KEY,
  NSFW_ENABLED_KEY,
  PRICING_MODEL_ID_KEY,
  PRICING_MODEL_NAME_KEY,
  PRICING_MODEL_TYPE_KEY,
  PRICING_SOURCE_KEY,
} from '@/const';
import { FingerprintData } from '@/hooks/useFingerprint.tsx';
import { getProductPeriod } from '@/pages/pricing/PricingBlock/helpers.tsx';
import { getDownsellPrice } from '@/pages/pricing/helpers.ts';
import {
  SubscriptionProduct,
  TokensProduct,
} from '@/services/paymentsServices/types.ts';
import { UserActiveSubscriptionType } from '@/services/userServices/types.ts';
import { convertCentsToDollars } from '@/utils/convertCentsToDollars.ts';
import { getFingerprintHash } from '@/utils/getFingerprintHash.ts';
import { ls } from '@/utils/localStorage.ts';

export const getCommonPaymentParams = async (
  productId: string,
  isPurchaseTokens = false,
  isDownsellPayment = false,
) => {
  const currentUrl = `${window.location.origin}${window.location.pathname}`;
  const userAgent = navigator.userAgent;
  const sourcePath = isPurchaseTokens ? '/tokens' : '/pricing';
  const customerPlatform = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(
    userAgent,
  )
    ? 'MOB'
    : 'WEB';
  const confirmedTerms = ls.get(CONFIRMED_TERMS_KEY, '');
  const fingerprintData = (await getFingerprint(true)) as FingerprintData;
  const fingerprint = getFingerprintHash(fingerprintData);
  const utmData = ls.get(NSFW_ENABLED_KEY, {});
  const utmSource = utmData?.UtmSource || '';

  return {
    productId,
    redirectUrl: currentUrl,
    sourcePath,
    customerPlatform,
    userAgent,
    trafficSource: utmSource,
    fingerprint,
    isNConfirmed: confirmedTerms === 'n',
    isSConfirmed: !!confirmedTerms,
    isDownsellPayment,
  };
};

export const getIsTokensProduct = (
  product: SubscriptionProduct | TokensProduct | null,
): product is TokensProduct => {
  if (!product) return false;
  return 'tokenCount' in product;
};

export const getEventParams = (paywallType: string) => {
  const source = ls.get(PRICING_SOURCE_KEY, '');
  const modelId = ls.get(PRICING_MODEL_ID_KEY, '');
  const modelType = ls.get(PRICING_MODEL_TYPE_KEY, '');
  const modelName = ls.get(PRICING_MODEL_NAME_KEY, '');

  return {
    paywall_type: paywallType,
    ...(source && { source }),
    ...(modelId && { persona_id: modelId }),
    ...(modelType && { persona_type: modelType }),
    ...(modelName && { persona_name: modelName }),
  };
};

export const getProductDetails = (
  selectedProduct: SubscriptionProduct | TokensProduct | null,
  additionalDiscountPercent: number,
  paywallType: string,
) => {
  const isTokensProduct = getIsTokensProduct(selectedProduct);
  const initialTotalPrice =
    additionalDiscountPercent && !isTokensProduct && selectedProduct
      ? getDownsellPrice(selectedProduct)
      : convertCentsToDollars(selectedProduct?.currentPrice);
  const paywall_type = isTokensProduct
    ? 'default_tokens'
    : 'default_subscription';
  const product_id = isTokensProduct
    ? `tokens_${selectedProduct.tokenAmount}_${selectedProduct.currentPrice}`
    : `subscription_${getProductPeriod(
        selectedProduct?.periodType,
        selectedProduct?.periodValue,
      )}_${selectedProduct?.currentPrice}`;
  const eventParams = getEventParams(paywallType);

  return {
    initialTotalPrice,
    paywall_type,
    product_id,
    eventParams,
  };
};

export const normalizeSubscription = (
  subscription: PaymentNotificationResponse['subscription'],
): UserActiveSubscriptionType => {
  if (!subscription) return subscription;

  return {
    id: subscription.id,
    productId: subscription.productId,
    priceId: subscription.priceId,
    startDate: subscription.startDate,
    endDate: subscription.endDate,
    status: subscription.status,
    userId: subscription.userId,
    ...(subscription.cancellationRequestedAt && {
      cancellation_requested_at: subscription.cancellationRequestedAt,
    }),
  };
};
