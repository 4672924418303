import { memo } from 'react';

import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';

import { ModalActionResultProps } from './types.ts';

import styles from './styles.module.scss';

import WarningIcon from '@/assets/icons/chat/warning.svg?react';
import SuccessIcon from '@/assets/icons/success-green-check.svg?react';

const ModalActionResult = ({
  variant = 'success',
  onClose,
  onAction,
  title,
  description,
  actionButtonLabel,
}: ModalActionResultProps) => {
  const isSuccess = variant === 'success';

  return (
    <Modal onClose={onClose} closeButtonStyle="absolute" maxWidth={450}>
      <div className={styles.container}>
        {isSuccess ? (
          <SuccessIcon className={styles.successIcon} />
        ) : (
          <WarningIcon className={styles.failedIcon} />
        )}
        <p className={styles.heading}>{title}</p>
        <p className={styles.description}>{description}</p>

        <Button onClick={onAction} fullWidth>
          {actionButtonLabel}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(ModalActionResult);
