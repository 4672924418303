import { UserType } from '@/services/userServices/types.ts';
import { getIsNSFWContent, getIsNSFWUser } from '@/utils/getIsNSFWUser.ts';

const sfwFeatures = [
  'Generate photos',
  'Get 100 tokens every month',
  'Unlock chat photos',
  'Create your own AI Characters',
  'Fast response',
  'New features priority use',
  'Content privacy',
  'And much more!',
];

const sfwFeaturesMobile = [
  'Generate photos',
  'Get 100 tokens every month',
  'Create your own AI Characters',
  'And much more!',
];

const nsfwFeatures = (isNSFWContent: boolean) => [
  'Generate photos',
  'Get 100 tokens every month',
  'Extremely Spicy Content 🌶',
  'Unlock chat photos',
  `Create your own ${isNSFWContent ? 'girlfriend' : 'AI Characters'}`,
  'Fast response',
  'New features priority use',
  'Content privacy',
  'And much more!',
];

const nsfwFeaturesTable = [
  'Generate photos',
  'Get 100 tokens every month',
  'Extremely Spicy Content 🌶',
  'And much more!',
];

export const getDefaultPremiumFeatures = (
  user: UserType | null,
  isTableView: boolean,
) => {
  const isNSFWUser = getIsNSFWUser(user);
  const isNSFWContent = getIsNSFWContent(user);

  if (isNSFWUser) {
    return isTableView ? nsfwFeaturesTable : nsfwFeatures(isNSFWContent);
  }

  return isTableView ? sfwFeaturesMobile : sfwFeatures;
};
