import { useEffect, useLayoutEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Routes } from '@/const/enum.ts';

import styles from './styles.module.scss';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const newUrl = `${Routes.MAIN_PAGE}?${searchParams.toString()}`;
    navigate(newUrl);
  }, []);

  return (
    <section className={styles.container}>
      <p className={styles.error}>404</p>
      <p className={styles.title}>Oh, we can’t find that page...</p>
      <p className={styles.description}>
        Oops! It looks like the page you're trying to reach doesn't exist.
      </p>
      <Button link={Routes.MAIN_PAGE}>Back to Home</Button>
    </section>
  );
};

export default NotFoundPage;
