import { Routes } from '@/const/enum.ts';

import CrownIcon from '@/assets/icons/crown.svg?react';
import ChatIcon from '@/assets/icons/sidebar/chat.svg?react';
import CreateCharacterIcon from '@/assets/icons/sidebar/create-character.svg?react';
import ExploreIcon from '@/assets/icons/sidebar/explore.svg?react';
import TokenIcon from '@/assets/icons/token.svg?react';

export const getBottomNavigationLinks = (
  hasActiveSubscription: boolean,
  isLoading: boolean,
) => [
  {
    link: Routes.MAIN_PAGE,
    Icon: ExploreIcon,
    label: 'Explore',
    id: 'exp_btn_bottom',
  },
  {
    link: Routes.CHAT_PAGE,
    Icon: ChatIcon,
    label: 'Chat',
    id: 'chat_btn_bottom',
  },
  {
    link: Routes.CREATE_CHARACTER_PAGE,
    Icon: CreateCharacterIcon,
    label: 'Create',
    id: 'create_btn_bottom',
  },
  ...(hasActiveSubscription
    ? [
        {
          link: Routes.BUY_TOKENS,
          Icon: TokenIcon,
          label: 'Tokens',
          id: 'tokens_btn_bottom',
          isLoading,
        },
      ]
    : [
        {
          link: Routes.PRICING_PAGE,
          Icon: CrownIcon,
          label: 'Premium',
          id: 'premium_btn_bottom',
          isLoading,
        },
      ]),
];
