import { useEffect } from 'react';

import { Button } from '@/components/Button';
import { CheckboxGroup } from '@/components/CheckboxGroup';
import { Modal } from '@/components/Modals/Modal';
import {
  SUBSCRIPTION_CANCELLATION_IMPROVEMENT_LIST_POPUP_CLOSED_EVENT,
  SUBSCRIPTION_CANCELLATION_IMPROVEMENT_LIST_POPUP_SENT_EVENT,
  SUBSCRIPTION_CANCELLATION_IMPROVEMENT_LIST_POPUP_SHOWN_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { CancellationComponentProps } from '@/pages/pricing/CancellationSubscription/types.ts';
import { selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';

import { CANCELLATION_CHANGES_OPTIONS } from './constants.ts';

import styles from './styles.module.scss';

export const CancellationChanges = ({
  setStep,
  onClose,
  answers,
  handleUpdateAnswers,
}: CancellationComponentProps) => {
  const user = useAppSelector(selectUser);

  useEffect(() => {
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_IMPROVEMENT_LIST_POPUP_SHOWN_EVENT,
      user,
    );
  }, []);

  const onChange = (selectedOption: string[]) => {
    handleUpdateAnswers('changes', selectedOption);
  };

  const onAction = () => {
    setStep('features');
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_IMPROVEMENT_LIST_POPUP_SENT_EVENT,
      user,
      { improvement_list: JSON.stringify(answers.changes) },
    );
  };
  const handleClose = () => {
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_IMPROVEMENT_LIST_POPUP_CLOSED_EVENT,
      user,
    );
    onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      closeButtonStyle="absolute"
      disableAppearanceAnimation
      maxWidth={550}
    >
      <div className={styles.container}>
        <p className={styles.heading}>
          What do you want to change in the product?
        </p>
        <CheckboxGroup
          options={CANCELLATION_CHANGES_OPTIONS}
          selectedOptions={answers.changes}
          onChange={onChange}
        />

        <Button disabled={!answers.changes.length} onClick={onAction} fullWidth>
          Continue
        </Button>
      </div>
    </Modal>
  );
};
