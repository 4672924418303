import { Skeleton } from '@mui/material';

import { TokensProduct } from '@/services/paymentsServices/types.ts';
import { convertCentsToDollars } from '@/utils/convertCentsToDollars.ts';

export const getUpsellProductDetails = (
  selectedProduct: TokensProduct | null,
) => {
  return {
    productTitle: `${selectedProduct?.tokenAmount} Tokens`,
    productPreviousPrice:
      convertCentsToDollars(selectedProduct?.previousPrice) || 19.99,
    productPrice: convertCentsToDollars(
      selectedProduct?.currentPrice,
      true,
    ) || <Skeleton height={22} width={35} className="skeleton-loader" />,
    tokenAmount: selectedProduct?.tokenAmount || (
      <Skeleton height={22} width={28} className="skeleton-loader" />
    ),
    tokenCount: selectedProduct?.tokenCount || (
      <Skeleton height={22} width={28} className="skeleton-loader" />
    ),
    tokenBonus: selectedProduct?.tokenBonus || (
      <Skeleton height={20} width={28} className="skeleton-loader" />
    ),
  };
};
