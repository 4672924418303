import { useCallback, useEffect, useRef, useState } from 'react';

export const useOutsideClick = <T extends HTMLElement = HTMLDivElement>(
  initialValue = false,
) => {
  const [isActive, setIsActive] = useState(initialValue);
  const ref = useRef<T>(null);

  const toggleIsActive = useCallback(() => {
    setIsActive((prev) => !prev);
  }, []);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        toggleIsActive();
      }
    },
    [toggleIsActive],
  );

  useEffect(() => {
    if (isActive) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isActive, handleClick]);

  return { ref, isActive, setIsActive, toggleIsActive };
};
