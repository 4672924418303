import { SUPPORT_EMAIL } from '@/const';

export const SPECIAL_SUTTON_BANK_ERROR = {
  code: '3.04',
  message: (
    <>
      Apologies <span>we can not Process payments</span> from{' '}
      <strong>Sutton Bank</strong> or <strong>CashApp</strong>.
      <br />
      Please <span>use another card</span> to proceed.
    </>
  ),
  isSuttonBankError: true,
};

export const DEFAULT_PAYMENT_ERROR_MESSAGE =
  'The payment attempt was unsuccessful because your account was not debited for the service. This might be due to insufficient funds, incorrect payment details, or a temporary issue with your bank. Please verify your account information and try again!';

export const PAYMENT_ERRORS = {
  '0.01':
    "Oh no! The card issuer couldn't process your payment.\n" +
    'Please try again or use a different card.',
  '0.02':
    'Uh-oh! The time to complete this order is up ⌛️\n' +
    ' Please go through the payment process again.',
  '0.03':
    'Oh no! Your transaction was blocked.\n' +
    'Maybe everything will go smoothly with another card?\n' +
    "If it doesn't work for you, contact your card issuer.",
  '1.01':
    "Oops! Your card issuer couldn't identify you. Seems it's somewhat absent-minded today.\n" +
    'Give it another chance (or a couple of chances) with the same card.\n' +
    'Alternatively, contact your card issuer.',
  '2.01':
    "Seems like there's an issue with your card data 🤨\n" +
    `Try one more time – it should go smoothly this time. If not, use another card or contact us at ${SUPPORT_EMAIL}`,
  '2.02':
    "Oh no! The card issuer couldn't process your payment.\n" +
    'Please try again or use a different card.',
  '2.03':
    "Oh no! We don't yet support payments in this currency 😔\n" +
    `Please try a different card or contact our Billing Support at ${SUPPORT_EMAIL} for assistance.`,
  '2.05':
    'Oops, something went wrong with your order 🙀\n' +
    `Please try a different card or contact our Billing Support at ${SUPPORT_EMAIL} for assistance.`,
  '2.06':
    'Your CVV is tricky today 👻\n' +
    'Please double-check those three tiny digits at the back of your card and retry this payment.',
  '2.07':
    "Oh no! The card issuer couldn't process your payment.\n" +
    'Please try again or use a different card.',
  '2.08':
    'Oh no! Your card number is wrong.\n' +
    "But it's easy to fix 😉\n" +
    "Retype your 16-digit card number – and that's it!",
  '2.09':
    'Please double-check the expiration date of your card (4 digits on the front side).\n' +
    'If your card has expired, proceed with another card.',
  '2.10':
    "Something's wrong with security authentication…\n" +
    `But no worries! Our Billing Support agents have the solution! Contact us at ${SUPPORT_EMAIL}`,
  '2.11':
    'Your card issuer has just mixed something about the 3DS authentication.\n' +
    "Contact them saying it's about 3DS flow on your card – they should know how to fix it.\n" +
    "Once it's done, please try again.",
  '2.12':
    'Something went wrong with the 3DS flow.\n' +
    'Maybe you can use another card?',
  '2.13':
    'Your IP address has failed 🤖\n' +
    "So it's time to move to another browser or close the incognito tab if you're using one.",
  '2.14':
    "Wow! Your order was declined because you're already subscribed to Get Honey. Just sign in to continue.\n" +
    'If you forgot your login details, search for the email from the Get Honey Team.\n' +
    `For more information, please contact us at ${SUPPORT_EMAIL}`,
  '2.15':
    'Oops! Something went wrong with your 3D authentication ⚠️\n' +
    'But no worries! Ask your card issuer to turn on the 3DS flow on your card and see everything working again!',
  '2.16':
    'Oops… We spotted that your subscription had been blocked 😕\n' +
    `But no worries - our Billing Support is ready to help you. Just сontact them at ${SUPPORT_EMAIL}`,
  '3.01':
    'Oh no! Seems that your card was blocked for payments 😨\n' +
    'This can happen when you exceed some payment limits.\n' +
    'Could you use another card? Or contact your card issuer for an explanation?',
  '3.02':
    "Seems you don't have enough money on this card 😳\n" +
    'Try paying with another card or top up this one.',
  '3.03':
    "Oops… we've stumbled on the credit limit.\n" +
    'Contact your card issuer for help, or use another card.\n' +
    `Btw, our Billing Support can also assist you. Contact them at ${SUPPORT_EMAIL}`,
  '3.04': (
    <>
      Seems there are some problems with our bank now. <br /> Please{' '}
      <span>use another card</span> to proceed
    </>
  ),
  '3.05':
    'Your card has been declined.\n' +
    'Please contact your card issuer and ask them to remove the limit for online payments.\n' +
    `Need a more detailed guide? Contact us at ${SUPPORT_EMAIL}`,
  '3.06':
    'Seems there are some bank problems here.\n' +
    'Ask your card issuer to remove the limit for online payments or just use another card to proceed.\n' +
    `Need a more detailed guide? contact us at ${SUPPORT_EMAIL}`,
  '3.07':
    "Our bad 😔 We don't support this card brand yet.\n" +
    `Please use another card or contact us at ${SUPPORT_EMAIL} for more information.`,
  '3.08': (
    <>
      Seems there are some problems with our bank now. <br /> Please{' '}
      <span>use another card</span> to proceed
    </>
  ),
  '3.09':
    'Oops! Something went wrong with your 3D authentication ⚠️\n' +
    'But no worries! Ask your card issuer to turn on the 3DS flow on your card and see everything working again!',
  '3.10': (
    <>
      Your payment was blocked by your card issuer. <br /> What to do? Just{' '}
      <span>use another card</span> to proceed
    </>
  ),
  '3.11': 'The next subscription payment via this card is not allowed.',
  '3.12':
    'The customer has blocked transactions via this card for reasons that require clarification.',
  '4.01 ':
    "Oh no! The card issuer couldn't process your payment.\n" +
    'What to do?\n' +
    `Just contact your card issuer or contact us at ${SUPPORT_EMAIL}`,
  '4.02':
    "Oh no! The card issuer couldn't process your payment.\n" +
    'Please contact your card issuer for more information.',
  '4.03':
    'Oh no! Your card was restricted by the card issuer 😔\n' +
    'Please contact your card issuer and ask to remove the limit for online payments.\n' +
    `You can also try using another card or contact us at ${SUPPORT_EMAIL} for assistance.`,
  '4.04':
    "Oh no! The card issuer couldn't process your payment.\n" +
    'What to do?\n' +
    'Please try again or contact your card issuer for more information.',
  '4.05':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'What to do? \n' +
    `Just contact your card issuer or contact us at ${SUPPORT_EMAIL}`,
  '4.06':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please contact your card issuer for more information.',
  '4.07':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'What to do?\n' +
    `Just contact your card issuer or contact us at ${SUPPORT_EMAIL}`,
  '4.08':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'What to do?\n' +
    `Just contact your card issuer or contact us at ${SUPPORT_EMAIL}`,
  '4.09':
    "Oh no! The card issuer couldn't process your payment. \n" +
    `Contact your card issuer or contact us at ${SUPPORT_EMAIL}`,
  '5.01':
    'Your card issuer has just mixed something about the 3DS authentication.\n' +
    "Please contact your card issuer and say it's about 3DS flow on your card – they should know how to fix everything. \n" +
    "Once it's fixed, try again or use a different card.",
  '5.02':
    "Oh no! The card issuer couldn't process your payment. \n" +
    `Just try using a different card, contact your card issuer, or contact us at ${SUPPORT_EMAIL}`,
  '5.03':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Just try again.\n' +
    `You can also use a different card or contact us at ${SUPPORT_EMAIL}`,
  '5.04':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again or use a different card.',
  '5.05':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again or use a different card.',
  '5.06':
    "Wow! Your order was declined because you're already subscribed to Get Honey. Just sign in to continue. \n" +
    'If you forgot your login details, search for the email or SMS from the Get Honey team. \n' +
    `For more information, please contact us at ${SUPPORT_EMAIL}`,
  '5.07':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again or use a different card.',
  '5.08':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again with a different card.',
  '5.09':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again or use a different card.',
  '5.10':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again or use a different card.',
  '5.11':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again or use a different card.',
  '5.12':
    'The account is blocked and all transactions made through this account will be declined by the payment processor or acquiring bank until the problem is resolved.',
  '6.01':
    "Oh no! The card issuer couldn't process your payment. \n" +
    `Just try using a different card or contact us at ${SUPPORT_EMAIL}`,
  '6.02':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Just try using a different card.\n' +
    `Also, you can contact your card issuer or contact us at ${SUPPORT_EMAIL}`,
  '6.03':
    'The payment failed due to a technical issue on the provider’s or processor’s side.',
  '7.01':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Just try using a different card.\n' +
    `Also, you can contact your card issuer or contact us at ${SUPPORT_EMAIL}`,
  '7.02':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again or use a different card.',
  '7.03':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again or use a different card.',
  '7.04':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again or use a different card.',
  '7.05':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again or use a different card.',
  '7.06':
    "Oh no! The card issuer couldn't process your payment. \n" +
    'Please try again or use a different card.',
  '7.07':
    'A Strong Customer Authentication (SCA) error occurred during MIT payment processing because the schema transaction ID taken from the first PSP transaction is missing.',
};

export const DEFAULT_PAYMENT_ERROR_TITLE = 'Payment declined';

export const PAYMENT_ERROR_TITLE: { [key: string]: string } = {
  '2.01': 'Invalid data',
  '2.03': 'Invalid currency',
  '2.06': 'Invalid CVV2 code',
  '2.08': 'Invalid card number',
  '2.11': 'Invalid 3DS flow on the bank side',
  '2.12': 'Invalid 3DS flow',
  '3.02': 'Insufficient funds',
  '3.03': 'Payment amount limit excess',
  '3.04': 'YOUR Bank Declined this Payment!\nUse card from Different Bank',
  '3.05': 'Call your bank',
  '3.08': 'YOUR Bank Declined this Payment!\nUse card from Different Bank',
  '3.09': '3D-Secure authentication required',
  '3.10': 'YOUR Bank Declined this Payment!\nUse card from Different Bank',
  '5.01': '3D secure verification failed',
};

export const DEFAULT_FREE_TOKENS_ERROR =
  "We are sorry such inconvenience, we'll give you 20 additional tokens upon successful payment";

export const FREE_TOKENS_ERROR: { [key: string]: string } = {
  '3.04': 'For such inconvenience we offer you 20 additional tokens',
  '3.08': 'For such inconvenience we offer you 20 additional tokens',
};

export const DEFAULT_FREE_TOKENS_IMAGE_TEXT = 'Try Again';

export const FREE_TOKENS_IMAGE_TEXT: { [key: string]: string } = {
  '3.04': 'Try Another Card',
  '3.08': 'Try Another Card',
  '3.10': 'Try Another Card',
};
