import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectUser } from '@/store/public';
import { getIsNSFWContent, getIsNSFWUser } from '@/utils/getIsNSFWUser.ts';

export const useContentType = () => {
  const user = useAppSelector(selectUser);
  const isNSFWUser = getIsNSFWUser(user);
  const isNSFWContent = getIsNSFWContent(user);

  return {
    user,
    isNSFWUser,
    isNSFWContent,
  };
};
