import classNames from 'classnames';

import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';
import { Spinner } from '@/components/Spinner';

import { ModalUpsellConfirmationWithImageProps } from './types.ts';

import styles from './styles.module.scss';

import premiumGirl from '@/assets/images/tokens/upsell-tokens-2.webp';

export const ModalCancelConfirmationWithImage = ({
  handleAction,
  handleClose,
  badgeText,
  title,
  description,
  actionButtonText,
  cancelButtonText,
  imageUrl,
  isLoadingAction,
  isLoadingCancellation,
  buttonsContainerClassName,
  disableAppearanceAnimation,
  closeButtonStyle = 'hidden',
}: ModalUpsellConfirmationWithImageProps) => {
  return (
    <Modal
      closeButtonStyle={closeButtonStyle}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      disableAppearanceAnimation={disableAppearanceAnimation}
      maxWidth={450}
      noPadding
    >
      <img
        src={imageUrl || premiumGirl}
        alt="Premium Girl"
        className={styles.girlImage}
      />
      <p className={styles.badge}>{badgeText}</p>
      <div className={styles.content}>
        <p
          className={classNames(
            styles.title,
            !description && styles.noDescription,
          )}
        >
          {title}
        </p>
        {description && <p className={styles.description}>{description}</p>}

        <div
          className={classNames(
            styles.actionButtons,
            buttonsContainerClassName,
          )}
        >
          <Button
            disabled={isLoadingCancellation || isLoadingAction}
            variant="disabled"
            onClick={handleAction}
            fullWidth
          >
            {isLoadingAction ? <Spinner /> : actionButtonText}
          </Button>

          <Button
            disabled={isLoadingCancellation || isLoadingAction}
            onClick={handleClose}
            fullWidth
          >
            {isLoadingCancellation ? <Spinner /> : cancelButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
