import { useCallback, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Routes } from '@/const/enum.ts';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectPrePaywallImage } from '@/store/chat';
import {
  closeModalPaywallReducer,
  closeModalPrePaywallReducer,
  openModalPaywallReducer,
  openModalPrePaywallReducer,
  selectEventsState,
  selectIsModalPaywallOpened,
  selectIsModalPrePaywallOpened,
  setEventsStateReducer,
} from '@/store/paywall';
import { selectUser } from '@/store/public';

import defaultPrePaywallImage from '@/assets/images/premiunGirl.webp';

export const usePaywallModals = () => {
  const dispatch = useAppDispatch();
  const isInIframe = window.self !== window.top;
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const user = useAppSelector(selectUser);
  const isModalPrePaywallOpened = useAppSelector(selectIsModalPrePaywallOpened);
  const isModalPaywallOpened = useAppSelector(selectIsModalPaywallOpened);
  const prePaywallImage =
    useAppSelector(selectPrePaywallImage) || defaultPrePaywallImage;
  const eventsState = useAppSelector(selectEventsState);

  const isTestGroup = user?.featureGroup === 'experiment_example_test_group';

  const openPrePaywallModal = useCallback(
    (eventsState?: Record<string, string>) => {
      if (eventsState) dispatch(setEventsStateReducer(eventsState));
      dispatch(
        isTestGroup ? openModalPaywallReducer() : openModalPrePaywallReducer(),
      );
    },
    [dispatch, isTestGroup],
  );

  const closePrePaywallModal = useCallback(() => {
    dispatch(closeModalPrePaywallReducer());
  }, [dispatch]);

  const openPaywallModal = useCallback(() => {
    dispatch(openModalPaywallReducer());
  }, [dispatch]);

  const closePaywallModal = useCallback(() => {
    dispatch(closeModalPaywallReducer());
    dispatch(closeModalPrePaywallReducer());
  }, [dispatch]);

  useEffect(() => {
    const orderId = searchParams.get('orderId');
    const noModalPaymentRoutes = [Routes.PRICING_PAGE, Routes.BUY_TOKENS];
    if (
      orderId &&
      !isInIframe &&
      !noModalPaymentRoutes.includes(location.pathname as Routes)
    ) {
      openPaywallModal();
    }
  }, [searchParams, openPaywallModal, isInIframe]);

  useEffect(() => {
    return closePaywallModal;
  }, [closePaywallModal]);

  return {
    isModalPrePaywallOpened,
    openPrePaywallModal,
    closePrePaywallModal,

    isModalPaywallOpened,
    openPaywallModal,
    closePaywallModal,

    eventsState,
    isTestGroup,
    prePaywallImage,
  };
};
