import { IS_MIRROR_SVITLO_APP, NSFW_ENABLED_KEY } from '@/const';
import { UserType } from '@/services/userServices/types.ts';
import { ls } from '@/utils/localStorage.ts';

export const getIsNSFWUser = (user: UserType | null): boolean => {
  if (IS_MIRROR_SVITLO_APP) return false;

  return !!user?.nEnabled || !!ls.get(NSFW_ENABLED_KEY, null);
};

export const getIsNSFWContent = (user: UserType | null): boolean => {
  const utmDataSaved = ls.get(NSFW_ENABLED_KEY, {});
  const utmSource = utmDataSaved?.UtmSource;
  const isNSFWUser = getIsNSFWUser(user);

  if (user?.activeSubscription) {
    return isNSFWUser;
  }

  return isNSFWUser && user?.utmSource !== 'google' && utmSource !== 'google';
};
