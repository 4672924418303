export const CANCELLATION_REASON_OPTIONS = [
  {
    label:
      "I worry that I'll forget to cancel my subscription before the end of the introductory period",
    value:
      "I worry that I'll forget to cancel my subscription before the end of the introductory period",
  },
  {
    label: 'The subscription is too expensive for my budget',
    value: 'The subscription is too expensive for my budget',
  },
  {
    label: 'The product hasn’t met my expectations',
    value: 'The product hasn’t met my expectations',
  },
  {
    label: 'I found a better alternative',
    value: 'I found a better alternative',
  },
  {
    label: 'I faced technical issues',
    value: 'I faced technical issues',
  },
  {
    label: 'My reason is not listed',
    value: 'My reason is not listed',
  },
];
