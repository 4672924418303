import * as amplitude from '@amplitude/analytics-browser';
import { Identify } from '@amplitude/analytics-browser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import {
  ANONYMOUS_USER_ID_KEY,
  CONFIRMED_TERMS_KEY,
  IS_MIRROR_SVITLO_APP,
  NSFW_ENABLED_KEY,
  REGISTERED_BEFORE_KEY,
} from '@/const';
import { paymentsApi } from '@/services/paymentsServices';
import { UserCardType } from '@/services/paymentsServices/types.ts';
import { userApi } from '@/services/userServices';
import { UserType } from '@/services/userServices/types.ts';
import { RootState } from '@/store';
import {
  removeTokensFromLocalStorage,
  setTokensInLocalStorage,
} from '@/utils/authUtils.ts';
import { getPlatform } from '@/utils/getPlatform.ts';
import { ls } from '@/utils/localStorage.ts';

type SetAuthPayload = {
  accessToken: string;
  refreshToken: string;
  generatedUserId?: string;
  isLoginOrRegister?: boolean;
};

type InitialStateProps = {
  isAuth: boolean;
  accessToken: string;
  refreshToken: string;
  isSidebarOpened: boolean;
  user: UserType | null;
  isUserFetching: boolean;
  tokens: number;
  spentTokens: number;
  isTokensFetching: boolean;
  userCards: UserCardType[];
  isAnimationDisabled: boolean;
};

const initialState: InitialStateProps = {
  isAuth: false,
  accessToken: '',
  refreshToken: '',
  isSidebarOpened: false,
  user: null,
  isUserFetching: false,
  tokens: 0,
  spentTokens: 0,
  isTokensFetching: false,
  userCards: [],
  isAnimationDisabled: true,
};

const index = createSlice({
  name: 'public',
  initialState,
  reducers: {
    setAuth(
      state,
      {
        payload: {
          accessToken,
          refreshToken,
          isLoginOrRegister = true,
          generatedUserId,
        },
      }: PayloadAction<SetAuthPayload>,
    ) {
      if (!accessToken) {
        removeTokensFromLocalStorage();
        state.accessToken = '';
        state.refreshToken = '';
        state.isAuth = false;
        return;
      }

      setTokensInLocalStorage(accessToken, refreshToken);
      if (generatedUserId) ls.set(ANONYMOUS_USER_ID_KEY, generatedUserId);
      if (isLoginOrRegister) ls.remove(ANONYMOUS_USER_ID_KEY);

      state.isAuth = !ls.get(ANONYMOUS_USER_ID_KEY, '');
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },

    logout(state) {
      removeTokensFromLocalStorage();
      state.accessToken = '';
      state.refreshToken = '';
      state.isAuth = false;
      window.location.href = '/';
    },

    setIsSidebarOpened(state, action: PayloadAction<boolean>) {
      state.isSidebarOpened = action.payload;
    },

    setUser(state, action: PayloadAction<UserType | null>) {
      state.user = action.payload;
    },

    updateUserSubscription(
      state,
      action: PayloadAction<UserType['activeSubscription']>,
    ) {
      if (!state.user) return;

      state.user = {
        ...state.user,
        activeSubscription: action.payload,
      };
    },

    setTokens(state, action: PayloadAction<number>) {
      state.tokens = action.payload;
    },

    setSpentTokens(state, action: PayloadAction<number>) {
      state.spentTokens = action.payload;
    },

    removeUserCard(state, action: PayloadAction<string>) {
      state.userCards = state.userCards.filter(
        (card) => card.id !== action.payload,
      );
    },

    setIsAnimationDisabled(state, action: PayloadAction<boolean>) {
      state.isAnimationDisabled = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getUserInfo.matchPending, (state) => {
      state.isUserFetching = true;
    });
    builder.addMatcher(
      userApi.endpoints.getUserInfo.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        if (payload.email) {
          state.isAuth = true;
        }
        state.isUserFetching = false;
        ls.set(REGISTERED_BEFORE_KEY, 'true');
        if (
          payload.utmSource &&
          payload.nEnabled &&
          !ls.get(NSFW_ENABLED_KEY, null)
        ) {
          ls.set(NSFW_ENABLED_KEY, {
            UtmSource: payload.utmSource,
            ...(payload.url ? { Url: payload.url } : {}),
          });
        }
        if (!payload.nEnabled && !IS_MIRROR_SVITLO_APP) {
          ls.remove(NSFW_ENABLED_KEY);
        }

        if (payload.nEnabled && payload.isNConfirmed) {
          ls.set(CONFIRMED_TERMS_KEY, 'n');
        }
        if (payload.isSConfirmed && !payload.isNConfirmed) {
          ls.set(CONFIRMED_TERMS_KEY, 's');
        }

        if (payload) {
          // set up amplitude
          try {
            const platform = getPlatform();

            const currentUserId = amplitude.getUserId();
            if (currentUserId !== payload.id) {
              amplitude.setUserId(payload.id);
            }
            const identify = new Identify();

            identify.set('is_nsfw', payload.nEnabled);
            identify.set('email', payload.email);
            identify.set('custom_platform', platform);
            identify.set('domain', window.location.hostname);
            Sentry.setUser({
              id: payload.id,
              email: payload.email,
              is_nsfw: payload.nEnabled,
            });

            amplitude.identify(identify);
          } catch (error) {
            console.log('Amplitude error:', error);
          }
        }
      },
    );
    builder.addMatcher(userApi.endpoints.getUserInfo.matchRejected, (state) => {
      state.isUserFetching = false;
      state.user = null;
    });
    builder.addMatcher(
      paymentsApi.endpoints.getTokens.matchPending,
      (state) => {
        state.isTokensFetching = true;
      },
    );
    builder.addMatcher(
      paymentsApi.endpoints.getTokens.matchFulfilled,
      (state, { payload }) => {
        state.tokens = payload.remainingTokens || 0;
        state.isTokensFetching = false;
      },
    );
    builder.addMatcher(
      paymentsApi.endpoints.getTokens.matchRejected,
      (state) => {
        state.isTokensFetching = false;
        state.tokens = 0;
      },
    );
    builder.addMatcher(
      paymentsApi.endpoints.getUserCardInfo.matchFulfilled,
      (state, { payload }) => {
        state.userCards = payload;
      },
    );
    builder.addMatcher(
      paymentsApi.endpoints.getUserCardInfoYuno.matchFulfilled,
      (state, { payload }) => {
        state.userCards = payload;
      },
    );
  },
});

export const selectIsAuth = (state: RootState) => state.public.isAuth;
export const selectAccessToken = (state: RootState) => state.public.accessToken;
export const selectIsSidebarOpened = (state: RootState) =>
  state.public.isSidebarOpened;
export const selectIsLoadingUser = (state: RootState) =>
  state.public.isUserFetching;
export const selectUser = (state: RootState) => state.public.user;
export const selectIsYunoPaymentMethod = (state: RootState) =>
  state.public.user?.preferredPaymentMethod === 'yuno';
export const selectTokens = (state: RootState) => state.public.tokens;
export const selectSpentTokens = (state: RootState) => state.public.spentTokens;
export const selectIsTokensFetching = (state: RootState) =>
  state.public.isTokensFetching;
export const selectUserCards = (state: RootState) => state.public.userCards;
export const selectIsAnimationDisabled = (state: RootState) =>
  state.public.isAnimationDisabled;

export const {
  setAuth,
  logout,
  setSpentTokens,
  setIsSidebarOpened,
  updateUserSubscription,
  setUser,
  removeUserCard,
  setTokens,
  setIsAnimationDisabled,
} = index.actions;
export default index.reducer;
