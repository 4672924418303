import { Skeleton } from '@mui/material';

import styles from './styles.module.scss';

export const BottomNavigationLinkSkeleton = () => {
  return (
    <div className={styles.bottomLink}>
      <Skeleton
        width={20}
        height={20}
        variant="rectangular"
        className={styles.skeleton}
      />
      <Skeleton
        width="80%"
        height={22}
        variant="text"
        className={styles.skeleton}
      />
    </div>
  );
};
