import classNames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '@/const/enum.ts';
import { useOutsideClick } from '@/hooks/useOutsideClick.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectUser, setIsSidebarOpened } from '@/store/public';
import { setDefaultImgOnError } from '@/utils/setDefaultImgOnError.ts';

import { Logout } from './Logout';

import styles from './styles.module.scss';

import CrownIcon from '@/assets/icons/crown.svg?react';
import CaretLeftIcon from '@/assets/icons/header/carret-down.svg?react';
import SettingsIcon from '@/assets/icons/header/settings.svg?react';
import UserIcon from '@/assets/icons/header/user.svg?react';

export const Profile = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const [isImageError, setIsImageError] = useState(false);

  const { ref, isActive, toggleIsActive } = useOutsideClick();

  return (
    <div ref={ref} className={styles.container}>
      <button
        id="profile_icon"
        className={classNames(styles.dropdownButton, isActive && styles.active)}
        onClick={toggleIsActive}
      >
        {user?.userProfileUrl && !isImageError ? (
          <img
            src={user.userProfileUrl}
            width={46}
            height={46}
            className={styles.profileImage}
            alt="Profile logo"
            onError={() => setIsImageError(true)}
          />
        ) : (
          <div className={styles.userIconWrapper}>
            <UserIcon />
          </div>
        )}

        <p className={styles.yourProfileText}>Your profile</p>

        <CaretLeftIcon
          className={classNames(styles.caretDown, isActive && styles.active)}
        />
      </button>

      <div className={classNames(styles.menu, !isActive && styles.hidden)}>
        {!user?.activeSubscription && (
          <Link
            to={Routes.PRICING_PAGE}
            state={{ source: 'header' }}
            id="icon_subscr_btn"
            onClick={() => {
              toggleIsActive();
              dispatch(setIsSidebarOpened(false));
            }}
            className={styles.menuItem}
          >
            <CrownIcon className={styles.crownIcon} />
            <span>Subscription</span>
          </Link>
        )}
        <Link
          to={Routes.SETTINGS_PAGE}
          onClick={() => {
            toggleIsActive();
            dispatch(setIsSidebarOpened(false));
          }}
          className={styles.menuItem}
          id="set_btn"
        >
          <SettingsIcon />
          <span>Settings</span>
        </Link>
        <Logout onAction={toggleIsActive} />
      </div>
    </div>
  );
};
