import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { getPremiumBenefits } from '@/pages/pricing/PricingBlock/constants.ts';
import { selectUser } from '@/store/public';

import styles from './styles.module.scss';

import CheckIcon from '@/assets/icons/check.svg?react';

export const PricingBlockBenefits = () => {
  const user = useAppSelector(selectUser);

  return (
    <div className={styles.benefits}>
      <p id="benefits_title" className={styles.benefitsTitle}>
        Premium Benefits
      </p>
      <ul id="benefits_list" className={styles.benefitList}>
        {getPremiumBenefits(user).map((benefit) => (
          <li key={benefit} className={styles.benefitItem}>
            <CheckIcon /> {benefit}
          </li>
        ))}
      </ul>
    </div>
  );
};
