import month60ImageSafe from '@/assets/images/pricing/monthly/60off-sf.png';
import month60Image from '@/assets/images/pricing/monthly/60off.png';
import month70ImageSafe from '@/assets/images/pricing/monthly/70off-sf.png';
import month70Image from '@/assets/images/pricing/monthly/70off.png';
import month80ImageSafe from '@/assets/images/pricing/monthly/80off-sf.png';
import month80Image from '@/assets/images/pricing/monthly/80off.png';
import quarterly65ImageSafe from '@/assets/images/pricing/quarterly/65off-sf.png';
import quarterly65Image from '@/assets/images/pricing/quarterly/65off.png';
import quarterly70ImageSafe from '@/assets/images/pricing/quarterly/70off-sf.png';
import quarterly70Image from '@/assets/images/pricing/quarterly/70off.png';
import quarterly80ImageSafe from '@/assets/images/pricing/quarterly/80off-sf.png';
import quarterly80Image from '@/assets/images/pricing/quarterly/80off.png';
import yearly75ImageSafe from '@/assets/images/pricing/yearly/75off-sf.png';
import yearly75Image from '@/assets/images/pricing/yearly/75off.png';
import yearly80ImageSafe from '@/assets/images/pricing/yearly/80off-sf.png';
import yearly80Image from '@/assets/images/pricing/yearly/80off.png';

export const getDownsellData = (isNSFWContent: boolean) => ({
  1: {
    images: isNSFWContent
      ? [
          month60Image,
          month70Image,
          month80Image,
          month60Image,
          month70Image,
          month80Image,
          month60Image,
        ]
      : [
          month60ImageSafe,
          month70ImageSafe,
          month80ImageSafe,
          month60ImageSafe,
          month70ImageSafe,
          month80ImageSafe,
          month60ImageSafe,
        ],
    discountPercent: 30,
  },
  3: {
    images: isNSFWContent
      ? [
          quarterly65Image,
          quarterly70Image,
          quarterly80Image,
          quarterly65Image,
          quarterly70Image,
          quarterly80Image,
          quarterly65Image,
        ]
      : [
          quarterly65ImageSafe,
          quarterly70ImageSafe,
          quarterly80ImageSafe,
          quarterly65ImageSafe,
          quarterly70ImageSafe,
          quarterly80ImageSafe,
          quarterly65ImageSafe,
        ],
    discountPercent: 20,
  },
  12: {
    images: isNSFWContent
      ? [
          yearly75Image,
          yearly80Image,
          yearly75Image,
          yearly80Image,
          yearly75Image,
          yearly80Image,
          yearly75Image,
        ]
      : [
          yearly75ImageSafe,
          yearly80ImageSafe,
          yearly75ImageSafe,
          yearly80ImageSafe,
          yearly75ImageSafe,
          yearly80ImageSafe,
          yearly75ImageSafe,
        ],
    discountPercent: 10,
  },
});
