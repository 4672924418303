import { Dispatch, SetStateAction } from 'react';

import { SubscriptionProduct } from '@/services/paymentsServices/types.ts';
import { ActiveSubscriptionProduct } from '@/services/userServices/types.ts';

export type PricingPageProps = {
  hidden?: boolean;
  isModal?: boolean;
};

export type PricingBlockProps = {
  products: SubscriptionProduct[] | undefined;
  isLoading: boolean;
  selectedProduct: SubscriptionProduct | null;
  setSelectedProduct: Dispatch<SetStateAction<SubscriptionProduct | null>>;
  startCancellationFlow: () => void;
  paymentButtonText: PaymentButtonTextEnum;
  handleActionClick: () => void;
  isLifeTimeSubscription: boolean;
  activeSubscription: ActiveSubscriptionProduct | undefined;
  isModal?: boolean;
};

export enum PaymentButtonTextEnum {
  Continue = 'Continue',
  Upgrade = 'Claim Bonus Months & Continue',
  Downgrade = 'Downgrade',
}
