import classNames from 'classnames';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Modal } from '@/components/Modals/Modal';
import { ModalCheckout } from '@/components/Modals/ModalCheckout';
import { ModalSubscriptionDownsell } from '@/components/Modals/ModalSubscriptionDownsell';
import { TimeToDiscount } from '@/components/TimeToDiscount';
import { Toast } from '@/components/Toast';
import {
  IS_MIRROR_SVITLO_APP,
  PRICING_MODEL_ID_KEY,
  PRICING_MODEL_NAME_KEY,
  PRICING_MODEL_TYPE_KEY,
  PRICING_SOURCE_KEY,
} from '@/const';
import { PAYWALL_SHOWN_EVENT } from '@/const/dataLayerEvents.ts';
import { usePaywallModals } from '@/hooks/usePaywallModals.tsx';
import { useRenewSubscription } from '@/hooks/useRenewSubscription.tsx';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { CancellationSubscription } from '@/pages/pricing/CancellationSubscription';
import { ModalDowngradeConfirmation } from '@/pages/pricing/ModalDowngradeConfirmation';
import {
  convertSubscriptionPeriodToMonthsNumber,
  getPaymentButtonText,
} from '@/pages/pricing/helpers.ts';
import {
  useGetActiveSubscriptionQuery,
  useGetProductsAdminQuery,
  useGetProductsQuery,
  useLazyGetUserCardInfoQuery,
  useLazyGetUserCardInfoYunoQuery,
} from '@/services/paymentsServices';
import { SubscriptionProduct } from '@/services/paymentsServices/types.ts';
import {
  selectIsAuth,
  selectIsLoadingUser,
  selectIsYunoPaymentMethod,
  selectUser,
} from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';
import { ls } from '@/utils/localStorage.ts';

import { ModalUpsellTokens } from './ModalUpsellTokes';
import PricingBlock from './PricingBlock';
import { formatPeriodToText } from './PricingBlock/helpers.tsx';
import { PricingPageProps } from './types.ts';

import styles from './styles.module.scss';

const PricingPage = ({ hidden, isModal }: PricingPageProps) => {
  const { state: locationState } = useLocation();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const isAuth = useAppSelector(selectIsAuth);
  const user = useAppSelector(selectUser);
  const isLoadingUser = useAppSelector(selectIsLoadingUser);
  const isYunoPaymentMethod = useAppSelector(selectIsYunoPaymentMethod);

  const [selectedProduct, setSelectedProduct] =
    useState<SubscriptionProduct | null>(null);
  const [additionalDiscountPercent, setAdditionalDiscountPercent] = useState(0);
  const [toastUpdateSubscriptionVariant, setToastUpdateSubscriptionVariant] =
    useState<'success' | 'error' | ''>('');
  const [modalStates, setModalStates] = useState({
    isModalPaymentOpened: false,
    isModalUpsellTokensOpened: false,
    isModalDowngradeOpened: false,
    isModalDownsellOpened: false,
    isCancellationFlowStarted: false,
  });

  const { RenewComponent } = useRenewSubscription();
  const { eventsState, closePaywallModal, isTestGroup } = usePaywallModals();

  const { data: products, isLoading: isLoadingProducts } = useGetProductsQuery(
    null,
    { skip: hidden },
  );
  const { data: productsAdmin, isLoading: isLoadingProductsAdmin } =
    useGetProductsAdminQuery(null, { skip: !hidden });
  const { data: activeSubscription } = useGetActiveSubscriptionQuery(
    undefined,
    {
      skip: !isAuth || hidden,
    },
  );
  const [getUserCardInfo] = useLazyGetUserCardInfoQuery();

  const [getUserCardInfoYuno] = useLazyGetUserCardInfoYunoQuery();

  useEffect(() => {
    if (!isLoadingUser || !isAuth) return;

    isYunoPaymentMethod ? getUserCardInfoYuno() : getUserCardInfo();
  }, [isLoadingUser, isYunoPaymentMethod, isAuth]);

  const changeModalState = useCallback(
    (key: keyof typeof modalStates, value: boolean) => {
      setModalStates((prev) => ({ ...prev, [key]: value }));
    },
    [setModalStates],
  );

  const state = eventsState || locationState;

  const eventParams = {
    paywall_type: 'default_subscription',
    source: state?.source,
    persona_id: state?.modelId,
    persona_type: state?.modelType,
    persona_name: state?.modelName,
    is_pre_paywall_shown: (!isTestGroup).toString(),
  };

  useEffect(() => {
    pushToDataLayer(PAYWALL_SHOWN_EVENT, user, eventParams);

    if (state?.source) ls.set(PRICING_SOURCE_KEY, state.source);
    if (state?.modelId) ls.set(PRICING_MODEL_ID_KEY, state.modelId);
    if (state?.modelType) ls.set(PRICING_MODEL_TYPE_KEY, state.modelType);
    if (state?.modelName) ls.set(PRICING_MODEL_NAME_KEY, state.modelName);
  }, []);

  useEffect(() => {
    if (isLoadingProducts || !products) return;

    setSelectedProduct(products.find((product) => product.isPopular) || null);
  }, [isLoadingProducts]);

  useEffect(() => {
    if (orderId) {
      changeModalState('isModalPaymentOpened', true);
    }
  }, [user]);

  const handleModalPaymentClose = (shouldOpenDownsell = false) => {
    if (
      !user?.activeSubscription &&
      !additionalDiscountPercent &&
      shouldOpenDownsell
    ) {
      changeModalState('isModalDownsellOpened', true);
    }
    setAdditionalDiscountPercent(0);
    changeModalState('isModalPaymentOpened', false);
  };

  const productTitle = `${formatPeriodToText(selectedProduct)} subscription`;
  const currentProducts = hidden ? productsAdmin : products;
  const isLoading = isLoadingProducts || isLoadingProductsAdmin;

  const paymentButtonText = getPaymentButtonText(
    activeSubscription,
    selectedProduct,
  );

  const handleActionClick = useCallback(() => {
    // paymentButtonText === 'Downgrade'
    //   ? setIsModalDowngradeOpened(true)
    //   : setIsModalPaymentOpened(true);
    changeModalState('isModalPaymentOpened', true);
  }, [paymentButtonText]);

  const subscriptionPeriodInMonths = convertSubscriptionPeriodToMonthsNumber(
    activeSubscription?.periodType,
    activeSubscription?.periodValue,
  );

  const startCancellationFlow = useCallback(() => {
    changeModalState('isCancellationFlowStarted', true);
  }, []);

  const isLifeTimeSubscription = useMemo(
    () => !!currentProducts?.some((product) => product.isLifetimeSubscription),
    [currentProducts],
  );

  const {
    isModalPaymentOpened,
    isModalUpsellTokensOpened,
    isModalDowngradeOpened,
    isModalDownsellOpened,
    isCancellationFlowStarted,
  } = modalStates;

  const pricingContent = (
    <>
      <div
        className={classNames(styles.priceContainer, isModal && styles.modal)}
      >
        <PricingBlock
          products={currentProducts}
          isLoading={isLoading}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          paymentButtonText={paymentButtonText}
          startCancellationFlow={startCancellationFlow}
          handleActionClick={handleActionClick}
          isLifeTimeSubscription={isLifeTimeSubscription}
          activeSubscription={activeSubscription}
          isModal={isModal}
        />
      </div>

      {isModalPaymentOpened && (
        <ModalCheckout
          onClose={handleModalPaymentClose}
          productTitle={productTitle}
          selectedProduct={selectedProduct}
          handlePaymentSuccess={() =>
            changeModalState('isModalUpsellTokensOpened', true)
          }
          paywallType={eventParams.paywall_type}
          additionalDiscountPercent={additionalDiscountPercent}
        />
      )}

      {isCancellationFlowStarted && (
        <CancellationSubscription
          onClose={() => changeModalState('isCancellationFlowStarted', false)}
        />
      )}

      {isModalUpsellTokensOpened && (
        <ModalUpsellTokens
          closeUpsellFlow={() =>
            changeModalState('isModalUpsellTokensOpened', false)
          }
        />
      )}

      {isModalDownsellOpened && selectedProduct && (
        <ModalSubscriptionDownsell
          selectedProduct={selectedProduct}
          handleClose={() => changeModalState('isModalDownsellOpened', false)}
          handleAction={(discountPercent) => {
            setAdditionalDiscountPercent(discountPercent);
            changeModalState('isModalDownsellOpened', false);
            changeModalState('isModalPaymentOpened', true);
          }}
        />
      )}

      {isModalDowngradeOpened && selectedProduct && (
        <ModalDowngradeConfirmation
          productId={selectedProduct.id}
          handleClose={() => changeModalState('isModalDowngradeOpened', false)}
          onSuccess={(res) => {
            setToastUpdateSubscriptionVariant(res);
            setSelectedProduct(null);
          }}
        />
      )}

      {!!toastUpdateSubscriptionVariant && activeSubscription && (
        <Toast
          open
          onClose={() => setToastUpdateSubscriptionVariant('')}
          message={
            toastUpdateSubscriptionVariant === 'success'
              ? `Your subscription has been downgraded to ${subscriptionPeriodInMonths} month${subscriptionPeriodInMonths > 1 ? 's' : ''}. This will take effect after the end of your current subscription period.`
              : "Something went wrong. We couldn't complete your subscription change. Please refresh the page or contact support."
          }
          severity={toastUpdateSubscriptionVariant}
        />
      )}

      {RenewComponent}
    </>
  );

  if (isModal) {
    return (
      <Modal
        closeButtonStyle="absolute"
        containerClassName={styles.modalContent}
        onClose={closePaywallModal}
        maxWidth={1250}
        noPadding
      >
        {pricingContent}
      </Modal>
    );
  }

  return (
    <>
      {!isLifeTimeSubscription && !isLoading && !IS_MIRROR_SVITLO_APP && (
        <TimeToDiscount />
      )}
      <section className={styles.section}>
        <h1 id="pricing_header" className={styles.title}>
          Choose your plan
        </h1>
        <p id="pricing_description" className={styles.description}>
          100% anonymous. You can cancel anytime.
        </p>
        {pricingContent}
      </section>
    </>
  );
};

export default memo(PricingPage);
