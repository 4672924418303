import { useEffect } from 'react';

import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';
import { RadioGroup } from '@/components/RadioGroup';
import {
  SUBSCRIPTION_CANCELLATION_REASON_POPUP_CLICKED_EVENT,
  SUBSCRIPTION_CANCELLATION_REASON_POPUP_CLOSED_EVENT,
  SUBSCRIPTION_CANCELLATION_REASON_POPUP_SHOWN_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { CANCELLATION_REASON_OPTIONS } from '@/pages/pricing/CancellationSubscription/Reason/constants.ts';
import { CancellationComponentProps } from '@/pages/pricing/CancellationSubscription/types.ts';
import { useGetLifetimeProductQuery } from '@/services/paymentsServices';
import { selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';

import styles from './styles.module.scss';

export const CancellationReason = ({
  setStep,
  answers,
  handleUpdateAnswers,
  onClose,
}: CancellationComponentProps) => {
  const user = useAppSelector(selectUser);

  useGetLifetimeProductQuery();

  useEffect(() => {
    pushToDataLayer(SUBSCRIPTION_CANCELLATION_REASON_POPUP_SHOWN_EVENT, user);
  }, []);

  const onChange = (selectedOption: string) => {
    handleUpdateAnswers('reason', selectedOption);
  };

  const onAction = () => {
    switch (answers.reason) {
      case "I worry that I'll forget to cancel my subscription before the end of the introductory period":
        setStep('reminder');
        break;
      case 'The subscription is too expensive for my budget':
        setStep('offer');
        break;
      default:
        setStep('feedback');
        break;
    }
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_REASON_POPUP_CLICKED_EVENT,
      user,
      {
        cancellation_reason: answers.reason,
      },
    );
  };

  const handleClose = () => {
    pushToDataLayer(SUBSCRIPTION_CANCELLATION_REASON_POPUP_CLOSED_EVENT, user);
    onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      closeButtonStyle="absolute"
      disableAppearanceAnimation
      maxWidth={550}
    >
      <div className={styles.container}>
        <p className={styles.heading}>What is your reason for cancellation?</p>
        <RadioGroup
          options={CANCELLATION_REASON_OPTIONS}
          selectedOption={answers.reason}
          onChange={onChange}
        />

        <Button disabled={!answers.reason} onClick={onAction} fullWidth>
          Continue
        </Button>
      </div>
    </Modal>
  );
};
