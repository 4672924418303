import { Snackbar, Alert } from '@mui/material';
import { createPortal } from 'react-dom';

import { ToastProps } from '@/components/Toast/types.ts';
import { IS_LIGHT } from '@/const';

import styles from './styles.module.scss';

import FailIcon from '@/assets/icons/failed.svg?react';
import SuccessIcon from '@/assets/icons/success-green-check.svg?react';

const snackbarStyles = (position: 'center' | 'right') => ({
  top: '130px !important',
  left: `${position === 'center' ? '50%' : 'unset'} !important`,
  right: `${position === 'center' ? 'unset' : '24px'} !important`,
  minWidth: '300px',
  maxWidth: 'fit-content',
  width: '95%',
  transform: `${position === 'center' ? 'translateX(-50%)' : 'none'} !important`,
});

const alertSeverity = {
  success: {
    color: IS_LIGHT ? '#0aad1a' : '#74FF82',
    border: `2px solid ${IS_LIGHT ? '#0aad1a' : '#74FF82'}`,
    background: 'rgba(116, 255, 130, 0.10)',
  },
  error: {
    color: IS_LIGHT ? '#F14848' : 'white',
    border: '2px solid #F14848',
    background: IS_LIGHT ? 'rgba(241, 72, 72, 0.10)' : 'rgba(12, 12, 12, 0.70)',
  },
};

const alertStyles = {
  width: '100%',
  borderRadius: '12px',
  backdropFilter: 'blur(5px)',
  textAlign: 'center',
  fontSize: '16px',
};

export const Toast = ({
  open,
  message,
  onClose,
  position = 'center',
  severity = 'error',
  autoHideDuration = 6000,
  sx,
  id,
}: ToastProps) => {
  return createPortal(
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      sx={{
        ...snackbarStyles(position),
        ...sx,
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        icon={false}
        sx={{ ...alertStyles, ...alertSeverity[severity] }}
      >
        <p className={styles.message} id={id}>
          {severity === 'success' ? <SuccessIcon /> : <FailIcon />}
          {message}
        </p>
      </Alert>
    </Snackbar>,
    document.body,
  );
};
