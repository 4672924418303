import classNames from 'classnames';

import { Checkbox } from '@/components/Checkbox';
import { toSnakeCase } from '@/utils/toSnakeCase.ts';

import { CheckboxGroupProps } from './types.ts';

import styles from './styles.module.scss';

export const CheckboxGroup = <T extends string>({
  options,
  selectedOptions,
  onChange,
  maxLimit,
  className,
  labelClassName,
}: CheckboxGroupProps<T>) => {
  const handleChange = (value: T) => {
    const updatedSelection = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];
    onChange(updatedSelection);
  };

  const isMaximumReached = maxLimit
    ? selectedOptions.length >= maxLimit
    : false;

  return (
    <ul className={classNames(styles.checkboxList, className)}>
      {options.map((option) => (
        <li key={option.value}>
          <Checkbox
            id={toSnakeCase(option.value)}
            label={option.label}
            checked={selectedOptions.includes(option.value)}
            onChange={() => handleChange(option.value)}
            labelClassName={classNames(
              labelClassName,
              styles.checkboxItem,
              selectedOptions.includes(option.value) && styles.selected,
              isMaximumReached &&
                !selectedOptions.includes(option.value) &&
                styles.disabled,
            )}
            disabled={
              isMaximumReached && !selectedOptions.includes(option.value)
            }
          />
        </li>
      ))}
    </ul>
  );
};
