import { lazy } from 'react';

import { LazyLoad } from '@/components/LazyLoad';

export const MainPage = LazyLoad(lazy(() => import('../pages/main')));
export const SettingsPage = LazyLoad(lazy(() => import('../pages/settings')));
export const Chat = LazyLoad(lazy(() => import('../pages/chat')));
export const LegalPage = LazyLoad(lazy(() => import('../pages/legal')));
export const PrivacyPolicyPage = LazyLoad(
  lazy(() => import('../pages/legal/PrivacyPolicy')),
);
export const TermsOfUsePage = LazyLoad(
  lazy(() => import('../pages/legal/TermsOfUse')),
);
export const CookiesPolicyPage = LazyLoad(
  lazy(() => import('../pages/legal/CookiesPolicy')),
);
export const UnderagePolicyPage = LazyLoad(
  lazy(() => import('../pages/legal/UnderagePolicy')),
);
export const ContentRemovalPolicyPage = LazyLoad(
  lazy(() => import('../pages/legal/ContentRemovalPolicy')),
);
export const BlockedContentPolicyPage = LazyLoad(
  lazy(() => import('../pages/legal/BlockedContentPolicy')),
);
export const DMCAPolicyPage = LazyLoad(
  lazy(() => import('../pages/legal/DMCAPolicy')),
);
export const ComplaintPolicyPage = LazyLoad(
  lazy(() => import('../pages/legal/ComplaintPolicy')),
);
export const ComplaintManagementPolicyPage = LazyLoad(
  lazy(() => import('../pages/legal/ComplaintManagementPolicy')),
);
export const ExemptionPage = LazyLoad(
  lazy(() => import('../pages/legal/Exemption')),
);
export const AntiSlaveryPolicyPage = LazyLoad(
  lazy(() => import('../pages/legal/AntiSlaveryPolicy')),
);
export const ChargebackFraudMitigationPolicyPage = LazyLoad(
  lazy(() => import('../pages/legal/ChargebackFraudMitigationPolicy')),
);
export const CreateCharacter = LazyLoad(
  lazy(() => import('../pages/createCharacter')),
);
export const PricingPage = LazyLoad(lazy(() => import('../pages/pricing')));
export const MyAIPage = LazyLoad(lazy(() => import('../pages/myAI')));
export const ContactUsPage = LazyLoad(lazy(() => import('../pages/contactUs')));
export const BuyTokensPage = LazyLoad(lazy(() => import('../pages/buyTokens')));
export const HiddenAuth = LazyLoad(lazy(() => import('../pages/hiddenAuth')));
// export const Quiz = LazyLoad(lazy(() => import('../pages/quiz')));
// export const AffiliatePage = LazyLoad(lazy(() => import('../pages/affiliate')));
// export const GalleryPage = LazyLoad(lazy(() => import('../pages/gallery')));
// export const GenerateImagePage = LazyLoad(
//   lazy(() => import('../pages/generateImage')),
// );
