import { getCommonPaymentParams } from '@/components/Modals/ModalCheckout/helpers.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import {
  useCreatePaymentIntentMutation,
  useCreateResignIntentMutation,
  useUpdateSubscriptionMutation,
} from '@/services/paymentsServices';
import { selectIsAuth, selectUser } from '@/store/public';

type UsePaymentIntentProps = {
  initializeConnection: (queryParams?: string | undefined) => void;
  initializeRegister: (queryParams?: string | undefined) => void;
  isTokensProduct: boolean;
  productId?: string;
  additionalDiscountPercent: number | undefined;
};

export const usePaymentIntent = ({
  initializeConnection,
  initializeRegister,
  isTokensProduct,
  productId = '',
  additionalDiscountPercent,
}: UsePaymentIntentProps) => {
  const isAuth = useAppSelector(selectIsAuth);
  const user = useAppSelector(selectUser);

  const [createPaymentIntent, { data: paymentData, reset: resetPaymentData }] =
    useCreatePaymentIntentMutation();
  const [updateSubscription, { data: updateData, reset: resetUpdateData }] =
    useUpdateSubscriptionMutation();
  const [
    createResignPaymentIntent,
    { data: resignPaymentData, reset: resetResignData },
  ] = useCreateResignIntentMutation();

  const initializePayment = async (cardToken?: string) => {
    const isResign = !!cardToken;
    const commonParams = await getCommonPaymentParams(
      productId,
      isTokensProduct,
      !!additionalDiscountPercent,
    );
    const { data, error } = isResign
      ? await createResignPaymentIntent({
          userCardInfoId: cardToken,
          ...commonParams,
        })
      : user?.activeSubscription && !isTokensProduct
        ? await updateSubscription(commonParams)
        : await createPaymentIntent(commonParams);

    if (!error) {
      const queryParam = data?.createdUserId
        ? `?userId=${data?.createdUserId}`
        : '';
      initializeConnection(queryParam);
      !isAuth && initializeRegister(queryParam);
    }
  };

  const currentPaymentData =
    user?.activeSubscription && !isTokensProduct ? updateData : paymentData;
  const currentResetPaymentData =
    user?.activeSubscription && !isTokensProduct
      ? resetUpdateData
      : resetPaymentData;

  return {
    initializePayment,
    paymentData: currentPaymentData,
    resetPaymentData: currentResetPaymentData,
    resignPaymentData,
    resetResignData,
  };
};
