import '@styles/index.scss';
import { useEffect } from 'react';

import { APP_TITLE } from '@/const';

import { AppRouter } from './routes';

function App() {
  useEffect(() => {
    const handleViteError = () => {
      window.location.reload();
    };

    window.addEventListener('vite:preloadError', handleViteError);

    document.title = APP_TITLE;

    return () => {
      window.removeEventListener('vite:preloadError', handleViteError);
    };
  }, []);

  // useEffect(() => {
  //   if (!IS_MIRROR_SVITLO_APP) return;
  //
  //   const script = document.createElement('script');
  //   script.src = 'https://sdk-web.y.uno/v1/static/js/main.min.js';
  //   script.async = true;
  //   document.body.appendChild(script);
  //
  //   return () => {
  //     if (script) document.body.removeChild(script);
  //   };
  // }, []);

  return <AppRouter />;
}

export default App;
