import { useEffect, useState } from 'react';

import { Modal } from '@/components/Modals/Modal';
import { ModalCancelConfirmationWithImage } from '@/components/Modals/ModalCancelConfirmationWithImage';
import { useContentType } from '@/hooks/useContentType.tsx';
import { convertSubscriptionPeriodToMonthsNumber } from '@/pages/pricing/helpers.ts';

import { Button } from '../../Button';
import { getDownsellData } from './constants.ts';
import { ModalSubscriptionDiscountProps } from './types.ts';

import styles from './styles.module.scss';

import confirmationImageSafe from '@/assets/images/pricing/confirmation-sf.webp';
import confirmationImage from '@/assets/images/pricing/confirmation.webp';

export const ModalSubscriptionDownsell = ({
  selectedProduct,
  handleClose,
  handleAction,
}: ModalSubscriptionDiscountProps) => {
  const { isNSFWContent } = useContentType();

  const [seconds, setSeconds] = useState(59);
  const [isModalConfirmationOpened, setIsModalConfirmationOpened] =
    useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 0) {
          clearTimeout(timeout);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [seconds]);

  const periodInMonths = convertSubscriptionPeriodToMonthsNumber(
    selectedProduct?.periodType,
    selectedProduct?.periodValue,
  );

  const data = getDownsellData(isNSFWContent)[periodInMonths as 1 | 3 | 12];
  if (!data) handleClose();

  const onClose = () => setIsModalConfirmationOpened(true);
  const openConfirmationModal = () => setIsModalConfirmationOpened(true);

  return (
    <>
      <Modal
        closeButtonStyle="hidden"
        onClose={onClose}
        disableBackdropClick
        disableEscapeKeyDown
        disableAppearanceAnimation
        containerClassName={styles.modalContainer}
        noPadding
      >
        <div className={styles.imagesContainer}>
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className={styles.imagesAnimationBlock}
              style={{
                animationDelay: `${index * 0.2}s`,
                left: `${index * 33.3}%`,
              }}
            >
              {data.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt="Girl with discount suggestion"
                />
              ))}
            </div>
          ))}
        </div>

        <div className={styles.header}>
          <p className={styles.badge}>One-time offer...😔</p>
          <div className={styles.timer}>
            <p className={styles.timerCountdown}>{seconds}</p>
            <p className={styles.timerText}>Sec</p>
          </div>
        </div>
        <div className={styles.content}>
          <p className={styles.title}>You’re lucky today!</p>
          <p className={styles.description}>
            Give it a try at an exclusive <span>80% OFF</span>, if you unlock
            your chat in the next 60 seconds!
          </p>
          <p className={styles.disclaimer}>
            It is a non-commitment offer that you{' '}
            <span>could cancel at any time</span>.
          </p>

          <div className={styles.actionButtons}>
            <Button
              variant="disabled"
              onClick={openConfirmationModal}
              fullWidth
            >
              Skip Discount
            </Button>

            <Button
              onClick={() => handleAction(data.discountPercent)}
              fullWidth
            >
              Apply Discount
            </Button>
          </div>
        </div>
      </Modal>

      {isModalConfirmationOpened && (
        <ModalCancelConfirmationWithImage
          handleAction={handleClose}
          handleClose={() => handleAction(data.discountPercent)}
          badgeText="Don't leave me Baby...😔"
          title="Are you sure? It’s one-time offer."
          imageUrl={isNSFWContent ? confirmationImage : confirmationImageSafe}
          description="This is a unique offer that is only available now! If you refuse it, you will no longer be able to receive it!"
          actionButtonText="Yes, lose access"
          cancelButtonText="No, go back"
        />
      )}
    </>
  );
};
