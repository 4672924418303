import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store';

export type PaywallState = {
  isModalPrePaywallOpened: boolean;
  isModalPaywallOpened: boolean;
  eventsState: Record<string, string>;
};

const initialState: PaywallState = {
  isModalPrePaywallOpened: false,
  isModalPaywallOpened: false,
  eventsState: {},
};

const index = createSlice({
  name: 'paywall',
  initialState,
  reducers: {
    openModalPrePaywallReducer(state) {
      state.isModalPrePaywallOpened = true;
    },
    closeModalPrePaywallReducer(state) {
      state.isModalPrePaywallOpened = false;
    },
    openModalPaywallReducer(state) {
      state.isModalPaywallOpened = true;
    },
    closeModalPaywallReducer(state) {
      state.isModalPaywallOpened = false;
    },

    setEventsStateReducer(
      state,
      action: PayloadAction<Record<string, string>>,
    ) {
      state.eventsState = action.payload;
    },
  },
});

export const selectIsModalPrePaywallOpened = (state: RootState) =>
  state.paywall.isModalPrePaywallOpened;
export const selectIsModalPaywallOpened = (state: RootState) =>
  state.paywall.isModalPaywallOpened;
export const selectEventsState = (state: RootState) =>
  state.paywall.eventsState;

export const {
  openModalPrePaywallReducer,
  closeModalPrePaywallReducer,
  openModalPaywallReducer,
  closeModalPaywallReducer,
  setEventsStateReducer,
} = index.actions;
export default index.reducer;
