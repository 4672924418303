import { YUNO_PAYMENT_STYLES } from '@/components/Modals/ModalCheckout/YunoPayment/YunoPaymentForm/constants.ts';
import { YunoPaymentErrors } from '@/components/Modals/ModalCheckout/YunoPayment/YunoPaymentForm/types.ts';

export const YUNO_START_CHECKOUT_PARAMS = {
  countryCode: 'US',
  language: 'en',
  elementSelector: '#yuno-payment-form-root',
  showLoading: false,
  renderMode: {
    type: 'element',
    elementSelector: {
      apmForm: '#yuno-payment-form-root',
      actionForm: '#yuno-payment-form',
    },
  },
  card: {
    type: 'extends',
    styles: YUNO_PAYMENT_STYLES,
    texts: {
      cardForm: {
        enrollmentSubmitButton: 'Continue',
        paymentSubmitButton: 'Continue',
      },
    },
  },
} as const;

export const YUNO_ERRORS: Record<
  YunoPaymentErrors,
  { title: string; description: string }
> = {
  [YunoPaymentErrors.INSUFFICIENT_FUNDS]: {
    title: 'Insufficient Funds',
    description:
      'Oops! It seems like your account doesn’t have enough funds for this transaction.\n' +
      'Please check your balance or try using a different payment method.',
  },
  [YunoPaymentErrors.DECLINED_BY_BANK]: {
    title: 'Payment Declined by Bank',
    description:
      'Oh no! Your bank declined the transaction.\n' +
      'You might want to check with them to see what went wrong, or try another card.',
  },
  [YunoPaymentErrors.DO_NOT_HONOR]: {
    title: 'Transaction Not Authorized',
    description:
      'Unfortunately, your bank didn’t approve the transaction.\n' +
      'Please reach out to them for more information, or use a different card.',
  },
  [YunoPaymentErrors.INVALID_SECURITY_CODE]: {
    title: 'Invalid Security Code',
    description:
      'Hmm, it looks like the security code you entered isn’t correct.\n' +
      'Double-check the number on the back of your card and try again.',
  },
  [YunoPaymentErrors.INVALID_CARD_DATA]: {
    title: 'Invalid Card Information',
    description:
      'Oops! There seems to be an issue with the card details you entered.\n' +
      'Please double-check and try again, or use a different card.',
  },
  [YunoPaymentErrors.REPORTED_STOLEN]: {
    title: 'Card Reported Stolen',
    description:
      "It seems that the card you're trying to use has been reported stolen.\n" +
      'Please contact your bank for assistance, or use a different card.',
  },
  [YunoPaymentErrors.ERROR]: {
    title: 'Payment declined',
    description:
      "Oh no! The card issuer couldn't process your payment.\n" +
      'Please try again or use a different card.',
  },
};
