import { SpinnerProps } from '@/components/Spinner/types.ts';

import styles from './styles.module.scss';

export const Spinner = ({ size = 19 }: SpinnerProps) => {
  return (
    <div
      className={styles.spinner}
      style={{
        width: size,
        height: size,
      }}
    />
  );
};
