const IFRAME_CONTAINER_ID = `payment-form-container_#123`;
const GOOGLE_PAY_BUTTON_CONTAINER_ID = `google-pay-button-container_#123`;
const APPLE_PAY_BUTTON_CONTAINER_ID = `apple-pay-button-container_#123`;
const PAYPAL_BUTTON_CONTAINER_ID = `paypal-button-container_#123`;

export {
  IFRAME_CONTAINER_ID,
  GOOGLE_PAY_BUTTON_CONTAINER_ID,
  APPLE_PAY_BUTTON_CONTAINER_ID,
  PAYPAL_BUTTON_CONTAINER_ID,
};
