import useMediaQuery from '@mui/material/useMediaQuery';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { memo, useEffect } from 'react';

import { Button } from '@/components/Button';
import {
  PRE_PAYWALL_BUTTON_CLICKED_EVENT,
  PRE_PAYWALL_POPUP_SHOWN_EVENT,
} from '@/const/dataLayerEvents.ts';
import { MediaQueries } from '@/const/enum.ts';
import { FingerprintData } from '@/hooks/useFingerprint.tsx';
import { usePaywallModals } from '@/hooks/usePaywallModals.tsx';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { useSendPropellerEventMutation } from '@/services/userServices';
import { selectUser } from '@/store/public';
import { getFingerprintHash } from '@/utils/getFingerprintHash.ts';
import { pushToDataLayer } from '@/utils/gtm.ts';
import { setDefaultImgOnError } from '@/utils/setDefaultImgOnError.ts';

import { Modal } from '../Modal';
import { getDefaultPremiumFeatures } from './constants.tsx';

import styles from './styles.module.scss';

import CheckIcon from '@/assets/icons/check.svg?react';
import CrownIcon from '@/assets/icons/crown.svg?react';

const ModalPrePaywall = () => {
  const isTableView = useMediaQuery(MediaQueries.TABLET);

  const user = useAppSelector(selectUser);
  const options = getDefaultPremiumFeatures(user, isTableView);

  const [sendPropellerEvent] = useSendPropellerEventMutation();

  const {
    eventsState,
    prePaywallImage,
    openPaywallModal,
    closePrePaywallModal,
  } = usePaywallModals();

  const analyticsProps = {
    ...(eventsState?.source && { source: eventsState.source }),
    ...(eventsState?.modelId && { persona_id: eventsState?.modelId }),
    ...(eventsState?.modelType && { persona_type: eventsState.modelType }),
    ...(eventsState?.modelName && { persona_name: eventsState.modelName }),
  };

  useEffect(() => {
    pushToDataLayer(PRE_PAYWALL_POPUP_SHOWN_EVENT, user, analyticsProps);

    const sendPropellerDataEvent = async () => {
      const fingerprintData = (await getFingerprint(true)) as FingerprintData;
      const fingerprintHash = getFingerprintHash(fingerprintData);
      sendPropellerEvent(fingerprintHash);
    };

    sendPropellerDataEvent();
  }, []);

  const handleAction = () => {
    pushToDataLayer(PRE_PAYWALL_BUTTON_CLICKED_EVENT, user, analyticsProps);
    openPaywallModal();
  };

  return (
    <Modal
      onClose={closePrePaywallModal}
      closeButtonStyle="withBackground"
      containerClassName={styles.modalContainer}
    >
      <div className={styles.container}>
        <img
          src={prePaywallImage}
          alt="Premium Girl"
          loading="lazy"
          height={568}
          className={styles.premiumGirl}
          onError={setDefaultImgOnError}
        />
        <div className={styles.content}>
          <h1 className={styles.title}>
            Unlock <span>Premium</span> Benefits!
          </h1>
          <div className={styles.subscription}>
            Upgrade to our Premium Membership and enjoy:
          </div>
          <ul className={styles.subscriptionList}>
            {options.map((option) => (
              <li key={option} className={styles.subscriptionItem}>
                <CheckIcon /> {option}
              </li>
            ))}
          </ul>
          <Button
            id="be_premium_btn"
            className={styles.actionButton}
            onClick={handleAction}
          >
            <CrownIcon />
            <span>Upgrade to Premium</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalPrePaywall);
