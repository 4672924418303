import { Fragment, useState } from 'react';

import { ConfirmationModal } from '@/components/ConfirmationModal';
import { CARD_ICON } from '@/components/Modals/ModalCheckout/components/CardSelection/constants.ts';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { useDeleteUserCardInfoMutation } from '@/services/paymentsServices';
import { removeUserCard, selectUserCards } from '@/store/public';

import { formatCardNumber } from './helpers.ts';
import { PaymentCardSelectionProps } from './types.ts';

import styles from './styles.module.scss';

import PlusIcon from '@/assets/icons/plus-small.svg?react';
import DeleteIcon from '@/assets/icons/trash.svg?react';

export const PaymentCardSelection = ({
  initializePayment,
  setCurrentStep,
  isYunoPayment,
  setFlow,
}: PaymentCardSelectionProps) => {
  const dispatch = useAppDispatch();

  const userCards = useAppSelector(selectUserCards);

  const [deleteCardId, setDeleteCardId] = useState('');

  const [deleteUserCardInfo, { isLoading: isLoadingDelete }] =
    useDeleteUserCardInfoMutation();

  const handleCardClick = (cardId: string) => {
    setFlow?.('resign');
    initializePayment(cardId);
    setCurrentStep(isYunoPayment ? 'paymentProcessing' : 'loading');
  };

  const handleDeleteCard = async () => {
    const { error } = await deleteUserCardInfo(deleteCardId);
    if (!error) {
      dispatch(removeUserCard(deleteCardId));
    }
  };

  return (
    <div className={styles.cardSelectContainer}>
      <div className={styles.cardList}>
        {userCards?.length ? (
          userCards.map((card) => {
            const CardIcon =
              CARD_ICON[card.brand.toLowerCase() as keyof typeof CARD_ICON];

            return (
              <Fragment key={card.id}>
                <button
                  key={card.id}
                  className={styles.card}
                  onClick={() => handleCardClick(card.id)}
                >
                  <div className={styles.cardNumberBlock}>
                    <div className={styles.cardNumber}>
                      <p>Card number</p>
                      <span>{formatCardNumber(card.cardNumber)}</span>
                    </div>
                    {CardIcon && <CardIcon />}
                  </div>

                  <div className={styles.cardInfo}>
                    <div className={styles.cardType}>
                      <p>Card type</p>
                      <span>{card.cardType}</span>
                    </div>
                    {card.cardExpirationMonth && (
                      <div className={styles.expiry}>
                        <p>Expiry</p>
                        <span>
                          {card.cardExpirationMonth}/
                          {card.cardExpirationYear.toString().slice(-2)}
                        </span>
                      </div>
                    )}

                    <div
                      role="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteCardId(card.id);
                      }}
                      className={styles.deleteButton}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                </button>

                {deleteCardId === card.id && (
                  <ConfirmationModal
                    onClose={() => setDeleteCardId('')}
                    onAction={handleDeleteCard}
                    isLoading={isLoadingDelete}
                    title="Are you sure you want to delete this card?"
                    description="This action cannot be undone, please be careful!"
                    actionButtonLabel="Delete"
                  />
                )}
              </Fragment>
            );
          })
        ) : (
          <p className={styles.noCardsText}>No cards added</p>
        )}
      </div>
      <button
        onClick={() => {
          setFlow?.('newCard');
          setCurrentStep('loading');
          initializePayment();
        }}
        className={styles.addCardButton}
      >
        <PlusIcon />
        Pay with another card
      </button>
    </div>
  );
};
