import { IS_MIRROR_SVITLO_APP } from '@/const';
import { SUBSCRIPTION_MANAGE_BUTTON_CLICKED_EVENT } from '@/const/dataLayerEvents.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { RenewSubscription } from '@/pages/settings/components/RenewSubscription';
import { selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';

import { PricingBlockCancelSubscriptionProps } from './types.tsx';

import styles from './styles.module.scss';

export const PricingBlockCancelSubscription = ({
  startCancellationFlow,
}: PricingBlockCancelSubscriptionProps) => {
  const user = useAppSelector(selectUser);

  const handleCancelClick = () => {
    pushToDataLayer(SUBSCRIPTION_MANAGE_BUTTON_CLICKED_EVENT, user);
    startCancellationFlow();
  };

  const isSubscriptionCancelled = Boolean(
    user?.activeSubscription?.cancellation_requested_at,
  );

  if (isSubscriptionCancelled) {
    return (
      <RenewSubscription
        buttonLabel="Renew your subscription"
        className={styles.renewButton}
      />
    );
  }

  return (
    <button
      id="pricing_cancel_subsc"
      disabled={IS_MIRROR_SVITLO_APP}
      onClick={handleCancelClick}
      className={styles.cancelSubscriptionButton}
    >
      Cancel Subscription
    </button>
  );
};
