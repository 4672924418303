import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';

import { RouteGuard } from '@/components/RouteGuard';
import { MediaQueries } from '@/const/enum.ts';
import { SIDEBAR_LINKS } from '@/layout/Sidebar/constants.ts';

import styles from './styles.module.scss';

export const SidebarSvitlo = () => {
  const isMobile = useMediaQuery(MediaQueries.TABLET);

  return (
    <nav>
      <ul className={styles.linkList}>
        {SIDEBAR_LINKS(isMobile).map((link) => {
          if (link.disabled) return null;

          return (
            <li key={link.route}>
              <RouteGuard
                id={link.id}
                variant="navlink"
                route={link.route}
                className={classNames(styles.link, link.className)}
                activeClassName={styles.active}
                skipAuthCheck={!link.guard}
                needSubscription={link.needSubscription}
                state={link.state}
              >
                <span className={styles.label}>{link.label}</span>
              </RouteGuard>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
