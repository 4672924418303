import classNames from 'classnames';

import { RadioButton } from '@/components/RadioButton';
import { toSnakeCase } from '@/utils/toSnakeCase.ts';

import { RadioGroupProps } from './types.ts';

import styles from './styles.module.scss';

import CrownIcon from '@/assets/icons/crown.svg?react';

export const RadioGroup = <T extends string>({
  options,
  selectedOption,
  onChange,
  className,
  labelClassName,
}: RadioGroupProps<T>) => {
  return (
    <ul className={classNames(styles.list, className)}>
      {options.map((option) => (
        <li key={option.value}>
          <RadioButton
            id={toSnakeCase(option.value)}
            checked={selectedOption === option.value}
            onChange={() => onChange(option.value)}
            labelClassName={classNames(
              styles.label,
              selectedOption === option.value && styles.selected,
              labelClassName,
            )}
            isPremium={option.isPremium}
          >
            <span className={styles.labelText}>
              {option.label}{' '}
              {option.isPremium && (
                <span className={styles.premiumBadge}>
                  <CrownIcon />
                  Only for Premium
                </span>
              )}
            </span>
          </RadioButton>
        </li>
      ))}
    </ul>
  );
};
