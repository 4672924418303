import { UserType } from '@/services/userServices/types.ts';
import { getIsNSFWContent, getIsNSFWUser } from '@/utils/getIsNSFWUser.ts';

import ApplePayIcon from '@/assets/icons/pricing/Applepay.svg?react';
import GpayIcon from '@/assets/icons/pricing/Gpay.svg?react';
import MaestroIcon from '@/assets/icons/pricing/Maestro.svg?react';
import MastercardIcon from '@/assets/icons/pricing/Mastercard.svg?react';
import VisaIcon from '@/assets/icons/pricing/Visa.svg?react';

const sfwBenefits = [
  'Generate photos',
  'Get 100 tokens every month',
  'Unlock chat photos',
  'Create your own AI Characters',
  'Fast response',
  'New features priority use',
  'Content privacy',
  'And much more!',
];

const nsfwBenefits = (isNSFWContent: boolean) => [
  'Generate photos',
  'Get 100 tokens every month',
  'Extremely Spicy Content\u00A0🌶',
  'Unlock chat photos',
  `Create your own ${isNSFWContent ? 'girlfriend' : 'AI Characters'}`,
  'Fast response',
  'New features priority use',
  'Content privacy',
  'And much more!',
];

export const getPremiumBenefits = (user: UserType | null) => {
  const isNSFWUser = getIsNSFWUser(user);
  const isNSFWContent = getIsNSFWContent(user);

  if (isNSFWUser) return nsfwBenefits(isNSFWContent);
  return sfwBenefits;
};

export const PRICING_PAYMENT_ICONS = [
  MastercardIcon,
  VisaIcon,
  MaestroIcon,
  ApplePayIcon,
  GpayIcon,
];
