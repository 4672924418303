import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from '@/services/utils/baseQueryWithReauth.ts';

import { ModelType } from './types.ts';

export const personalApi = createApi({
  reducerPath: 'personalApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['CreatedPersons'],
  endpoints: (builder) => ({
    getPersonalTop: builder.query<ModelType[], boolean>({
      query: (isNSFW) => `PersonModel/top?isEnabled=${isNSFW}`,
    }),
    getPersonalById: builder.query<ModelType, string>({
      query: (id) => `PersonModel/get-person/${id}`,
    }),
    getCreatedPersons: builder.query<ModelType[], null | void>({
      query: () => `PersonModel/get-created-persons`,
    }),
    unlockModel: builder.mutation<ModelType, string>({
      query: (modelId) => ({
        url: `PersonModel/unlock`,
        method: 'POST',
        body: { PersonModelId: modelId },
      }),
    }),
  }),
});

export const {
  useGetCreatedPersonsQuery,
  useLazyGetCreatedPersonsQuery,
  useGetPersonalByIdQuery,
  useGetPersonalTopQuery,
  useUnlockModelMutation,
} = personalApi;
