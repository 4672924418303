import ClientSdk from '../interfaces/ClientSdk';

export default class SdkLoader {
  static #CloudfrontSrc = 'https://cdn.charge-auth.com/js/form.js';

  static #loadAttempt: Promise<ClientSdk> | null = null;

  static load(): Promise<ClientSdk | null> {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      // Resolve to null when imported server side. This makes the module
      // safe to import in an isomorphic code base.
      return Promise.resolve(null);
    }

    if (window.PaymentFormSdk) {
      return Promise.resolve(window.PaymentFormSdk);
    }

    if (this.#loadAttempt) {
      return this.#loadAttempt;
    }

    this.#loadAttempt = new Promise<ClientSdk>((resolve, reject) => {
      try {
        const script = document.createElement('script');
        script.async = true;

        script.src = this.#CloudfrontSrc;
        script.id = 'payment-form-source';

        script.onerror = (e) => {
          this.#loadAttempt = null;
          reject(e);
        };

        script.onload = () => {
          resolve(window.PaymentFormSdk as ClientSdk);
        };

        document.head.appendChild(script);
      } catch (e) {
        reject(e);
      }
    });

    return this.#loadAttempt;
  }
}
