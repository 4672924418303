import { IS_LIGHT } from '@/const';
import { InitConfig, ResignFormConfig } from '@/libs/solidgate-react-sdk';

export const GOOGLE_PAY_BUTTON_PARAMS = {
  color: 'white',
  type: 'plain',
};

export const APPLE_PAY_BUTTON_PARAMS = {
  color: 'white',
  type: 'plain',
  integrationType: 'js',
};

export const PAYMENT_FORM_PARAMS = {
  submitButtonText: 'Continue',
  isCardHolderVisible: true,
  formTypeClass: 'default',
  hideCvvNumbers: true,
  emailLabel: 'E-mail',
  emailPlaceholder: 'example@gmail.com',
} as InitConfig['formParams'];

export const PAYMENT_FORM_STYLES = {
  form_body: {
    'font-family': 'Manrope, sans-serif',
  },
  input_group: {
    input: {
      'background-color': IS_LIGHT ? '#eaecf0' : '#181818',
      color: IS_LIGHT ? 'black' : 'white',
      '::placeholder': {
        color: '#808080 !important',
        'font-size': '16px',
      },
    },
    label: {
      'font-size': '16px',
      color: `${IS_LIGHT ? 'black' : 'white'} !important`,
    },
    email: {
      'margin-top': '16px',
    },
  },
  submit_button: {
    'background-color': '#ae52e7',
    'font-size': '16px',
    'font-weight': '700',
    'border-radius': '12px',
    'font-family': 'Manrope, sans-serif',
    ':hover': {
      'background-color': '#8a30c1',
    },
    span: {
      ':first-child': {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
      },
    },
  },
};

export const RESIGN_FORM_APPEARANCE: ResignFormConfig['appearance'] = {
  submitButtonText: 'Continue',
  allowSubmit: true,
  resignCvvLabel: 'CVV/CVC',
  resignCvvPlaceholder: 'CVV',
  hideCvvNumbers: true,
};

export const RESIGN_FORM_STYLES: ResignFormConfig['styles'] = {
  'resign-form': {
    padding: '0',
    'font-family': 'Manrope, sans-serif',
  },
  'resign-input-group': {
    input: {
      'background-color': IS_LIGHT ? '#eaecf0' : '#181818',
      color: IS_LIGHT ? 'black' : 'white',
      outline: 'none',
      '::placeholder': {
        color: '#808080 !important',
      },
    },
    label: {
      'font-size': '16px',
      color: `${IS_LIGHT ? 'black' : 'white'} !important`,
    },
  },

  'resign-submit-button': {
    'background-color': '#ae52e7',
    'font-size': '16px',
    'font-weight': '700',
    'border-radius': '12px',
    'font-family': 'Manrope, sans-serif',
    ':hover': {
      'background-color': '#8a30c1',
    },
    span: {
      ':first-child': {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
      },
    },
  },
};
