import { useState, useEffect } from 'react';

import styles from './styles.module.scss';

export const TimeToDiscount = () => {
  const [time, setTime] = useState({
    minutes: 15,
    seconds: 0,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime((prevTime) => {
        let { minutes, seconds } = prevTime;

        if (seconds > 0) {
          seconds -= 1;
        } else if (minutes > 0) {
          minutes -= 1;
          seconds = 59;
        }

        return { minutes, seconds };
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [time]);

  return (
    <div className={styles.container}>
      <div className={styles.textBlock}>
        <p className={styles.title}>Hurry Up!</p>
        <p className={styles.description}>Limited-Time Offer Ends&nbsp;In:</p>
      </div>
      <div className={styles.timer}>
        <div>
          <p className={styles.timeNumber}>
            {String(time.minutes).padStart(2, '0')}
          </p>
          <p className={styles.timeText}>Min</p>
        </div>
        <div>
          <p className={styles.timeNumber}>
            {String(time.seconds).padStart(2, '0')}
          </p>
          <p className={styles.timeText}>Sec</p>
        </div>
      </div>
    </div>
  );
};
