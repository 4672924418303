import { useEffect } from 'react';

import { Button } from '@/components/Button';
import { Loader } from '@/components/Loader';
import { Modal } from '@/components/Modals/Modal';
import {
  SUBSCRIPTION_CANCELLATION_LIFETIME_DOWSELL_CLAIM_EVENT,
  SUBSCRIPTION_CANCELLATION_LIFETIME_DOWSELL_CLOSED_EVENT,
  SUBSCRIPTION_CANCELLATION_LIFETIME_DOWSELL_SHOWN_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useContentType } from '@/hooks/useContentType.tsx';
import { getLifetimeOfferBenefits } from '@/pages/pricing/CancellationSubscription/Offer/constants.ts';
import { CancellationComponentProps } from '@/pages/pricing/CancellationSubscription/types.ts';
import { useGetLifetimeProductQuery } from '@/services/paymentsServices';
import { convertCentsToDollars } from '@/utils/convertCentsToDollars.ts';
import { pushToDataLayer } from '@/utils/gtm.ts';

import styles from './styles.module.scss';

import CheckIcon from '@/assets/icons/check.svg?react';
import TokenIcon from '@/assets/icons/token.svg?react';
import offerBannerSafe from '@/assets/images/pricing/offer-banner-sf.webp';
import offerBanner from '@/assets/images/pricing/offer-banner.webp';

export const CancellationOffer = ({
  setStep,
  openModalCheckout,
}: CancellationComponentProps) => {
  const { user, isNSFWContent } = useContentType();
  const { data } = useGetLifetimeProductQuery();

  useEffect(() => {
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_LIFETIME_DOWSELL_SHOWN_EVENT,
      user,
    );
  }, []);

  const onAction = () => {
    openModalCheckout();
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_LIFETIME_DOWSELL_CLAIM_EVENT,
      user,
    );
  };
  const onCancel = () => {
    setStep('offerCancelConfirmation');
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_LIFETIME_DOWSELL_CLOSED_EVENT,
      user,
    );
  };

  const product = data?.[0];
  const currentPrice = convertCentsToDollars(product?.currentPrice);
  const previousPrice = convertCentsToDollars(product?.previousPrice);
  const bannerImage = isNSFWContent ? offerBanner : offerBannerSafe;

  const renderBenefits = () => (
    <ul className={styles.benefitList}>
      {getLifetimeOfferBenefits(currentPrice).map((benefit) => (
        <li key={benefit} className={styles.benefitItem}>
          <CheckIcon />
          {benefit}
        </li>
      ))}
    </ul>
  );

  return (
    <Modal
      closeButtonStyle="withBackground"
      onClose={onCancel}
      disableAppearanceAnimation
      maxWidth={450}
      noPadding
    >
      <img src={bannerImage} alt="Premium Girl" className={styles.girlImage} />
      {product ? (
        <>
          <p className={styles.badge}>
            Unlock Lifetime Access for just ${currentPrice}!
          </p>
          <div className={styles.content}>
            <h2 className={styles.title}>Pay Once – Use Forever</h2>
            {renderBenefits()}
            <div className={styles.priceInfo}>
              <p className={styles.currentPrice}>
                Lifetime Premium <span>${currentPrice}</span>
              </p>
              <p className={styles.previousPrice}>
                No more recurring payments! <span>${previousPrice}</span>
              </p>
            </div>
            <div className={styles.tokensBadge}>
              <TokenIcon /> Get 100 Tokens every month
            </div>
            <Button onClick={onAction} fullWidth>
              Claim Lifetime Access
            </Button>
            <p className={styles.disclaimer}>
              This offer is available only now, on this screen.
            </p>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};
