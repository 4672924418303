import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectIsAuth } from '@/store/public';

const ALLOWED_PARAMS = [
  `utm_source`,
  `utm_medium`,
  `utm_campaign`,
  `utm_term`,
  `utm_content`,
  `utm_id`,
  `propeller_visitor_id`,
  `propeller_sub_source_id`,
  `aclid`,
  `clickid`,
  `gclid`,
  `TJUID`,
  `cake_cookie`,
  `tracking`,
  `campaign_id`,
  `conversion_tracking`,
  `country`,
  `site_id`,
  `scr_hostname`,
  `sub`,
  `format`,
  `zone_id`,
  `category_id`,
  `variation_id`,
  `keyword`,
  `Keywords`,
  `cost`,
  `actual_cost`,
  `pricing_model`,
  `userid`,
  `location`,
  `publisher`,
  `placement`,
  `porn_category`,
  `bid`,
  `CampaignID`,
  `CampaignName`,
  `SiteName`,
  `SpotID`,
  `AdID`,
  `AdName`,
  `BidID`,
  `dynamicS2S`,
  `dynamicCMP`,
  `dynamicGEO`,
  `dynamicPUB`,
  `dynamicSITE`,
  `dynamicZNE`,
  `dynamicIMG`,
  `dynamicIMGNAME`,
  `dynamicBID`,
];

export const usePreserveQueryParams = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isAuth = useAppSelector(selectIsAuth);

  const [initialQueryParams, setInitialQueryParams] = useState('');

  useEffect(() => {
    const utmSource = searchParams.get('utm_source');
    if (!utmSource) return;

    const filteredParams = new URLSearchParams();
    searchParams.forEach((value, key) => {
      if (ALLOWED_PARAMS.includes(key)) {
        filteredParams.set(key, value);
      }
    });

    const queryString = filteredParams.toString();
    if (queryString) {
      setInitialQueryParams(queryString);
    }
  }, []);

  const mergeQueryParams = (newParamsString: string) => {
    const mergedSearchParams = new URLSearchParams(initialQueryParams);

    const newParams = new URLSearchParams(newParamsString);
    newParams.forEach((value, key) => {
      mergedSearchParams.set(key, value);
    });

    return mergedSearchParams.toString();
  };

  useLayoutEffect(() => {
    if (isAuth) return;

    const currentPath = location.pathname;
    const currentSearch = searchParams.toString();

    let newUrl = '';

    if (currentSearch) {
      const mergedQueryString = mergeQueryParams(currentSearch);
      newUrl = `${currentPath}?${mergedQueryString}`;
    } else if (initialQueryParams) {
      newUrl = `${currentPath}?${initialQueryParams}`;
    }

    if (newUrl && location.search !== `?${newUrl}`) {
      navigate(newUrl, { replace: true });
    }
  }, [location.pathname, searchParams, navigate, isAuth, initialQueryParams]);
};
