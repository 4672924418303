import classNames from 'classnames';
import { useEffect, MouseEvent } from 'react';
import { createPortal } from 'react-dom';

import { useScroll } from '@/hooks/useScroll.tsx';

import { ModalProps } from './types.ts';

import styles from './styles.module.scss';

import CloseIcon from '@/assets/icons/close.svg?react';

export const Modal = ({
  onClose,
  children,
  closeButtonStyle = 'static',
  closeButtonClassName,
  disableBackdropClick,
  disableEscapeKeyDown,
  disableAppearanceAnimation = false,
  maxWidth,
  noPadding,
  overlayClassName,
  containerClassName,
  contentClassName,
}: ModalProps) => {
  useScroll();

  useEffect(() => {
    const isOtherModalOpened = document.body.classList.contains(
      styles.modalOpen,
    );

    if (!isOtherModalOpened) {
      document.body.classList.add(styles.modalOpen);
    }

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && !disableEscapeKeyDown) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      if (!isOtherModalOpened) document.body.classList.remove(styles.modalOpen);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [disableEscapeKeyDown, onClose]);

  const handleBackdropClick = (e: MouseEvent) => {
    if (!disableBackdropClick && e.target === e.currentTarget) {
      onClose();
    }
  };

  return createPortal(
    <div
      className={classNames(
        styles.modalOverlay,
        !disableAppearanceAnimation && styles.fadeIn,
        overlayClassName,
      )}
      onMouseDown={handleBackdropClick}
    >
      <div
        className={classNames(
          styles.modalContainer,
          !disableAppearanceAnimation && styles.fadeIn,
          noPadding && styles.noPadding,
          containerClassName,
        )}
        style={{ maxWidth }}
        onClick={(e) => e.stopPropagation()}
      >
        {closeButtonStyle !== 'hidden' && (
          <button
            id="close_modal_wind"
            className={classNames(
              styles.closeButton,
              closeButtonStyle === 'withBackground' && styles.withBackground,
              ['absolute', 'withBackground'].includes(closeButtonStyle) &&
                styles.absolute,
              closeButtonClassName,
            )}
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        )}
        <div className={classNames(styles.content, contentClassName)}>
          {children}
        </div>
      </div>
    </div>,
    document.body,
  );
};
