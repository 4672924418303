import classNames from 'classnames';

import { CheckboxProps } from '@/components/Checkbox/types.ts';

import styles from './styles.module.scss';

import CheckboxIcon from '@/assets/icons/checkbox.svg?react';

export const Checkbox = ({
  label,
  customCheckIcon = <CheckboxIcon />,
  checked,
  onChange,
  children,
  labelClassName,
  disabled,
  id,
}: CheckboxProps) => {
  return (
    <label
      id={id}
      className={classNames(
        styles.label,
        disabled && styles.disabled,
        labelClassName,
      )}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange?.(e.target.checked)}
        className={styles.input}
        disabled={disabled}
      />

      <span className={styles.customCheckbox}>
        {checked ? customCheckIcon : null}
      </span>
      {children}
      {label && <span className={styles.labelText}>{label}</span>}
    </label>
  );
};
