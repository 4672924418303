import { FC, useState } from 'react';

import { PasswordFieldProps } from './types';

import styles from './styles.module.scss';

import EyeOffIcon from '@/assets/icons/auth/eye-off.svg?react';
import EyeIcon from '@/assets/icons/auth/eye-on.svg?react';
import LockIcon from '@/assets/icons/auth/lock.svg?react';

export const PasswordField: FC<PasswordFieldProps> = ({
  register,
  name,
  placeholder,
  sx,
  inputId,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handlePasswordChange = (event: any) => {
    const { value } = event.target;
    event.target.value = value.replace(/\s/g, '');
  };

  return (
    <div className={styles.input} style={sx}>
      <LockIcon />
      <input
        id={inputId}
        className={styles.inputStyle}
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        {...register(name, {
          onChange: handlePasswordChange,
        })}
      />
      <button
        type="button"
        className={styles.eyeButton}
        onClick={togglePasswordVisibility}
      >
        {showPassword ? <EyeOffIcon /> : <EyeIcon />}
      </button>
    </div>
  );
};
