import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';

import { Button } from '@/components/Button';
import { Routes } from '@/const/enum.ts';
import { useOutsideClick } from '@/hooks/useOutsideClick.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import {
  selectIsTokensFetching,
  selectTokens,
  selectUser,
  setIsSidebarOpened,
} from '@/store/public';

import { SpentTokens } from '../SpentTokens';

import styles from './styles.module.scss';

import PlusIcon from '@/assets/icons/header/plus.svg?react';
import TokenIcon from '@/assets/icons/token.svg?react';

export const Tokens = () => {
  const dispatch = useAppDispatch();

  const tokens = useAppSelector(selectTokens);
  const isTokensFetching = useAppSelector(selectIsTokensFetching);
  const user = useAppSelector(selectUser);

  const { ref, isActive, toggleIsActive } = useOutsideClick();

  const handleLinkClick = () => {
    dispatch(setIsSidebarOpened(false));
    toggleIsActive();
  };

  return (
    <div ref={ref} className={styles.container}>
      <button
        id="token_btn"
        onClick={toggleIsActive}
        className={classNames(
          styles.buyTokensButton,
          isActive && styles.active,
        )}
      >
        <div className={styles.tokensCount}>
          <TokenIcon />
          {isTokensFetching ? (
            <Skeleton
              height={44}
              width={30}
              className={styles.tokensSkeleton}
            />
          ) : (
            tokens
          )}
        </div>
        <div className={styles.plusContainer}>
          <PlusIcon />
        </div>
        <SpentTokens />
      </button>

      <div className={classNames(styles.menu, !isActive && styles.hidden)}>
        <p className={styles.menuItem}>
          Character cost{' '}
          <span className={styles.token}>
            {user?.userPricingInfo?.tokenPriceForPersonModelCreation || 25}
            <TokenIcon />
          </span>
        </p>
        <p className={styles.menuItem}>
          Image cost{' '}
          <span className={styles.token}>
            {user?.userPricingInfo?.tokenPriceForImage || 5} <TokenIcon />
          </span>
        </p>
        <p className={styles.menuItem}>
          Message cost{' '}
          <span className={styles.token}>
            {user?.userPricingInfo?.tokenPriceForMessage || 1} <TokenIcon />
          </span>
        </p>
        <Button
          id="buy_token_btn"
          link={Routes.BUY_TOKENS}
          onClick={handleLinkClick}
          className={styles.buyMoreButton}
          state={{ source: 'header' }}
        >
          Buy more <PlusIcon />
        </Button>
      </div>
    </div>
  );
};
