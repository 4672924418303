import { useEffect } from 'react';

import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';
import { RadioGroup } from '@/components/RadioGroup';
import {
  SUBSCRIPTION_CANCELLATION_FUTURE_USAGE_POPUP_CLOSED_EVENT,
  SUBSCRIPTION_CANCELLATION_FUTURE_USAGE_POPUP_SENT_EVENT,
  SUBSCRIPTION_CANCELLATION_FUTURE_USAGE_POPUP_SHOWN_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { CancellationComponentProps } from '@/pages/pricing/CancellationSubscription/types.ts';
import { selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';

import { CANCELLATION_FUTURE_OPTIONS } from './constants.ts';

import styles from './styles.module.scss';

export const CancellationFuture = ({
  setStep,
  onClose,
  answers,
  handleUpdateAnswers,
}: CancellationComponentProps) => {
  const user = useAppSelector(selectUser);

  useEffect(() => {
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_FUTURE_USAGE_POPUP_SHOWN_EVENT,
      user,
    );
  }, []);

  const onChange = (selectedOption: string) => {
    handleUpdateAnswers('future', selectedOption);
  };

  const onAction = () => {
    setStep('offer');
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_FUTURE_USAGE_POPUP_SENT_EVENT,
      user,
      { future_usage_response: answers.future },
    );
  };
  const handleClose = () => {
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_FUTURE_USAGE_POPUP_CLOSED_EVENT,
      user,
    );
    onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      closeButtonStyle="absolute"
      disableAppearanceAnimation
      maxWidth={550}
    >
      <div className={styles.container}>
        <p className={styles.heading}>
          How likely are you to try Get Honey at the future if we take into
          account your comments?
        </p>
        <RadioGroup
          options={CANCELLATION_FUTURE_OPTIONS}
          selectedOption={answers.future}
          onChange={onChange}
        />

        <Button onClick={onAction} disabled={!answers.future} fullWidth>
          Continue
        </Button>
      </div>
    </Modal>
  );
};
