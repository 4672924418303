import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';

import { LoaderProps } from './types.ts';

import styles from './styles.module.scss';

const CustomCircularProgress = styled(CircularProgress)({
  color: 'var(--accent)',
});

export const Loader = ({ className }: LoaderProps) => {
  return (
    <div className={classNames(styles.loader, className)}>
      <CustomCircularProgress />
    </div>
  );
};
