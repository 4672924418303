import { MAX_DISCOUNT_PERCENT } from '@/const';
import { getIsCurrentPlan } from '@/pages/pricing/helpers.ts';
import { SubscriptionProduct } from '@/services/paymentsServices/types.ts';
import { ActiveSubscriptionProduct } from '@/services/userServices/types.ts';
import { convertCentsToDollars } from '@/utils/convertCentsToDollars.ts';

export const formatPeriodToText = (
  selectedProduct: ActiveSubscriptionProduct | SubscriptionProduct | null,
): string => {
  if (!selectedProduct) {
    return '';
  }

  const { periodType, periodValue } = selectedProduct;

  if (periodType === 'month') {
    return `${periodValue} ${periodValue === 1 ? 'month' : 'months'}`;
  } else if (periodType === 'year') {
    return `${periodValue * 12} months`;
  } else {
    return `${periodValue} ${periodType}`;
  }
};

export const getDisclaimerText = (
  selectedPlan: SubscriptionProduct | null,
  hasActiveSubscription: boolean,
): string => {
  if (!selectedPlan) return '';

  const { periodType, periodValue, currentPrice, previousPrice } = selectedPlan;

  const frequency =
    periodType === 'year'
      ? 'year'
      : periodType === 'month' && periodValue === 3
        ? 'quarter'
        : 'month';

  const upgradeDisclaimer =
    hasActiveSubscription && (periodType !== 'month' || periodValue !== 1)
      ? ' The selected plan will be activated immediately, and any unused amount for the current plan will be refunded at checkout.'
      : '';

  return (
    `By continuing, you agree that you will be charged an introductory payment of $${(currentPrice / 100).toFixed(2)}. ` +
    `If you do not cancel at least 24 hours prior to the end of the introductory period, ` +
    `you will automatically be charged $${(previousPrice / 100).toFixed(2)} for each subsequent ${frequency} until you cancel.` +
    upgradeDisclaimer
  );
};

export const getProductPeriod = (
  periodType: string | undefined,
  periodValue: number | undefined,
): string => {
  if (!periodValue || !periodType) {
    return 'default';
  }

  switch (periodType) {
    case 'month':
      return periodValue === 3 ? 'quarterly' : 'monthly';
    case 'year':
      return 'annually';
    default:
      return `${periodValue} ${periodType}`;
  }
};

export const formatProductDetails = (
  product: SubscriptionProduct,
  activeSubscriptionInMonths: number,
) => {
  const priceDaily = convertCentsToDollars(product.currentPriceDaily, true);
  const previousPriceDaily = convertCentsToDollars(
    product.previousPriceDaily,
    true,
  );
  const previousPriceMonthly = convertCentsToDollars(
    product.previousPriceMonthly,
    true,
  );
  const priceMonthlyInteger = Math.floor(product.currentPriceMonthly / 100);
  const priceMonthlyDecimal = Math.round(
    (product.currentPriceMonthly / 100 - priceMonthlyInteger) * 100,
  );
  const period = formatPeriodToText(product);
  const isLifetime = product.isLifetimeSubscription;
  const isCurrentPlan = getIsCurrentPlan(activeSubscriptionInMonths, product);

  return {
    priceDaily,
    previousPriceDaily,
    previousPriceMonthly,
    priceMonthlyInteger,
    priceMonthlyDecimal,
    period,
    isLifetime,
    isCurrentPlan,
  };
};

export const getPricingDiscountText = (
  hasActiveSubscription: boolean,
  isLifeTimeSubscription: boolean,
  isOneYearSubscription: boolean,
) => {
  const noSubscription = {
    title: 'Get Exclusive Discount Only Today!',
    description: (
      <>
        Up to&nbsp;<span>{MAX_DISCOUNT_PERCENT}%</span>&nbsp;off for first
        subscription
      </>
    ),
  };

  const activeSubscription = {
    title: 'Upgrade Your \n Subscription Today',
    description: 'and Get Extra Months FREE!',
  };

  const lifetimeSubscription = {
    title: 'Endless \n Honeyed Bliss',
    description: 'Limitless pleasure unlocked! \n Enjoy all benefits forever.',
  };

  if (isLifeTimeSubscription) {
    return lifetimeSubscription;
  }

  if (isOneYearSubscription || !hasActiveSubscription) {
    return noSubscription;
  }

  return activeSubscription;
};
