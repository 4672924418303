export const CANCELLATION_FEATURES_OPTIONS = [
  {
    label: 'Voiceover',
    value: 'Voiceover',
  },
  {
    label: 'Audio messages',
    value: 'Audio messages',
  },
  {
    label: 'Audio calls',
    value: 'Audio calls',
  },
  {
    label: 'Video messages',
    value: 'Video messages',
  },
  {
    label: 'Video calls',
    value: 'Video calls',
  },
];
