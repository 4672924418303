import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';
import { Spinner } from '@/components/Spinner';

import { ConfirmationModalProps } from './types.ts';

import styles from './styles.module.scss';

export const ConfirmationModal = ({
  onClose,
  onAction,
  title,
  description,
  actionButtonLabel,
  actionButtonId,
  cancelButtonId,
  isLoading,
  maxWidth,
}: ConfirmationModalProps) => {
  return (
    <Modal onClose={onClose} maxWidth={maxWidth}>
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>{title}</p>
          {description && <p className={styles.description}>{description}</p>}
          <div className={styles.buttons}>
            <Button id={cancelButtonId} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="secondary"
              id={actionButtonId}
              onClick={onAction}
              disabled={isLoading}
              className={styles.actionButton}
            >
              {isLoading ? <Spinner /> : actionButtonLabel}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
