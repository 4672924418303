import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import { MouseEventHandler, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { ModalAuthContainer } from '@/components/Modals/ModalAuthContainer';
import { MediaQueries, Routes } from '@/const/enum.ts';
import { usePaywallModals } from '@/hooks/usePaywallModals.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectIsAuth, selectUser, setIsSidebarOpened } from '@/store/public';

import { RouteGuardProps } from './types.ts';

export const RouteGuard = ({
  id,
  variant = 'link',
  children,
  route,
  className,
  activeClassName,
  disabled,
  onClick,
  onClickBeforeCheck,
  skipAuthCheck,
  needSubscription,
  state = {},
}: RouteGuardProps) => {
  const dispatch = useAppDispatch();
  const isTableView = useMediaQuery(MediaQueries.TABLET);

  const [isAuthModalOpened, setIsAuthModalOpened] = useState(false);

  const isAuth = useAppSelector(selectIsAuth);
  const user = useAppSelector(selectUser);

  const { openPrePaywallModal } = usePaywallModals();

  const checkAuth: MouseEventHandler<HTMLAnchorElement> = (e) => {
    onClickBeforeCheck?.();
    if (
      route === Routes.CHAT_PAGE &&
      window.location.pathname.startsWith(route) &&
      !isTableView
    ) {
      e.preventDefault();
      return;
    }

    if (skipAuthCheck && !disabled) {
      return;
    }

    if (disabled) {
      e.preventDefault();
      return;
    }

    if (!isAuth) {
      e.preventDefault();
      dispatch(setIsSidebarOpened(false));
      setIsAuthModalOpened(true);
      return;
    }

    if (needSubscription && !user?.activeSubscription) {
      e.preventDefault();
      dispatch(setIsSidebarOpened(false));
      openPrePaywallModal(state);
    }
  };

  const handleClick = () => {
    onClickBeforeCheck?.();

    if (!isAuth && !skipAuthCheck) {
      dispatch(setIsSidebarOpened(false));
      setIsAuthModalOpened(true);
      return;
    }

    if (needSubscription && !user?.activeSubscription) {
      dispatch(setIsSidebarOpened(false));
      openPrePaywallModal(state);
      return;
    }

    onClick?.();
    if (route) {
      window.location.href = route;
    }
  };

  const renderLink = () => {
    if (variant === 'link') {
      return (
        <Link
          to={route || '/'}
          id={id}
          onClick={checkAuth}
          className={className}
          state={state}
        >
          {children}
        </Link>
      );
    }

    if (variant === 'navlink') {
      return (
        <NavLink
          to={route || '/'}
          id={id}
          onClick={checkAuth}
          state={state}
          className={({ isActive }) =>
            isActive ? classNames(className, activeClassName) : className
          }
        >
          {children}
        </NavLink>
      );
    }

    if (variant === 'external') {
      return (
        <button
          onClick={handleClick}
          id={id}
          className={className}
          disabled={disabled}
        >
          {children}
        </button>
      );
    }

    return null;
  };

  return (
    <>
      {renderLink()}

      {isAuthModalOpened && (
        <ModalAuthContainer
          onClose={() => setIsAuthModalOpened(false)}
          source={state?.source}
        />
      )}
    </>
  );
};
