export enum ColorStatus {
  VeryWeak = '#F14848',
  Weak = '#FF7F37',
  Good = '#74FF82',
}

export const getPasswordStrength = (password: string) => {
  if (password === '') return '';

  let strength;
  switch (password.length) {
    case 1:
    case 2:
      strength = 'Very Weak';
      break;
    case 3:
    case 4:
      strength = 'Weak';
      break;
    case 5:
    case 6:
    case 7:
      strength = 'Good';
      break;
    case 8:
      strength = 'Very Strong';
      break;
    default:
      strength = 'Very Strong';
  }

  return strength;
};

export const getStatusColor = (strength: string) => {
  switch (strength) {
    case 'Very Weak':
      return ColorStatus.VeryWeak;
    case 'Weak':
      return ColorStatus.Weak;
    case 'Good':
      return ColorStatus.Good;
    case 'Very Strong':
      return ColorStatus.Good;
    default:
      return '#181818';
  }
};

export const getActiveBars = (strength: string) => {
  switch (strength) {
    case 'Very Strong':
      return 4;
    case 'Good':
      return 3;
    case 'Weak':
      return 2;
    default:
      return 1;
  }
};
