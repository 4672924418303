import { useState } from 'react';

import { ConfirmationModal } from '@/components/ConfirmationModal';
import { useFingerprint } from '@/hooks/useFingerprint.tsx';
import { useAppDispatch } from '@/hooks/useTSRedux.tsx';
import { LogoutProps } from '@/layout/Header/Profile/Logout/types.ts';
import { logout } from '@/store/public';

import styles from './styles.module.scss';

import LogoutIcon from '@/assets/icons/header/logout.svg?react';

export const Logout = ({ onAction }: LogoutProps) => {
  const dispatch = useAppDispatch();
  const { postSetFingerprint } = useFingerprint();

  const [isModalConfirmationOpened, setIsModalConfirmationOpened] =
    useState(false);

  const handleOpen = () => setIsModalConfirmationOpened(true);
  const handleClose = () => setIsModalConfirmationOpened(false);
  const handleLogout = () => {
    onAction();
    dispatch(logout());
    postSetFingerprint();
  };

  return (
    <>
      <button id="logout_btn" className={styles.menuItem} onClick={handleOpen}>
        <LogoutIcon />
        <span>Log out</span>
      </button>

      {isModalConfirmationOpened && (
        <ConfirmationModal
          title="Log out Account"
          description="Are you sure you want to log out? Logging out will end your current
          session and return you to the login screen."
          actionButtonLabel="Log out"
          onClose={handleClose}
          onAction={handleLogout}
          actionButtonId="conf_logout_btn"
          cancelButtonId="cancel_logout_btn"
        />
      )}
    </>
  );
};
