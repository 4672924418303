import { IS_MIRROR_SVITLO_APP } from '@/const';
import { Routes } from '@/const/enum.ts';
import { SidebarLinkType } from '@/layout/Sidebar/types.ts';

import styles from './styles.module.scss';

import ChatIcon from '@/assets/icons/sidebar/chat.svg?react';
import ContactUsIcon from '@/assets/icons/sidebar/contact-us.svg?react';
import CreateCharacterIcon from '@/assets/icons/sidebar/create-character.svg?react';
import ExploreIcon from '@/assets/icons/sidebar/explore.svg?react';
// import GalleryIcon from '@/assets/icons/sidebar/gallery.svg?react';
// import GenerateImageIcon from '@/assets/icons/sidebar/generate-image.svg?react';
import MyAIIcon from '@/assets/icons/sidebar/my-ai.svg?react';
import PricingIcon from '@/assets/icons/sidebar/pricing.svg?react';

export const SIDEBAR_LINKS = (isMobile = true): SidebarLinkType[] => [
  {
    route: Routes.MAIN_PAGE,
    Icon: ExploreIcon,
    label: 'Explore',
    id: 'exp_btn',
    guard: false,
  },
  {
    route: Routes.CHAT_PAGE,
    Icon: ChatIcon,
    label: 'Chat',
    id: 'chat_btn',
    guard: false,
  },
  // {
  //   route: Routes.GALLERY_PAGE,
  //   Icon: GalleryIcon,
  //   label: 'Gallery',
  //   guard: true,
  // },
  // {
  //   route: Routes.GENERATE_IMAGE_PAGE,
  //   Icon: GenerateImageIcon,
  //   label: 'Generate Image',
  //   guard: true,
  // },
  {
    route: Routes.CREATE_CHARACTER_PAGE,
    Icon: CreateCharacterIcon,
    label: 'Create Character',
    id: 'create_btn',
    guard: false,
  },
  {
    route: Routes.MY_AI_PAGE,
    Icon: MyAIIcon,
    label: 'My AI',
    id: 'ai_btn',
    guard: false,
  },
  {
    route: Routes.PRICING_PAGE,
    Icon: PricingIcon,
    label: 'Pricing',
    guard: false,
    id: 'price_btn',
    className: styles.pricing,
    state: { source: 'sidebar' },
  },
  {
    route: Routes.CONTACT_US_PAGE,
    Icon: ContactUsIcon,
    label: 'Contact Us',
    guard: false,
    id: 'contact_btn',
    className: styles.contactUs,
    state: { source: 'sidebar' },
    disabled: isMobile || !IS_MIRROR_SVITLO_APP,
  },
];
