import { Routes } from '../const/enum';
import {
  LegalPage,
  MainPage,
  PrivacyPolicyPage,
  TermsOfUsePage,
  UnderagePolicyPage,
  ComplaintPolicyPage,
  ComplaintManagementPolicyPage,
  CookiesPolicyPage,
  DMCAPolicyPage,
  BlockedContentPolicyPage,
  ContentRemovalPolicyPage,
  ExemptionPage,
  AntiSlaveryPolicyPage,
  ChargebackFraudMitigationPolicyPage,
  PricingPage,
  ContactUsPage,
  Chat,
  MyAIPage,
  CreateCharacter,
  HiddenAuth,
} from './RouteImports.tsx';

export const CommonRoutes = [
  { path: Routes.MAIN_PAGE, element: <MainPage /> },
  { path: Routes.GOOGLE_AUTH_PAGE, element: <MainPage /> },
  { path: Routes.PRICING_PAGE, element: <PricingPage /> },
  { path: Routes.CONTACT_US_PAGE, element: <ContactUsPage /> },
  { path: Routes.CHAT_PAGE, element: <Chat /> },
  { path: `${Routes.CHAT_PAGE}/:modelId`, element: <Chat /> },
  { path: Routes.MY_AI_PAGE, element: <MyAIPage /> },
  { path: Routes.CREATE_CHARACTER_PAGE, element: <CreateCharacter /> },
  // { path: Routes.AFFILIATE_PAGE, element: <AffiliatePage /> },
  { path: Routes.HIDDEN_PRICING_PAGE, element: <PricingPage hidden /> },
  { path: Routes.HIDDEN_MODAL_AUTH_PAGE, element: <HiddenAuth /> },

  // Legal pages
  { path: Routes.LEGAL_PAGE, element: <LegalPage /> },
  { path: Routes.PRIVACY_POLICY_PAGE, element: <PrivacyPolicyPage /> },
  { path: Routes.TERMS_OF_SERVICE_PAGE, element: <TermsOfUsePage /> },
  { path: Routes.COOKIES_POLICY_PAGE, element: <CookiesPolicyPage /> },
  { path: Routes.UNDERAGE_POLICY_PAGE, element: <UnderagePolicyPage /> },
  {
    path: Routes.CONTENT_REMOVAL_POLICY_PAGE,
    element: <ContentRemovalPolicyPage />,
  },
  {
    path: Routes.BLOCKED_CONTENT_POLICY_PAGE,
    element: <BlockedContentPolicyPage />,
  },
  { path: Routes.DMCA_POLICY_PAGE, element: <DMCAPolicyPage /> },
  { path: Routes.COMPLAINT_POLICY_PAGE, element: <ComplaintPolicyPage /> },
  {
    path: Routes.COMPLAINT_MANAGEMENT_POLICY_PAGE,
    element: <ComplaintManagementPolicyPage />,
  },
  { path: Routes.EXEMPTION_PAGE, element: <ExemptionPage /> },
  {
    path: Routes.ANTI_SLAVERY_POLICY_PAGE,
    element: <AntiSlaveryPolicyPage />,
  },
  {
    path: Routes.CHARGEBACK_FRAUD_MITIGATION_POLICY_PAGE,
    element: <ChargebackFraudMitigationPolicyPage />,
  },
];
