import {
  DEFAULT_PAYMENT_ERROR_TITLE,
  FREE_TOKENS_ERROR,
  FREE_TOKENS_IMAGE_TEXT,
  PAYMENT_ERROR_TITLE,
} from '@/components/Modals/ModalCheckout/SolidPayment/constants.tsx';

export const getErrorTitleByCode = (
  code: string,
  isSuttonBankError?: boolean,
) => {
  if (isSuttonBankError) {
    return 'SUTTON BANK Declined your payment! \n Use different bank card';
  }

  return PAYMENT_ERROR_TITLE[code] || DEFAULT_PAYMENT_ERROR_TITLE;
};

export const getFreeTokensErrorByCode = (code: string) => {
  return FREE_TOKENS_ERROR[code];
};

export const getFreeTokensImageTextByCode = (code: string) => {
  return FREE_TOKENS_IMAGE_TEXT[code];
};
