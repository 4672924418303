import * as yup from 'yup';

import { EMAIL_REGEX } from '@/const/enum.ts';

export const schema = yup.object().shape({
  Email: yup
    .string()
    .required('Email is required')
    .min(6, 'Email must be at least 6 characters')
    .max(50, 'Email must be max 50 characters')
    .matches(EMAIL_REGEX, 'Email is invalid'),
});
