import { NSFW_ENABLED_KEY } from '@/const';

type ObjectOrRecord<T> = T extends object
  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<string, any>
  : T extends string
    ? string
    : T;

export const ls = {
  get: <T>(key: string, defaultValue: ObjectOrRecord<T>): ObjectOrRecord<T> => {
    const storedValue = localStorage.getItem(key);
    if (!storedValue) return defaultValue;

    try {
      const parsedValue = JSON.parse(storedValue);

      if (key === NSFW_ENABLED_KEY && typeof parsedValue === 'string') {
        return JSON.parse(parsedValue);
      }

      return parsedValue;
    } catch {
      return storedValue as ObjectOrRecord<T>;
    }
  },

  set: <T>(key: string, value: T): void => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(
        `Error setting localStorage item with key "${key}":`,
        error,
      );
    }
  },

  remove: (key: string): void => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(
        `Error removing localStorage item with key "${key}":`,
        error,
      );
    }
  },
};
