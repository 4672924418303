export function convertCentsToDollars(cents?: number): number;
export function convertCentsToDollars(
  cents: number | undefined,
  fixed: true,
): string;
export function convertCentsToDollars(
  cents?: number,
  fixed: boolean = false,
): string | number {
  const amount = cents ?? 0;
  return fixed ? `${(amount / 100).toFixed(2)}` : amount / 100;
}
