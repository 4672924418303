import { PaymentButtonTextEnum } from '@/pages/pricing/types.ts';
import { SubscriptionProduct } from '@/services/paymentsServices/types.ts';
import { ActiveSubscriptionProduct } from '@/services/userServices/types.ts';

export const convertSubscriptionPeriodToMonthsNumber = (
  periodType: string | undefined,
  periodValue: number | undefined,
): number => {
  if (!periodType || !periodValue) return 0;

  return periodType === 'year' ? periodValue * 12 : periodValue;
};

export const getPaymentButtonText = (
  activeSubscription: ActiveSubscriptionProduct | undefined,
  selectedProduct: SubscriptionProduct | null,
): PaymentButtonTextEnum => {
  if (!activeSubscription || !selectedProduct)
    return PaymentButtonTextEnum.Continue;

  const activeMonths = convertSubscriptionPeriodToMonthsNumber(
    activeSubscription.periodType,
    activeSubscription.periodValue,
  );
  const selectedMonths = convertSubscriptionPeriodToMonthsNumber(
    selectedProduct.periodType,
    selectedProduct.periodValue,
  );

  if (selectedMonths < activeMonths) {
    return PaymentButtonTextEnum.Continue;
    // return 'Downgrade';
  } else if (selectedMonths > activeMonths) {
    return PaymentButtonTextEnum.Upgrade;
  } else {
    return PaymentButtonTextEnum.Continue;
  }
};

export const getIsCurrentPlan = (
  activeSubscriptionInMonths: number,
  selectedProduct: SubscriptionProduct | null,
) => {
  const selectedProductInMonths = convertSubscriptionPeriodToMonthsNumber(
    selectedProduct?.periodType,
    selectedProduct?.periodValue,
  );
  return selectedProductInMonths === activeSubscriptionInMonths;
};

export const getDownsellPrice = (product: SubscriptionProduct) => {
  if (!product) return 0;

  const period = convertSubscriptionPeriodToMonthsNumber(
    product.periodType,
    product.periodValue,
  );
  switch (period) {
    case 1:
      return 13.99;
    case 3:
      return 34.99;
    case 12:
      return 107.99;
    default:
      return 0;
  }
};
