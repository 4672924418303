import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Routes } from '@/const/enum.ts';
import { useContentType } from '@/hooks/useContentType.tsx';
import { PRICING_PAYMENT_ICONS } from '@/pages/pricing/PricingBlock/constants.ts';

import { PAYMENT_FAQ, PAYMENT_SECURE_FEATURES } from './constants.ts';
import { PaymentFormWrapperProps } from './types.ts';

import styles from './styles.module.scss';

import ExpandMoreIcon from '@/assets/icons/pricing/arrow-top.svg?react';
import girlImage from '@/assets/images/checkout-girl.webp';

export const PaymentFormWrapper = ({
  OrderSummary,
  children,
  isHidden,
}: PaymentFormWrapperProps) => {
  const { isNSFWContent } = useContentType();

  return (
    <div
      className={classNames(styles.formContainer, isHidden && styles.hidden)}
    >
      <img
        src={girlImage}
        alt="Premium AI girlfriend"
        className={classNames(styles.image, !isNSFWContent && styles.hidden)}
      />

      <div className={styles.paymentContent}>
        <h1 id="payment_info" className={styles.title}>
          Payment Information
        </h1>

        {children}

        <div className={styles.paymentMethods}>
          {PRICING_PAYMENT_ICONS.map((Icon, index) => (
            <Icon key={index} />
          ))}
        </div>

        <div className={styles.secureBlock}>
          {PAYMENT_SECURE_FEATURES.map(({ name, Icon }, index) => (
            <div className={styles.secureFeature} key={index}>
              <Icon />
              <p>{name}</p>
            </div>
          ))}
        </div>

        <p className={styles.checkout}>Checkout</p>
        <div className={styles.summaryBlock}>{OrderSummary}</div>

        <p className={styles.faq}>FAQ</p>

        <ul className={styles.faqList}>
          {PAYMENT_FAQ.map(({ summary, details }, index) => (
            <Accordion key={index} className={styles.accordion}>
              <AccordionSummary
                className={styles.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`payment-faq${index}-content`}
                id={`payment-faq${index}-header`}
              >
                {summary}
              </AccordionSummary>
              <AccordionDetails className={styles.accordionDetails}>
                {details}
              </AccordionDetails>
            </Accordion>
          ))}
        </ul>

        <p className={styles.contactUsBlock}>
          Have a question? <Link to={Routes.CONTACT_US_PAGE}>Contact Us</Link>
        </p>
      </div>
    </div>
  );
};
