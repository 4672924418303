import { SyntheticEvent } from 'react';

import defaultImg from '@/assets/images/default-error-img.webp';

export const DEFAULT_IMG = defaultImg;

export const setDefaultImgOnError = (
  event: SyntheticEvent<HTMLImageElement, Event>,
  fallbackSrc: string = DEFAULT_IMG,
) => {
  const target = event.target as HTMLImageElement;
  target.src = fallbackSrc;
  target.alt = 'Image currently unavailable';
  target.classList.add('img-error');
};
