import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';
import { getCommonPaymentParams } from '@/components/Modals/ModalCheckout/helpers.ts';
import { Spinner } from '@/components/Spinner';
import { useAppDispatch } from '@/hooks/useTSRedux.tsx';
import {
  paymentsApi,
  useUpdateSubscriptionMutation,
} from '@/services/paymentsServices';
import { userApi } from '@/services/userServices';

import { ModalDowngradeConfirmationProps } from './types.ts';

import styles from './styles.module.scss';

import premiumGirl from '@/assets/images/pricing/downgrade.webp';

export const ModalDowngradeConfirmation = ({
  productId,
  handleClose,
  onSuccess,
}: ModalDowngradeConfirmationProps) => {
  const dispatch = useAppDispatch();

  const [updateSubscription, { isLoading }] = useUpdateSubscriptionMutation();

  const handleDowngrade = async () => {
    const commonParams = await getCommonPaymentParams(productId);
    const { error } = await updateSubscription(commonParams);
    onSuccess(error ? 'error' : 'success');
    if (!error) {
      dispatch(paymentsApi.util.invalidateTags(['ActiveSubscription']));
      dispatch(userApi.util.invalidateTags(['User']));
    }
    handleClose();
  };

  return (
    <Modal
      closeButtonStyle="withBackground"
      onClose={handleClose}
      maxWidth={450}
      noPadding
    >
      <img src={premiumGirl} alt="Premium Girl" className={styles.girlImage} />
      <p className={styles.badge}>Don't lose your profit! 🤗</p>
      <div className={styles.content}>
        <p className={styles.title}>
          Are you sure you want to shorten your subscription?
        </p>
        <p className={styles.description}>
          Switching to a shorter subscription period means you’ll miss out on
          the best value for your money. Longer plans offer bigger savings over
          time, ensuring you get the same amazing benefits at a lower cost per
          month.
        </p>

        <div className={styles.actionButtons}>
          <Button variant="disabled" onClick={handleDowngrade} fullWidth>
            {isLoading ? <Spinner /> : 'Downgrade'}
          </Button>

          <Button onClick={handleClose} fullWidth>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
