import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

import {
  ANONYMOUS_USER_ID_KEY,
  BASE_URL_API,
  CONFIRMED_TERMS_KEY,
  IS_NGROK_API,
  REFRESH_PRE_REGISTER_URL,
  REFRESH_TOKEN_URL,
} from '@/const';
import {
  getTokensFromLocalStorage,
  removeTokensFromLocalStorage,
} from '@/utils/authUtils.ts';
import { checkIsOldAccessToken } from '@/utils/checkIsOldAccessToken.ts';
import { isTokenExpired } from '@/utils/isTokenExpired.ts';
import { ls } from '@/utils/localStorage.ts';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL_API,
  prepareHeaders: (headers) => {
    const { accessToken } = getTokensFromLocalStorage();
    const isOldToken = checkIsOldAccessToken();

    if (!headers.has('Content-Type')) {
      headers.set('Content-Type', 'application/json');
    }

    if (!accessToken || isOldToken || isTokenExpired()) {
      return headers;
    }

    if (!headers.get('Ignore-Authorization')) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    headers.delete('Ignore-Authorization');

    if (IS_NGROK_API) {
      headers.set('ngrok-skip-browser-warning', 'true');
    }

    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const { accessToken, refreshToken } = getTokensFromLocalStorage();
        const anonUserId = ls.get(ANONYMOUS_USER_ID_KEY, '');
        const confirmedTerms = ls.get(CONFIRMED_TERMS_KEY, '');

        const refreshResult = await baseQuery(
          {
            url: anonUserId ? REFRESH_PRE_REGISTER_URL : REFRESH_TOKEN_URL,
            method: 'POST',
            headers: {
              'Ignore-Authorization': 'true',
            },
            body: {
              accessToken: accessToken,
              refreshToken: refreshToken,
              userId: anonUserId,
              isNConfirmed: confirmedTerms === 'n',
              isSConfirmed: !!confirmedTerms,
            },
          },
          api,
          extraOptions,
        );
        if (refreshResult.data) {
          const { accessToken, refreshToken } = refreshResult.data as {
            accessToken: string;
            refreshToken: string;
          };
          api.dispatch({
            type: 'public/setAuth',
            payload: {
              accessToken,
              refreshToken,
              isLoginOrRegister: false,
            },
          });
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch({ type: 'public/logout' });
          removeTokensFromLocalStorage();
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
