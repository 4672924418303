export const CANCELLATION_FUTURE_OPTIONS = [
  {
    label: 'Very likely 😍',
    value: 'Very likely',
  },
  {
    label: 'Somewhat likely 😊',
    value: 'Somewhat likely',
  },
  {
    label: 'Neither likely or unlikely 😐',
    value: 'Neither likely or unlikely',
  },
  {
    label: 'Somewhat unlikely 😕',
    value: 'Somewhat unlikely',
  },
  {
    label: 'Very unlikely 🙁',
    value: 'Very unlikely',
  },
];
