import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { NSFW_ENABLED_KEY } from '@/const';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { useSetFingerprintMutation } from '@/services/userServices';
import { selectIsAuth, selectUser } from '@/store/public';
import { getFingerprintHash } from '@/utils/getFingerprintHash.ts';
import { ls } from '@/utils/localStorage.ts';

export type FingerprintData = {
  hash: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};

const UTM_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

const toPascalCase = (str: string | null): string => {
  if (!str) return '';
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
};

export const useFingerprint = (shouldSetFingerprint = false) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isFingerprintSet, setIsFingerprintSet] = useState(false);

  const [setFingerprint] = useSetFingerprintMutation();

  const isAuth = useAppSelector(selectIsAuth);
  const user = useAppSelector(selectUser);

  const postSetFingerprint = useCallback(
    async (payload?: Record<string, string>) => {
      const savedParams = ls.get(NSFW_ENABLED_KEY, null);
      const modifiedPayload =
        payload ||
        (savedParams && typeof savedParams === 'object'
          ? savedParams
          : undefined);

      if (!modifiedPayload) return;

      try {
        const fingerprintData = (await getFingerprint(true)) as FingerprintData;
        const Fingerprint = getFingerprintHash(fingerprintData);
        const Url = window.location.href;
        const Metadata = JSON.stringify(fingerprintData.data.system);

        ls.set(NSFW_ENABLED_KEY, { ...modifiedPayload, Url, Metadata });

        await setFingerprint({
          Fingerprint,
          Metadata,
          Url,
          ...modifiedPayload,
        });
      } catch (error) {
        console.error('Error setting fingerprint:', error);
      }
    },
    [setFingerprint],
  );

  useEffect(() => {
    const utmDataSaved = ls.get(NSFW_ENABLED_KEY, {});
    const utmSourceSaved = utmDataSaved?.UtmSource;
    const utmSourceFromQueryParams = searchParams.get('utm_source');
    const preLandDomain = searchParams.get('preLandDomain');

    if (
      !user ||
      isFingerprintSet ||
      !shouldSetFingerprint ||
      !utmSourceFromQueryParams ||
      utmSourceSaved === utmSourceFromQueryParams
    ) {
      return;
    }

    const utmData = UTM_PARAMS.reduce(
      (acc, param) => {
        const value = searchParams.get(param);
        if (value) acc[toPascalCase(param)] = value;
        return acc;
      },
      {} as Record<string, string>,
    );

    const propellerVisitorId = toPascalCase(
      searchParams.get('propeller_visitor_id'),
    );
    const propellerSubSourceId = toPascalCase(
      searchParams.get('propeller_sub_source_id'),
    );
    const aclid = searchParams.get('aclid');
    const gclid = searchParams.get('gclid');
    const clickid = searchParams.get('clickid');
    const cakeCookie = searchParams.get('cake_cookie');
    const tracking = searchParams.get('tracking');
    const googleTransactionId = searchParams.get('googleTransactionId');

    const isReferrerPresent =
      document.referrer &&
      !document.referrer.startsWith(window.location.origin) &&
      !document.referrer.includes('solidgate.com');

    const fetchFingerprint = async () => {
      const params = {
        ...utmData,
        ...(isReferrerPresent ? { referer: document.referrer } : {}),
        ...(propellerVisitorId ? { propellerVisitorId } : {}),
        ...(propellerSubSourceId ? { propellerSubSourceId } : {}),
        ...(aclid ? { aclid } : {}),
        ...(gclid ? { gclid } : {}),
        ...(clickid ? { clickid } : {}),
        ...(cakeCookie ? { cakeCookie } : {}),
        ...(tracking ? { tracking } : {}),
        ...(preLandDomain ? { preLandDomain } : {}),
        ...(googleTransactionId ? { googleTransactionId } : {}),
      };

      await postSetFingerprint(params);
      setIsFingerprintSet(true);

      if (isAuth) {
        setSearchParams({}, { replace: true });
      }
    };

    fetchFingerprint();
  }, [searchParams, postSetFingerprint, user]);

  return { postSetFingerprint };
};
