import { CancellationAnswers } from './types.ts';

export const INITIAL_CANCELLATION_ANSWERS_STATE: CancellationAnswers = {
  reason: '',
  feedback: '',
  changes: [],
  features: [],
  future: '',
  unsubscribe: '',
};

export const CANCELLATION_TEXTAREA_MIN_LENGTH = 10;
