import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { CHECKOUT_FORM_LOADED } from '@/const/dataLayerEvents.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';

const MOUNT_FORM_MAX_TIME = 3000;

type UseIframeLoadTrackerProps = {
  iframeId: string;
  enabled: boolean;
  maxLoadTime?: number;
};

export const useIframeLoadTracker = ({
  iframeId,
  maxLoadTime = MOUNT_FORM_MAX_TIME,
  enabled,
}: UseIframeLoadTrackerProps) => {
  const user = useAppSelector(selectUser);

  useEffect(() => {
    if (!enabled) return;

    const startTime = performance.now();
    let iframeLoaded = false;

    const reportLoadError = (elapsedTime: number, isFormClosed = false) => {
      const errorMessage = `Solid Payment form took longer than expected to load${isFormClosed ? ': Form closed before unmount' : ''}`;
      Sentry.captureException(errorMessage, {
        extra: {
          elapsedTime: `${(elapsedTime / 1000).toFixed(2)}s`,
          maxLoadTime: `${MOUNT_FORM_MAX_TIME / 1000}s`,
          isFormClosed,
        },
      });
    };

    const handleLoad = (event: Event) => {
      const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
      if (event.target === iframe) {
        iframeLoaded = true;
        pushToDataLayer(CHECKOUT_FORM_LOADED, user);
        const endTime = performance.now();
        const elapsedTime = endTime - startTime;

        if (elapsedTime > maxLoadTime) {
          reportLoadError(elapsedTime);
        }
      }
    };

    document.body.addEventListener('load', handleLoad, true);

    return () => {
      if (!iframeLoaded) {
        const endTime = performance.now();
        const elapsedTime = endTime - startTime;

        if (elapsedTime > maxLoadTime) {
          reportLoadError(elapsedTime, true);
        }
      }

      document.body.removeEventListener('load', handleLoad, true);
    };
  }, [iframeId, maxLoadTime, enabled]);
};
