import { jwtDecode } from 'jwt-decode';

import { getTokensFromLocalStorage } from '@/utils/authUtils.ts';

interface DecodedToken {
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/webpage'?: string;
}

export const checkIsOldAccessToken = (): boolean => {
  const { accessToken } = getTokensFromLocalStorage();

  if (!accessToken) {
    return false;
  }

  try {
    const decoded: DecodedToken = jwtDecode(accessToken);
    return !decoded[
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/webpage'
    ];
  } catch (error) {
    console.error('Invalid access token:', error);
    return false;
  }
};
