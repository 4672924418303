import { useCallback, useEffect } from 'react';

import { Button } from '@/components/Button';
import { Loader } from '@/components/Loader';
import { Modal } from '@/components/Modals/Modal';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import PricingBlockProduct from '@/pages/pricing/PricingBlock/Product';
import { convertSubscriptionPeriodToMonthsNumber } from '@/pages/pricing/helpers.ts';
import { useGetProductsQuery } from '@/services/paymentsServices';
import { SubscriptionProduct } from '@/services/paymentsServices/types.ts';
import {
  selectRenewSelectedProduct,
  setRenewSelectedProduct,
} from '@/store/renewSubscription';

import { RenewPaywallProps } from './types.ts';

import styles from './styles.module.scss';

import RenewIcon from '@/assets/icons/pricing/renew.svg?react';
import TokenIcon from '@/assets/icons/token.svg?react';

export const RenewPaywall = ({
  onClose,
  activeSubscription,
  isLoadingActiveSubscription,
  onUpgradeAction,
}: RenewPaywallProps) => {
  const dispatch = useAppDispatch();

  const selectedProduct = useAppSelector(selectRenewSelectedProduct);

  const { data: products, isLoading: isLoadingProducts } =
    useGetProductsQuery();

  const isLoading = isLoadingProducts || isLoadingActiveSubscription;

  const activeSubscriptionInMonths = convertSubscriptionPeriodToMonthsNumber(
    activeSubscription?.periodType,
    activeSubscription?.periodValue,
  );
  const filteredProducts = products?.filter((product) => {
    const productPeriodInMonths = convertSubscriptionPeriodToMonthsNumber(
      product.periodType,
      product.periodValue,
    );

    return productPeriodInMonths > activeSubscriptionInMonths;
  });

  useEffect(() => {
    if (!isLoadingProducts && filteredProducts) {
      dispatch(
        setRenewSelectedProduct(
          filteredProducts.find((product) => product.periodType === 'year') ||
            null,
        ),
      );
    }
  }, [isLoadingProducts]);

  const setSelectedProduct = useCallback(
    (product: SubscriptionProduct | null) => {
      dispatch(setRenewSelectedProduct(product));
    },
    [dispatch],
  );

  return (
    <Modal
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      closeButtonStyle="withBackground"
      containerClassName={styles.modalContainer}
      contentClassName={styles.container}
      disableAppearanceAnimation
      noPadding
    >
      <h1 className={styles.title}>
        Extend Your
        <br />
        Subscription and Save!
      </h1>
      <p className={styles.description}>
        Switch to a longer plan and enjoy uninterrupted access without the need
        for frequent renewals. It's a simple way to save time and ensure
        continuous use!
      </p>
      <div className={styles.tokensBadge}>
        <TokenIcon /> Get 100 bonus tokens for renewal
      </div>

      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        filteredProducts?.map((product, index) => (
          <PricingBlockProduct
            key={product.id}
            index={index}
            isLifeTimeSubscription={false}
            product={product}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            activeSubscriptionInMonths={activeSubscriptionInMonths}
            className={styles.pricingBlockProduct}
            selectedClassName={styles.selected}
          />
        ))
      )}
      <Button onClick={onUpgradeAction} disabled={!selectedProduct} fullWidth>
        <RenewIcon />
        Upgrade Now
      </Button>
    </Modal>
  );
};
