import { useEffect } from 'react';

import { Button } from '@/components/Button';
import { CheckboxGroup } from '@/components/CheckboxGroup';
import { Modal } from '@/components/Modals/Modal';
import {
  SUBSCRIPTION_CANCELLATION_FEATURE_LIST_POPUP_CLOSED_EVENT,
  SUBSCRIPTION_CANCELLATION_FEATURE_LIST_POPUP_SENT_EVENT,
  SUBSCRIPTION_CANCELLATION_FEATURE_LIST_POPUP_SHOWN_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { CancellationComponentProps } from '@/pages/pricing/CancellationSubscription/types.ts';
import { selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';

import { CANCELLATION_FEATURES_OPTIONS } from './constants.ts';

import styles from './styles.module.scss';

export const CancellationFeatures = ({
  setStep,
  onClose,
  answers,
  handleUpdateAnswers,
}: CancellationComponentProps) => {
  const user = useAppSelector(selectUser);

  useEffect(() => {
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_FEATURE_LIST_POPUP_SHOWN_EVENT,
      user,
    );
  }, []);

  const onChange = (selectedOption: string[]) => {
    handleUpdateAnswers('features', selectedOption);
  };

  const onAction = () => {
    setStep('future');
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_FEATURE_LIST_POPUP_SENT_EVENT,
      user,
      { feature_list: JSON.stringify(answers.features) },
    );
  };
  const handleClose = () => {
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_FEATURE_LIST_POPUP_CLOSED_EVENT,
      user,
    );
    onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      closeButtonStyle="absolute"
      disableAppearanceAnimation
      maxWidth={550}
    >
      <div className={styles.container}>
        <p className={styles.heading}>
          What features you would like to see the most?
        </p>
        <CheckboxGroup
          options={CANCELLATION_FEATURES_OPTIONS}
          selectedOptions={answers.features}
          onChange={onChange}
        />

        <Button
          disabled={!answers.features.length}
          onClick={onAction}
          fullWidth
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};
