import { useCallback, useEffect, useMemo } from 'react';

import { ModalCheckout } from '@/components/Modals/ModalCheckout';
import ModalActionResult from '@/components/Modals/ModalSuccessAction';
import { PRICING_SOURCE_KEY } from '@/const';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux';
import { formatPeriodToText } from '@/pages/pricing/PricingBlock/helpers';
import { RenewPaywall } from '@/pages/settings/components/RenewPaywall';
import {
  useGetActiveSubscriptionQuery,
  useRenewSubscriptionMutation,
} from '@/services/paymentsServices';
import { selectUser } from '@/store/public';
import {
  selectRenewSelectedProduct,
  selectRenewSubscriptionStep,
  setRenewSubscriptionStep,
} from '@/store/renewSubscription';
import { ls } from '@/utils/localStorage';

export const useRenewSubscription = () => {
  const dispatch = useAppDispatch();

  const step = useAppSelector(selectRenewSubscriptionStep);
  const selectedProduct = useAppSelector(selectRenewSelectedProduct);
  const user = useAppSelector(selectUser);
  const userSubscriptionId = user?.activeSubscription?.id;
  const isSubscriptionCancelled = Boolean(
    user?.activeSubscription?.cancellation_requested_at,
  );

  const [renewSubscription, { isLoading: isLoadingRenew }] =
    useRenewSubscriptionMutation();
  const { data: activeSubscription, isLoading: isLoadingActiveSubscription } =
    useGetActiveSubscriptionQuery();

  useEffect(() => {
    if (step === 'success') ls.set(PRICING_SOURCE_KEY, 'renew_subscription');
  }, [step]);

  const onClickRenew = useCallback(async () => {
    const { error } = await renewSubscription(userSubscriptionId);
    dispatch(setRenewSubscriptionStep(error ? 'failed' : 'success'));
  }, [dispatch, renewSubscription, userSubscriptionId]);

  const onClickTryAgain = useCallback(async () => {
    const { error } = await renewSubscription(userSubscriptionId);
    dispatch(setRenewSubscriptionStep(error ? 'failed' : 'success'));
  }, [dispatch, renewSubscription, userSubscriptionId]);

  const onSuccessAction = useCallback(() => {
    dispatch(
      setRenewSubscriptionStep(
        activeSubscription?.periodType === 'year' ? 'initial' : 'upsell',
      ),
    );
  }, [dispatch, activeSubscription?.periodType]);

  const onUpgradeAction = useCallback(
    () => dispatch(setRenewSubscriptionStep('checkout')),
    [dispatch],
  );

  const resetStep = useCallback(
    () => dispatch(setRenewSubscriptionStep('initial')),
    [dispatch],
  );

  const productTitle = useMemo(
    () => `${formatPeriodToText(selectedProduct)} subscription`,
    [selectedProduct],
  );

  const COMPONENTS = useMemo(
    () => ({
      success: (
        <ModalActionResult
          title="Subscription Renewal Successful!"
          onAction={onSuccessAction}
          onClose={onSuccessAction}
          description="Your subscription has been successfully renewed. Thank you for continuing to be a valued member of our community!"
          actionButtonLabel="Continue"
          variant="success"
        />
      ),
      failed: (
        <ModalActionResult
          title="Subscription Renewal Issue"
          onAction={onClickTryAgain}
          onClose={resetStep}
          description="There was an issue with your renewal. Please try again."
          actionButtonLabel="Try Again"
          variant="failed"
        />
      ),
      upsell: (
        <RenewPaywall
          onClose={resetStep}
          activeSubscription={activeSubscription}
          isLoadingActiveSubscription={isLoadingActiveSubscription}
          onUpgradeAction={onUpgradeAction}
        />
      ),
      checkout: (
        <ModalCheckout
          onClose={resetStep}
          productTitle={productTitle}
          selectedProduct={selectedProduct}
          paywallType="default_subscription"
          disableAppearanceAnimation
        />
      ),
      initial: null,
    }),
    [
      onSuccessAction,
      resetStep,
      onClickTryAgain,
      activeSubscription,
      isLoadingActiveSubscription,
      onUpgradeAction,
      productTitle,
      selectedProduct,
    ],
  );

  const RenewComponent = COMPONENTS[step];

  return {
    isLoadingRenew,
    isSubscriptionCancelled,
    onClickRenew,
    RenewComponent,
  };
};
