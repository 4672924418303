import { useEffect } from 'react';

import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';
import { Toast } from '@/components/Toast';
import { SUBSCRIPTION_CANCELLATION_CONFIRMED_SUCCESSFULLY_EVENT } from '@/const/dataLayerEvents.ts';
import { useBoolean } from '@/hooks/useBoolean.tsx';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { CancellationComponentProps } from '@/pages/pricing/CancellationSubscription/types.ts';
import { selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';

import styles from './styles.module.scss';

import SuccessIcon from '@/assets/icons/success-green-check.svg?react';

export const CancellationSuccess = ({
  onClose,
  answers,
}: CancellationComponentProps) => {
  const user = useAppSelector(selectUser);

  const [isToastSuccessContactOpened, _, closeToast] = useBoolean(
    !answers.unsubscribe,
  );

  useEffect(() => {
    pushToDataLayer(
      SUBSCRIPTION_CANCELLATION_CONFIRMED_SUCCESSFULLY_EVENT,
      user,
    );
  }, []);

  return (
    <>
      <Modal
        onClose={onClose}
        closeButtonStyle="absolute"
        disableAppearanceAnimation
        maxWidth={450}
      >
        <div className={styles.container}>
          <SuccessIcon className={styles.successIcon} />
          <p className={styles.heading}>Subscription Canceled Successfully</p>
          <p className={styles.description}>
            Thank you for your feedback. Your subscription has been successfully
            canceled.
          </p>

          <Button onClick={onClose} fullWidth>
            Continue
          </Button>
        </div>
      </Modal>

      <Toast
        id="toast_contact_success"
        open={isToastSuccessContactOpened}
        onClose={closeToast}
        message="Your request has been successfully sent! We will contact you shortly!"
        severity="success"
      />
    </>
  );
};
