import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';
import { Spinner } from '@/components/Spinner';
import {
  SUBSCRIPTION_CANCELLATION_FEEDBACK_FORM_CLOSED_EVENT,
  SUBSCRIPTION_CANCELLATION_FEEDBACK_FORM_SENT_EVENT,
  SUBSCRIPTION_CANCELLATION_FEEDBACK_FORM_SHOWN_EVENT,
} from '@/const/dataLayerEvents.ts';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { CANCELLATION_TEXTAREA_MIN_LENGTH } from '@/pages/pricing/CancellationSubscription/constants.ts';
import { CancellationComponentProps } from '@/pages/pricing/CancellationSubscription/types.ts';
import { useCancelSubscriptionMutation } from '@/services/paymentsServices';
import { selectIsYunoPaymentMethod, selectUser } from '@/store/public';
import { pushToDataLayer } from '@/utils/gtm.ts';

import styles from './styles.module.scss';

import unsubscribeBannerImage from '@/assets/images/pricing/unsubscribe-banner.webp';

export const CancellationUnsubscribe = ({
  setStep,
  answers,
  handleUpdateAnswers,
  onClose,
}: CancellationComponentProps) => {
  const user = useAppSelector(selectUser);
  const isYunoPayment = useAppSelector(selectIsYunoPaymentMethod);
  const [isInputError, setIsInputError] = useState(false);

  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();

  useEffect(() => {
    pushToDataLayer(SUBSCRIPTION_CANCELLATION_FEEDBACK_FORM_SHOWN_EVENT, user);
  }, []);

  const onAction = async () => {
    if (!isValidInput) {
      setIsInputError(true);
      return;
    }
    await cancelSubscription({
      subscriptionId: user?.activeSubscription?.id || '',
      isYunoPayment,
      answers,
    });
    setStep('success');
    pushToDataLayer(SUBSCRIPTION_CANCELLATION_FEEDBACK_FORM_SENT_EVENT, user);
  };
  const handleClose = () => {
    pushToDataLayer(SUBSCRIPTION_CANCELLATION_FEEDBACK_FORM_CLOSED_EVENT, user);
    onClose();
  };

  const isValidInput =
    answers.unsubscribe.trim().length >= CANCELLATION_TEXTAREA_MIN_LENGTH;

  return (
    <Modal
      onClose={handleClose}
      closeButtonStyle="withBackground"
      disableAppearanceAnimation
      maxWidth={450}
      noPadding
    >
      <img src={unsubscribeBannerImage} alt="Premium girl" />
      <div className={styles.container}>
        <p className={styles.heading}>We're Sorry to See You Go!</p>
        <p className={styles.description}>
          To help us understand how we can improve, please share why you’re
          considering canceling your subscription. Your feedback is valuable to
          us!
        </p>

        <div>
          <textarea
            value={answers.unsubscribe}
            onChange={(e) => {
              handleUpdateAnswers('unsubscribe', e.target.value);
              if (isValidInput) setIsInputError(false);
            }}
            placeholder="Type your feedback here..."
            className={classNames(
              styles.textarea,
              isInputError && styles.withError,
            )}
          />
          <div className={styles.textareaHelpers}>
            {isInputError && (
              <p className={styles.errorText}>
                Please enter at least {CANCELLATION_TEXTAREA_MIN_LENGTH}
              </p>
            )}

            <p
              className={classNames(
                styles.symbols,
                isInputError && styles.withError,
              )}
            >
              {answers.unsubscribe.trim().length}/
              {CANCELLATION_TEXTAREA_MIN_LENGTH}
            </p>
          </div>
        </div>

        <Button onClick={onAction} disabled={isLoading} fullWidth>
          {isLoading ? <Spinner /> : 'Send feedback and unsubscribe'}
        </Button>
      </div>
    </Modal>
  );
};
