import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from '@/services/utils/baseQueryWithReauth.ts';

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['mutationChat'],
  endpoints: (builder) => ({
    postContactUsMessage: builder.mutation<
      void,
      { email: string; description: string }
    >({
      query: (body) => ({
        url: `contact-us`,
        method: 'POST',
        body,
      }),
    }),

    postSendMessageFeedback: builder.mutation<
      void,
      { email: string; description: string }
    >({
      query: (body) => ({
        url: `feedback`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  usePostContactUsMessageMutation,
  usePostSendMessageFeedbackMutation,
} = contactApi;
