import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectUser } from '@/store/public';

import { getBottomNavigationLinks } from './constants.ts';
import { BottomNavigationLinkSkeleton } from './skeleton.tsx';

import styles from './styles.module.scss';

export const BottomNavigation = () => {
  const user = useAppSelector(selectUser);

  return (
    <div className={styles.bottomNavigationContainer}>
      <ul className={styles.bottomNavigation}>
        {getBottomNavigationLinks(!!user?.activeSubscription, !user).map(
          ({ link, Icon, label, id, isLoading }) => (
            <li key={id}>
              {isLoading ? (
                <BottomNavigationLinkSkeleton />
              ) : (
                <NavLink
                  to={link}
                  id={id}
                  className={({ isActive }) =>
                    classNames(styles.bottomLink, isActive && styles.active)
                  }
                >
                  <Icon />
                  {label}
                </NavLink>
              )}
            </li>
          ),
        )}
      </ul>
    </div>
  );
};
