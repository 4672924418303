import { FC, useState } from 'react';

import { ModalCheckout } from '@/components/Modals/ModalCheckout';
import { useBoolean } from '@/hooks/useBoolean.tsx';
import { CancellationChanges } from '@/pages/pricing/CancellationSubscription/Changes';
import { CancellationFeatures } from '@/pages/pricing/CancellationSubscription/Features';
import { CancellationFuture } from '@/pages/pricing/CancellationSubscription/Future';
import { CancellationOffer } from '@/pages/pricing/CancellationSubscription/Offer';
import { OfferCancelConfirmation } from '@/pages/pricing/CancellationSubscription/OfferCancelConfirmation';
import { CancellationSuccess } from '@/pages/pricing/CancellationSubscription/Success';
import { CancellationUnsubscribe } from '@/pages/pricing/CancellationSubscription/Unsubscribe';
import { ModalUpsellTokens } from '@/pages/pricing/ModalUpsellTokes';
import { useGetLifetimeProductQuery } from '@/services/paymentsServices';

import { CancellationFeedback } from './Feedback';
import { CancellationReason } from './Reason';
import { CancellationReminder } from './Reminder';
import { CancellationWarning } from './Warning';
import { INITIAL_CANCELLATION_ANSWERS_STATE } from './constants.ts';
import {
  CancellationAnswers,
  CancellationComponentProps,
  CancellationStep,
  CancellationSubscriptionProps,
} from './types.ts';

const CANCELLATION_COMPONENTS: Record<
  CancellationStep,
  FC<CancellationComponentProps>
> = {
  warning: CancellationWarning,
  reason: CancellationReason,
  feedback: CancellationFeedback,
  reminder: CancellationReminder,
  changes: CancellationChanges,
  features: CancellationFeatures,
  future: CancellationFuture,
  offer: CancellationOffer,
  offerCancelConfirmation: OfferCancelConfirmation,
  // freeTokens: CancellationFreeTokens,
  unsubscribe: CancellationUnsubscribe,
  success: CancellationSuccess,
};

export const CancellationSubscription = ({
  onClose,
}: CancellationSubscriptionProps) => {
  const [answers, setAnswers] = useState<CancellationAnswers>(
    INITIAL_CANCELLATION_ANSWERS_STATE,
  );
  const [step, setStep] = useState<CancellationStep>('warning');
  const [isModalCheckoutOpened, openModalCheckout, closeModalCheckout] =
    useBoolean();
  const [isModalUpsellTokensOpened, openModalUpsellTokens] = useBoolean();

  const { data = [] } = useGetLifetimeProductQuery();

  const handleUpdateAnswers = (key: string, value: string | string[]) => {
    setAnswers((prev) => ({ ...prev, [key]: value }));
  };

  const CurrentComponent = CANCELLATION_COMPONENTS[step];

  return (
    <>
      <CurrentComponent
        step={step}
        setStep={setStep}
        answers={answers}
        handleUpdateAnswers={handleUpdateAnswers}
        onClose={onClose}
        openModalCheckout={openModalCheckout}
      />

      {isModalCheckoutOpened && (
        <ModalCheckout
          onClose={closeModalCheckout}
          productTitle="Lifetime subscription"
          selectedProduct={data?.[0]}
          handlePaymentSuccess={openModalUpsellTokens}
          paywallType="default_subscription"
        />
      )}

      {isModalUpsellTokensOpened && (
        <ModalUpsellTokens closeUpsellFlow={onClose} />
      )}
    </>
  );
};
