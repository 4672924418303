import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { YUNO_START_CHECKOUT_PARAMS } from '@/components/Modals/ModalCheckout/YunoPayment/constants.ts';
import { getCommonPaymentParams } from '@/components/Modals/ModalCheckout/helpers.ts';
import { CurrentPaymentState } from '@/components/Modals/ModalCheckout/types.ts';
import { YUNO_API_KEY } from '@/const';
import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import {
  useCreatePaymentIntentYunoMutation,
  useCreatePaymentYunoMutation,
} from '@/services/paymentsServices';
import { selectIsAuth, selectUser } from '@/store/public';
import { validateEmail } from '@/utils/validateEmail.ts';

type UsePaymentIntentProps = {
  initializeConnection: (queryParams?: string | undefined) => void;
  initializeRegister: (queryParams?: string | undefined) => void;
  isPurchaseTokens: boolean;
  productId?: string;
  currentStep: CurrentPaymentState;
  setCurrentStep: Dispatch<SetStateAction<CurrentPaymentState>>;
  additionalDiscountPercent: number | undefined;
  setErrorCode: Dispatch<SetStateAction<string>>;
};

export const usePaymentIntentYuno = ({
  initializeConnection,
  initializeRegister,
  isPurchaseTokens,
  productId = '',
  currentStep,
  setCurrentStep,
  additionalDiscountPercent,
  setErrorCode,
}: UsePaymentIntentProps) => {
  const isAuth = useAppSelector(selectIsAuth);
  const user = useAppSelector(selectUser);

  const [sessionId, setSessionId] = useState('');
  const [userEmail, setUserEmail] = useState(user?.email || '');
  const [emailError, setEmailError] = useState('');
  // const [yuno, setYuno] = useState<YunoInstance | null>(null);

  const userEmailRef = useRef(user?.email || '');
  const instanceFlag = useRef(0);
  const yuno = useMemo(() => {
    if (instanceFlag.current !== 0) {
      return;
    }
    instanceFlag.current = 1;
    return Yuno.initialize(YUNO_API_KEY);
  }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://sdk-web.y.uno/v1/static/js/main.min.js';
  //   script.async = true;
  //
  //   script.onload = () => {
  //     const yunoInstance = window.Yuno;
  //     if (yunoInstance) {
  //       setYuno(yunoInstance.initialize(YUNO_API_KEY));
  //     }
  //   };
  //
  //   document.body.appendChild(script);
  //
  //   return () => {
  //     if (script) {
  //       document.body.removeChild(script);
  //       window.Yuno = undefined;
  //     }
  //   };
  // }, []);

  const [createPayment] = useCreatePaymentYunoMutation();
  const [createPaymentIntent, { reset: resetPaymentData }] =
    useCreatePaymentIntentYunoMutation();

  useEffect(() => {
    if (!sessionId || !yuno) return;

    async function initCheckout() {
      if (!yuno) return;

      yuno.startCheckout({
        ...YUNO_START_CHECKOUT_PARAMS,
        //eslint-disable-next-line
        // @ts-ignore next-line
        showPayButton: false,
        checkoutSession: sessionId,
        onLoading({
          isLoading,
          type,
        }: {
          isLoading: boolean;
          type: LoadingType;
        }) {
          if (isLoading && type === 'ONE_TIME_TOKEN') {
            setCurrentStep('paymentProcessing');
          }
          if (
            !isLoading &&
            type === 'DOCUMENT' &&
            currentStep !== 'paymentProcessing'
          )
            setCurrentStep('paymentForm');
        },
        yunoCreatePayment: async (oneTimeToken) => {
          setCurrentStep('paymentProcessing');
          const commonParams = await getCommonPaymentParams(
            productId,
            isPurchaseTokens,
            !!additionalDiscountPercent,
          );

          const { data: createPaymentData, error } = await createPayment({
            ...commonParams,
            oneTimeToken,
            sessionId,
            email: userEmailRef.current,
          });
          if (error) {
            setCurrentStep('fail');
            return;
          }

          if (createPaymentData?.actionRequired) {
            yuno.continuePayment();
          }
        },
        yunoPaymentResult: (res) => {
          console.log('yunoPaymentResult', res);
        },
        yunoError: (error) => {
          console.log('yunoError', error);
          setErrorCode(error);
        },
      });

      yuno?.mountCheckoutLite({ paymentMethodType: 'CARD' });
    }
    initCheckout();
  }, [sessionId, yuno]);

  const handleSubmitPayment = () => {
    if (!userEmail || !validateEmail(userEmail)) {
      userEmail
        ? setEmailError('Email is invalid')
        : setEmailError('This field is required');
      return;
    }

    setEmailError('');
    //eslint-disable-next-line
    // @ts-ignore next-line
    yuno?.submitOneTimeTokenForm();
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (emailError) {
      const isValid = validateEmail(value);
      setEmailError(
        isValid ? '' : value ? 'Email is invalid.' : 'This field is required.',
      );
    }
    setUserEmail(value);
    userEmailRef.current = value;
  };

  const initializePayment = async (cardId?: string) => {
    const commonParams = await getCommonPaymentParams(
      productId,
      isPurchaseTokens,
      !!additionalDiscountPercent,
    );
    const { data, error } = await createPaymentIntent({
      ...commonParams,
      cardId,
    });
    if (!error) {
      const currentSessionId = data.checkoutSession;
      setSessionId(currentSessionId);
      const queryParam = data?.createdUserId
        ? `?userId=${data?.createdUserId}`
        : '';
      initializeConnection(queryParam);
      if (cardId) {
        const commonParams = await getCommonPaymentParams(
          productId,
          isPurchaseTokens,
          !!additionalDiscountPercent,
        );

        const { error } = await createPayment({
          ...commonParams,
          sessionId: currentSessionId,
          cardId,
        });
        if (error) {
          setCurrentStep('fail');
        }
      }
      if (!isAuth) initializeRegister(queryParam);
    } else {
      setCurrentStep('fail');
    }
  };

  return {
    initializePayment,
    resetPaymentData,
    sessionId,
    handleSubmitPayment,
    handleEmailChange,
    userEmail,
    emailError,
  };
};
