import classNames from 'classnames';
import { MouseEventHandler } from 'react';

import { useAppSelector } from '@/hooks/useTSRedux.tsx';
import { selectUser } from '@/store/public';

import { RadioProps } from './types';

import styles from './styles.module.scss';

export const RadioButton = ({
  name,
  label,
  customRadioIcon,
  checked,
  onChange,
  register,
  required,
  children,
  labelClassName,
  isPremium,
  id,
}: RadioProps) => {
  const isControlled = checked !== undefined && onChange !== undefined;

  const user = useAppSelector(selectUser);

  const handleClick: MouseEventHandler<HTMLLabelElement> = (e) => {
    if (isPremium && !user?.activeSubscription) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <label
      id={id}
      className={classNames(styles.label, labelClassName)}
      onClick={handleClick}
    >
      {isControlled ? (
        <input
          type="radio"
          checked={checked}
          onChange={(e) => onChange?.(e.target.checked)}
          className={styles.input}
          name={name}
        />
      ) : (
        <input
          type="radio"
          {...(register && name ? register(name, { required }) : {})}
          className={styles.input}
          name={name}
        />
      )}
      <span className={styles.customRadio}>
        {(isControlled ? checked : false) ? customRadioIcon : null}
      </span>
      {children}
      {label && <span>{label}</span>}
    </label>
  );
};
