import { ChangeEvent, ReactNode } from 'react';

export type PaymentFormProps = {
  OrderSummary: ReactNode;
  handleSubmitPayment: () => void;
  userEmail: string;
  handleEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
  emailError: string;
  showLoader: boolean;
  isHidden: boolean;
};

export enum YunoPaymentErrors {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  DECLINED_BY_BANK = 'DECLINED_BY_BANK',
  DO_NOT_HONOR = 'DO_NOT_HONOR',
  INVALID_SECURITY_CODE = 'INVALID_SECURITY_CODE',
  INVALID_CARD_DATA = 'INVALID_CARD_DATA',
  REPORTED_STOLEN = 'REPORTED_STOLEN',
  ERROR = 'ERROR',
}
