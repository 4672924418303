export const SIGNUP_BUTTON_CLICKED_EVENT = 'signup_button_clicked';
export const USER_SIGNED_UP_EVENT = 'user_signed_up';
export const USER_SIGNED_IN_EVENT = 'user_signed_in';
export const DELETE_ACCOUNT_BUTTON_CLICKED_EVENT =
  'delete_account_button_clicked';
export const ACCOUNT_DELETED_EVENT = 'account_deleted';
export const CHAT_OPENED_EVENT = 'chat_opened';
export const MESSAGE_SENT_20_EVENT = 'message_sent_20';
export const SUGGESTED_MESSAGE_CLICKED_EVENT = 'suggested_message_clicked';
export const DELETE_CHAT_EVENT = 'delete_chat_button_clicked';
export const CLEAN_CHAT_EVENT = 'clear_chat_button_clicked';
export const IMAGE_OPENED_EVENT = 'image_opened_fullscreen';
export const IMAGE_UNBLURRED_EVENT = 'image_unblurred';
export const PAYWALL_SHOWN_EVENT = 'paywall_shown';
export const CHECKOUT_PAGE_OPENED_EVENT = 'checkout_page_opened';
export const PURCHASE_EVENT = 'purchase';
export const PAYMENT_FAILED_EVENT = 'payment_failed';
export const SUBSCRIPTION_MANAGE_BUTTON_CLICKED_EVENT =
  'subscription_manage_button_clicked';
export const SIGNUP_POPUP_SHOWN_EVENT = 'signup_popup_shown';
export const MESSAGE_SEND_BUTTON_CLICKED_EVENT = 'message_send_button_clicked';
export const CHAT_CLEARED_EVENT = 'chat_cleared';
export const CHAT_DELETED_EVENT = 'chat_deleted';
export const INSUFFICIENT_POPUP_SHOWN_EVENT = 'insufficient_popup_shown';
export const ADD_TOKENS_BUTTON_CLICKED_EVENT = 'add_tokens_button_clicked';
export const SIGN_IN_BUTTON_CLICKED_EVENT = 'sign_in_button_clicked';
export const ASK_PHOTO_BUTTON_CLICKED_EVENT = 'ask_photo_button_clicked';
export const PRE_PAYWALL_POPUP_SHOWN_EVENT = 'pre_paywall_popup_shown';
export const PRE_PAYWALL_BUTTON_CLICKED_EVENT = 'pre_paywall_button_clicked';
export const GET_HONEY_BANNER_CLICKED_EVENT = 'get_honey_banner_clicked';
export const CREATE_AI_BANNER_CLICKED_EVENT = 'create_ai_banner_clicked';
export const MAGIC_LINK_SENT_EVENT = 'magic_link_sent';
export const CHECKOUT_FORM_LOADED = 'checkout_form_loaded';
export const CHARACTER_CREATION_STARTED_EVENT = 'character_creation_started';
export const CHARACTER_CREATION_GENERATED_EVENT =
  'character_creation_generated';
export const CHARACTER_CREATION_START_CHATTING_BUTTON_CLICKED =
  'character_creation_start_chatting_button_clicked';
export const CREATE_NEW_BUTTON_CLICKED = 'create_new_button_clicked';
export const CHARACTER_CREATION_STEP = (step: number) =>
  `character_creation_step_${step}`;

export const TOKENS_UPSELL_POPUP_SHOWN_EVENT = 'tokens_upsell_popup_shown';
export const TOKENS_UPSELL_POPUP_CLICKED_EVENT = 'tokens_upsell_popup_clicked';
export const TOKENS_UPSELL_POPUP_CLOSED_EVENT = 'tokens_upsell_popup_closed';
export const TOKENS_UPSELL_POPUP_CLOSE_CONFIRMED_EVENT =
  'tokens_upsell_popup_close_confirmed';
export const TOKENS_UPSELL_POPUP_CLOSE_REJECTED_EVENT =
  'tokens_upsell_popup_close_rejected';
export const CONTACT_US_BUTTON_CLICKED_ON_CHECKOUT_PAGE_EVENT =
  'contact_us_button_clicked_on_checkout_page';
export const TERMS_POPUP_SHOWN_EVENT = 'terms_popup_shown';
export const TERMS_POPUP_CLICKED_EVENT = 'terms_popup_clicked';
export const LIKE_BUTTON_CLICKED_EVENT = 'like_button_clicked';
export const DISLIKE_BUTTON_CLICKED_EVENT = 'dislike_button_clicked';
export const ADDITIONAL_REVIEW_POPUP_SHOWN_EVENT =
  'additional_review_popup_shown';
export const ADDITIONAL_REVIEW_POPUP_SENT_EVENT =
  'additional_review_feedback_sent';
export const ADDITIONAL_REVIEW_POPUP_FAILED_EVENT =
  'additional_review_feedback_failed';
export const CHARACTER_GENERATION_FAILED_EVENT = 'character_generation_failed';
export const CHARACTER_GENERATION_ERROR_SHOWN_EVENT =
  'character_generation_error_shown';
export const CHARACTER_GENERATION_ERROR_CLOSED_EVENT =
  'character_generation_error_clicked';

export const SUBSCRIPTION_CANCELLATION_WARNING_POPUP_SHOWN_EVENT =
  'subscription_cancellation_warning_popup_shown';
export const SUBSCRIPTION_CANCELLATION_WARNING_POPUP_CLOSED_EVENT =
  'subscription_cancellation_warning_popup_closed';
export const SUBSCRIPTION_CANCELLATION_WARNING_POPUP_CANCEL_CLICKED_EVENT =
  'subscription_cancellation_warning_popup_cancel_clicked';
export const SUBSCRIPTION_CANCELLATION_REASON_POPUP_SHOWN_EVENT =
  'subscription_cancellation_reason_popup_shown';
export const SUBSCRIPTION_CANCELLATION_REASON_POPUP_CLOSED_EVENT =
  'subscription_cancellation_reason_popup_closed';
export const SUBSCRIPTION_CANCELLATION_REASON_POPUP_CLICKED_EVENT =
  'subscription_cancellation_reason_popup_clicked';
export const SUBSCRIPTION_CANCELLATION_REMINDER_POPUP_SHOWN_EVENT =
  'subscription_cancellation_reminder_popup_shown';
export const SUBSCRIPTION_CANCELLATION_REMINDER_POPUP_CLOSED_EVENT =
  'subscription_cancellation_reminder_popup_closed';
export const SUBSCRIPTION_CANCELLATION_REMINDER_POPUP_LOOSE_ACCESS_EVENT =
  'subscription_cancellation_reminder_popup_loose_access';
export const SUBSCRIPTION_CANCELLATION_IMPROVEMENT_LIST_POPUP_CLOSED_EVENT =
  'subscription_cancellation_improvement_list_popup_closed';
export const SUBSCRIPTION_CANCELLATION_IMPROVEMENT_LIST_POPUP_SENT_EVENT =
  'subscription_cancellation_improvement_list_popup_sent';
export const SUBSCRIPTION_CANCELLATION_IMPROVEMENT_LIST_POPUP_SHOWN_EVENT =
  'subscription_cancellation_feature_list_popup_shown';
export const SUBSCRIPTION_CANCELLATION_FEATURE_LIST_POPUP_SHOWN_EVENT =
  'subscription_cancellation_feature_list_popup_shown';
export const SUBSCRIPTION_CANCELLATION_FEATURE_LIST_POPUP_SENT_EVENT =
  'subscription_cancellation_feature_list_popup_sent';
export const SUBSCRIPTION_CANCELLATION_FEATURE_LIST_POPUP_CLOSED_EVENT =
  'subscription_cancellation_feature_list_popup_closed';
export const SUBSCRIPTION_CANCELLATION_FUTURE_USAGE_POPUP_SHOWN_EVENT =
  'subscription_cancellation_future_usage_popup_shown';
export const SUBSCRIPTION_CANCELLATION_FUTURE_USAGE_POPUP_CLOSED_EVENT =
  'subscription_cancellation_future_usage_popup_closed';
export const SUBSCRIPTION_CANCELLATION_FUTURE_USAGE_POPUP_SENT_EVENT =
  'subscription_cancellation_future_usage_popup_sent';
export const SUBSCRIPTION_CANCELLATION_LIFETIME_DOWSELL_SHOWN_EVENT =
  'subscription_cancellation_lifetime_downsell_shown';
export const SUBSCRIPTION_CANCELLATION_LIFETIME_DOWSELL_CLOSED_EVENT =
  'subscription_cancellation_lifetime_downsell_closed';
export const SUBSCRIPTION_CANCELLATION_LIFETIME_DOWSELL_CLAIM_EVENT =
  'subscription_cancellation_lifetime_downsell_claim';
export const SUBSCRIPTION_CANCELLATION_LIFETIME_DOWNSELL_ARE_YOU_SURE_SHOWN_EVENT =
  'subscription_cancellation_lifetime_downsell_are_you_sure_shown';
export const SUBSCRIPTION_CANCELLATION_LIFETIME_DOWNSELL_ARE_YOU_SURE_CLOSED_EVENT =
  'subscription_cancellation_lifetime_downsell_are_you_sure_closed';
export const SUBSCRIPTION_CANCELLATION_LIFETIME_DOWNSELL_ARE_YOU_SURE_LOSE_ACCESS_EVENT =
  'subscription_cancellation_lifetime_downsell_are_you_sure_lose_access';
export const SUBSCRIPTION_CANCELLATION_FEEDBACK_FORM_SHOWN_EVENT =
  'subscription_cancellation_feedback_form_shown';
export const SUBSCRIPTION_CANCELLATION_FEEDBACK_FORM_SENT_EVENT =
  'subscription_cancellation_feedback_form_sent';
export const SUBSCRIPTION_CANCELLATION_FEEDBACK_FORM_CLOSED_EVENT =
  'subscription_cancellation_feedback_form_closed';
export const SUBSCRIPTION_CANCELLATION_CONFIRMED_SUCCESSFULLY_EVENT =
  'subscription_cancellation_confirmed_successfully';

export const PERSONA_PROFILE_VIEWED_EVENT = 'persona_profile_viewed';
export const PERSONA_PROFILE_IMAGE_VIEWED_EVENT =
  'persona_profile_image_viewed';
export const PERSONA_PROFILE_MEDIA_VIEWED_EVENT =
  'persona_profile_media_viewed';
