import { useEffect } from 'react';

export const useScroll = (isEnabled: boolean = false) => {
  useEffect(() => {
    const isAlreadyHidden = document.body.style.overflow === 'hidden';

    if (!isAlreadyHidden) {
      document.body.style.overflow = isEnabled ? 'scroll' : 'hidden';
    }

    return () => {
      if (!isAlreadyHidden) document.body.style.overflow = 'scroll';
    };
  }, [isEnabled]);
};
