import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {
  AMPLITUDE_KEY,
  BASE_URL_API,
  GOOGLE_API_KEY,
  IS_LIGHT,
  SENTRY_CONFIG,
} from '@/const';

import App from './App.tsx';
import ErrorBoundary from './components/ErrorBoundary';
import store from './store';

Sentry.init(SENTRY_CONFIG);

amplitude.init(AMPLITUDE_KEY, {
  defaultTracking: true,
  serverUrl: `${BASE_URL_API}proxy/amplitude`,
});
const sessionReplayTracking = sessionReplayPlugin();
amplitude.add(sessionReplayTracking);

if (IS_LIGHT) {
  document.documentElement.classList.add('light');
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={GOOGLE_API_KEY}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  </Provider>,
);
